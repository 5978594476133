<template>
  <div>
    <div
      :class="{
        'flex-container': true,
        fixedFooter: fullScreen && !height.length && !useSideBySideStyle,
      }"
      v-show="!isLoading"
    >
      <div
        v-if="fullScreen && !useSideBySideStyle && !useOpenSearchUI"
        v-show="$vuetify.breakpoint.smAndUp && !showHierarchies"
        class="hierarchies-collapsed"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-navigation-drawer
        v-if="fullScreen && !useOpenSearchUI"
        v-show="$vuetify.breakpoint.smAndUp"
        v-model="showHierarchies"
        absolute
        :temporary="useSideBySideStyle"
        class="left-col"
        style="height: 100vh"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Hide Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex align-center pl-1 pt-5">
          <span class="ml-2">Advanced Filters</span>
        </v-toolbar-title>

        <HierarchySearch
          v-if="showHierarchies"
          :items="items"
          :preSelected="preSelectedHierarchyFilters"
          :preSelectedClassifiers="preSelectedClassifierFilters"
          :hierarchyTypes="hierarchyTypes"
          :classifierTypes="classifierTypes"
          @filterChanged="doHierarchyFilter"
        ></HierarchySearch>
      </v-navigation-drawer>
      <v-card :class="[openSearchMenuMini ? 'allJobsOptionsMini' : 'allJobsOptions']" v-if="fullScreen && useOpenSearchUI && !useSideBySideStyle">
     
      <div class="openSearchExpand" v-if="openSearchMenuMini">
        <v-icon
          title="Expand"
          color="#3352DD"
          @click="openSearchMenuMini = !openSearchMenuMini;"
          >mdi-chevron-right-circle-outline</v-icon
        >
      </div>
      <v-navigation-drawer :class="[openSearchMenuMini ? 'navDrawerMini' : 'navDrawer']" permanent floating v-show="!openSearchMenuMini">
        <div class="expansionHolder">
          <v-icon
            title="Minimise"
            color="#3352DD"
            @click="openSearchMenuMini = !openSearchMenuMini;"
            >mdi-chevron-left-circle-outline</v-icon
          >
        </div>  
        <v-tabs class="navTabs" v-model="tab" grow >
          <v-tab class="tab ma-0 pa-0">
            <v-icon class="mr-2">mdi-magnify</v-icon>Search & Filters
          </v-tab>
          <v-tab class="tab ma-0 pa-0">
            <v-icon class="mr-2">mdi-file-tree-outline</v-icon>Explore
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="max-height:calc(100vh - 150px); height:calc(100vh - 150px); overflow:auto; width:100%">
          <v-tab-item style="height:100%; width:100%">
            <v-card flat style="height:100%; width:100%">
              <v-card-text style="height:100%; width:100%">
                <v-text-field
                  v-model="filterText"
                  ref="filterField"
                  outlined
                  label="Search"
                  @input="changefilterText"
                  @keypress.enter="triggerFilter"
                  @click:prepend-inner="triggerFilter"
                  @keyup.esc="clearFilter"
                >
                  <template v-slot:append>
                    <v-icon aria-label="Clear/Close Search" @click="clearFilter">mdi-close</v-icon>
                    <v-progress-circular size="15" style="top: 4px;" color="green" indeterminate v-if="openSearchInProgress"></v-progress-circular>
                  </template>
                </v-text-field>
                <div @click="filtersToggle = !filtersToggle" style="height:40px; cursor:pointer; font-family: Open Sans; font-size: 14px; font-weight: 700; line-height: 19.6px; text-align: left;">
                  <v-icon> {{ filtersToggle ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon> Filters
                </div>
                <div v-if="filtersToggle" style="height:100%; display:inline">
                  <div style="display:inline-flex">
                    <div id="customiseTableMenu">
                      <v-menu
                        offset-y
                        right
                        :close-on-content-click="false"
                        v-if="!cardView"
                        attach="#customiseTableMenu"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" outlined class="mr-2" v-on="on">
                            <v-icon left dark>
                              mdi-plus
                            </v-icon>
                            More Filters
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list
                          dense
                          class="pa-3"
                          style="max-height: calc(100vh - 450px); overflow-y: auto"
                          >
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Customise Table</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            v-for="field in dtColumns.filter(
                              (c) => fromTable || !c.fromTable
                            )"
                            :key="field.value"
                          >
                            <v-switch
                              class="v-input--reverse v-input--expand"
                              style="width: 200px"
                              dense
                              v-model="dtColumnsEnabled"
                              :label="field.text"
                              :value="field.value"
                              @change="saveColumnSelection"
                            ></v-switch>
                          </v-list-item>
                        </v-list>
                        </v-card>

                      </v-menu>
                    </div>
                    <div id="customiseViewMenu">
                      <v-menu
                        offset-y
                        left
                        :close-on-content-click="false"
                        v-if="cardView"
                        attach="#customiseViewMenu"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" outlined class="mr-2" v-on="on">
                            <v-icon left dark>
                              mdi-plus
                            </v-icon>
                            More Filters
                          </v-btn>
                        </template>
                        <v-card>
                        <v-list dense class="pa-3"
                          style="max-height: calc(100vh - 450px); overflow-y: auto">
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Customise View</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            v-for="field in cardFields.filter(
                              (c) => fromTable || !c.fromTable
                            )"
                            :key="field.value"
                          >
                            <v-switch
                              class="v-input--reverse v-input--expand"
                              style="width: 200px"
                              dense
                              v-model="cardFieldsEnabled"
                              :label="field.text"
                              :value="field.value"
                              @change="saveCardFieldSelection"
                            ></v-switch>
                          </v-list-item>
                        </v-list>
                        </v-card>
                      </v-menu>
                    </div>

                  <v-btn color="primary" outlined class="mr-2" style="display:inline" @click="clearAllFilters">Clear</v-btn><br/>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('statuses')">
                    <v-select
                      v-model="docStatusIncluded"
                      :items="docStatusSummary"
                      item-text="status"
                      item-value="status"
                      return-object
                      label="Job Statuses"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="docStatusChanged()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getStatusFilterText(item, index) }}
                      </template>
                    </v-select>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('scores')">
                    <v-select
                      v-model="docScoresIncluded"
                      :items="docScoreCategories"
                      item-text="text"
                      item-value="text"
                      return-object
                      label="Job Scores"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="doFilter()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getScoreFilterText(item, index) }}
                      </template>
                    </v-select>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('types')">
                    <v-select
                      v-model="docTypeIncluded"
                      :items="docTypeSummary"
                      item-text="docType"
                      item-value="docType"
                      return-object
                      label="Document Types"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="docTypeChanged()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getDocTypeFilterText(item, index) }}
                      </template>
                    </v-select>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('review_groups')">
                    <v-autocomplete
                      multiple
                      v-model="reviewGroupsIncluded"
                      label="Review Groups"
                      placeholder="Select..."
                      :items="reviewGroupsSummary"
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      clearable
                      @change="doFilter()">
                    </v-autocomplete>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('people')">
                    <PeopleFilter :attach=false v-model="peopleIncluded" @doFilter="doFilter" @clear="peopleIncluded.splice(0); doFilter();">
                    </PeopleFilter>
                  </div>
                  <div class="pt-5" v-if="filtersToShow.includes('created_between')">
                    <v-menu
                      ref="menu"
                      v-model="docCreatedDateRangeMenu"
                      :close-on-content-click="false"
                      :return-value.sync="docCreatedDateRange"
                      transition="slide-y-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- prepend-inner-icon="mdi-calendar" -->
                        <v-text-field
                          v-model="docCreatedDateRangeText"
                          label="Created Between"
                          dense
                          hide-details
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :append-icon="
                            docCreatedDateRangeText ? 'mdi-close' : ''
                          "
                          @click:append="clearDateFilter"
                          placeholder="e.g 01 Jan - 30 Jan"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="docCreatedDateRange"
                        no-title
                        scrollable
                        range
                      >
                        <v-btn text @click="clearDateFilter"> Clear </v-btn>
                        <v-btn text @click="docCreatedDateRangeMenu = false">
                          Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="doDateFilter">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="pt-5">
                    <v-menu
                      class="hidden-sm-and-down"
                      v-if="showFilters && useTranslation"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          title="Language Filter"
                          large
                          icon
                          v-on="on"
                          class="btn-background ml-2 d-flex align-center"
                        >
                          <country-flag
                            v-if="
                              selectedLanguageFilter.text != 'All' &&
                              selectedLanguageFilter.text !== 'English'
                            "
                            :country="selectedLanguageFilter.flagCode"
                            size="normal"
                            class="my-0"
                          />
                          <div v-if="selectedLanguageFilter.text == 'All'">
                            <v-icon>mdi-earth</v-icon>
                          </div>
                          <div v-if="selectedLanguageFilter.text == 'English'">
                            <img
                              src="../assets/Anglosphere.png"
                              width="28px"
                              height="20px"
                            />
                          </div>
                        </v-btn>
                      </template>
                      <v-card style="height: 550px; overflow-y: scroll">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Language Filter</v-list-item-title
                            >
                          </v-list-item>

                          <v-list-item
                            v-for="(language, index) in translateOptions"
                            :key="index"
                            @click="selectLanguageFilter(language)"
                          >
                            <v-list-item-icon class="d-flex align-center">
                              <country-flag
                                v-if="
                                  language.text !== 'All' &&
                                  language.text !== 'English'
                                "
                                :country="language.flagCode"
                                size="normal"
                                class="my-0"
                              />
                              <img
                                v-if="language.text == 'All'"
                                style="margin-right: 5px"
                                src="../assets/world.png"
                                width="25px"
                              />
                              <img
                                v-if="language.text == 'English'"
                                style="margin-right: 0px"
                                src="../assets/Anglosphere.png"
                                width="28px"
                                height="20px"
                              />
                            </v-list-item-icon>

                            <v-list-item-title>{{
                              language.text
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <HierarchySearch
                  :items="items"
                  :preSelected="preSelectedHierarchyFilters"
                  :preSelectedClassifiers="preSelectedClassifierFilters"
                  :hierarchyTypes="hierarchyTypes"
                  :classifierTypes="classifierTypes"
                  @filterChanged="doHierarchyFilter"
                ></HierarchySearch>
              </v-card-text>
            </v-card>
          </v-tab-item>          
        </v-tabs-items>        
      </v-navigation-drawer> 
       
      </v-card>
      <v-container
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed:
            !showHierarchies &&
            fullScreen &&
            $vuetify.breakpoint.smAndUp &&
            !useSideBySideStyle && !useOpenSearchUI,
          hierarchiesPinned:
            showHierarchies &&
            fullScreen &&
            $vuetify.breakpoint.lgAndUp &&
            !useSideBySideStyle && !useOpenSearchUI,
          'pa-0': !showFilters,
          sideviewfixheight: useSideBySideStyle,
          'jobsHolder': useOpenSearchUI && !useSideBySideStyle && fullScreen && !openSearchMenuMini,
          'jobsHolderMini': useOpenSearchUI && !useSideBySideStyle && fullScreen && openSearchMenuMini
        }"
      >
        <Multipane
          ref="multipane"
          layout="horizontal"
          :style="{
            overflowY: height ? 'auto' : '',
            height: height ? height : '',
          }"
          :class="{ hasPreview: selectedDocument }"
          @paneResize="onPaneResize"
        >
          <div
            ref="mainPane"
            :class="{
              mainPane: !useSideBySideStyle,
              fixedHeader: height.length,
            }"
            style="height: 100%"
          >
            <v-row
              v-if="showFilters && !useSideBySideStyle"
              :class="{
                'py-4': true,
                'pl-4': fullScreen && $vuetify.breakpoint.smAndUp,
              }"
            >
              <v-col cols="6">
                <h1 v-if="currentFilterKey" class="title">
                  {{ currentFilterKey }} Jobs
                </h1>
                <h1 v-else class="title">{{ $route.meta.text }}</h1>
              </v-col>
              <v-col cols="6" class="text-right" v-if="fullScreen">
                <v-menu
                  v-if="allowBulkWorkflowActions && useOpenSearchUI"
                  offset-y
                  left
                  v-model="bulkActions.menu">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="showFilters"
                      color="primary" outlined
                      :class="[
                        'mr-3',
                        'btn-background-opensearch ml-3',
                        bulkActions.active ? 'depressed' : '',
                      ]"
                      title="Bulk Actions"
                      v-on="on"
                      @keyup.esc="bulkActionCancel"
                    >
                      Document Actions
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template> 
                  <v-list
                    dense
                    style="max-height: calc(100vh - 250px); overflow: auto"
                  >
                    <v-list-item>
                      <v-list-item-title class="subtitle-1"
                        >Bulk Actions</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-for="(action, i) in bulkWorkflowActions"
                      :key="'a' + i"
                      @click="bulkActionSelect(action)"
                      :class="action.name === bulkActions.selectedAction?.name ? 'v-list-item--active' : ''"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ getIcon(action) }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ action.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  icon
                  small
                  @click="changeView('cards')"
                  title="Card View"
                  class=""
                >
                  <v-icon
                    :class="
                      viewSelect === 'cards' ? 'iconSelected' : 'iconPassive'
                    "
                    >mdi-view-grid</v-icon
                  >
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="changeView('table')"
                  title="Table View"
                  class="ml-3"
                >
                  <v-icon
                    :class="
                      viewSelect === 'table' ? 'iconSelected' : 'iconPassive'
                    "
                    >mdi-view-list</v-icon
                  >
                </v-btn>
                <v-btn
                  v-if="allowDocCompare"
                  icon
                  small
                  @click="toggleCompareSelect"
                  title="Compare View"
                  class="ml-3"
                >
                  <v-icon
                    :class="
                      viewSelect === 'compare' ? 'iconSelected' : 'iconPassive'
                    "
                    >mdi-compare</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-card :flat="!showFilters">
              <div
                :class="{
                  filters: true,
                  'mx-5': showFilters,
                  sidebysidepadding: useSideBySideStyle,
                }"
              >
                <v-row :class="{ 'pt-2 pb-4': !useSideBySideStyle }" v-if="!useOpenSearchUI" >
                  <v-col
                    v-if="filtersToShow.includes('people')"
                    cols="6"
                    md="4"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                  >
                    <PeopleFilter v-model="peopleIncluded" @doFilter="doFilter" @clear="peopleIncluded.splice(0); doFilter();">
                    </PeopleFilter>
                  </v-col>
                  <v-col
                    v-if="filtersToShow.includes('review_groups')"
                    cols="4"
                    md="3"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                    >
                    <v-autocomplete
                      multiple
                      v-model="reviewGroupsIncluded"
                      label="Review Groups"
                      placeholder="Select..."
                      :items="reviewGroupsSummary"
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      clearable
                      @change="doFilter()">
                    </v-autocomplete>
                  </v-col>   
                  <v-col
                    v-if="filtersToShow.includes('created_between') && !useOpenSearchUI"
                    cols="6"
                    md="4"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                  >
                    <v-menu
                      ref="menu"
                      v-model="docCreatedDateRangeMenu"
                      :close-on-content-click="false"
                      :return-value.sync="docCreatedDateRange"
                      transition="slide-y-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- prepend-inner-icon="mdi-calendar" -->
                        <v-text-field
                          v-model="docCreatedDateRangeText"
                          label="Created Between"
                          dense
                          hide-details
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :append-icon="
                            docCreatedDateRangeText ? 'mdi-close' : ''
                          "
                          @click:append="clearDateFilter"
                          placeholder="e.g 01 Jan - 30 Jan"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="docCreatedDateRange"
                        no-title
                        scrollable
                        range
                      >
                        <v-btn text @click="clearDateFilter"> Clear </v-btn>
                        <v-btn text @click="docCreatedDateRangeMenu = false">
                          Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="doDateFilter">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    v-if="filtersToShow.includes('types') && !useSideBySideStyle"
                    cols="6"
                    md="4"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                  >
                    <v-select
                      v-model="docTypeIncluded"
                      :items="docTypeSummary"
                      item-text="docType"
                      item-value="docType"
                      return-object
                      label="Document Types"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="docTypeChanged()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getDocTypeFilterText(item, index) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="filtersToShow.includes('scores') && !useSideBySideStyle"
                    cols="6"
                    md="4"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                  >
                    <v-select
                      v-model="docScoresIncluded"
                      :items="docScoreCategories"
                      item-text="text"
                      item-value="text"
                      return-object
                      label="Job Scores"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="doFilter()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getScoreFilterText(item, index) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="filtersToShow.includes('statuses') && !useSideBySideStyle"
                    cols="6"
                    md="4"
                    lg="1"
                    class="filter-col"
                    :style="`max-width: ${filterWidth}`"
                  >
                    <v-select
                      v-model="docStatusIncluded"
                      :items="docStatusSummary"
                      item-text="status"
                      item-value="status"
                      return-object
                      label="Job Statuses"
                      multiple
                      :menu-props="{
                        closeOnContentClick: false,
                        maxHeight: '400',
                        'offset-y': true,
                      }"
                      dense
                      hide-details
                      outlined
                      @change="docStatusChanged()"
                    >
                      <template v-slot:selection="{ item, index }">
                        {{ getStatusFilterText(item, index) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="!useSideBySideStyle"
                    cols="1"
                    class="header-buttons"
                  >
                    <div v-if="showFilters && !useOpenSearchUI" class="d-flex align-center">
                      <v-btn
                        title="Search"
                        large
                        icon
                        class="btn-background ml-2"
                        v-show="!showSearch"
                        @click="showFilterField"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>

                      <v-progress-circular size="15" color="green" indeterminate v-if="openSearchInProgress"></v-progress-circular>
                      <v-expand-x-transition>
                        <v-text-field
                          v-show="showSearch"
                          v-model="filterText"
                          ref="filterField"
                          hide-details
                          solo
                          flat
                          label="Filter jobs containing..."
                          prepend-inner-icon="mdi-magnify"
                          @input="changefilterText"
                          @keypress.enter="triggerFilter"
                          @click:prepend-inner="triggerFilter"
                          @keyup.esc="clearFilter"
                          class="btn-background ml-2 align-center"
                        >
                          <template v-slot:append>
                            <v-icon aria-label="Clear/Close Search" @click="clearFilter">mdi-close</v-icon>
                          </template>
                        </v-text-field>
                      </v-expand-x-transition>
                    </div>

                    <v-menu
                      class="hidden-sm-and-down"
                      v-if="showFilters && useTranslation"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          title="Language Filter"
                          large
                          icon
                          v-on="on"
                          class="btn-background ml-2 d-flex align-center"
                        >
                          <country-flag
                            v-if="
                              selectedLanguageFilter.text != 'All' &&
                              selectedLanguageFilter.text !== 'English'
                            "
                            :country="selectedLanguageFilter.flagCode"
                            size="normal"
                            class="my-0"
                          />
                          <div v-if="selectedLanguageFilter.text == 'All'">
                            <v-icon>mdi-earth</v-icon>
                          </div>
                          <div v-if="selectedLanguageFilter.text == 'English'">
                            <img
                              src="../assets/Anglosphere.png"
                              width="28px"
                              height="20px"
                            />
                          </div>
                        </v-btn>
                      </template>
                      <v-card style="height: 550px; overflow-y: scroll">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Language Filter</v-list-item-title
                            >
                          </v-list-item>

                          <v-list-item
                            v-for="(language, index) in translateOptions"
                            :key="index"
                            @click="selectLanguageFilter(language)"
                          >
                            <v-list-item-icon class="d-flex align-center">
                              <country-flag
                                v-if="
                                  language.text !== 'All' &&
                                  language.text !== 'English'
                                "
                                :country="language.flagCode"
                                size="normal"
                                class="my-0"
                              />
                              <img
                                v-if="language.text == 'All'"
                                style="margin-right: 5px"
                                src="../assets/world.png"
                                width="25px"
                              />
                              <img
                                v-if="language.text == 'English'"
                                style="margin-right: 0px"
                                src="../assets/Anglosphere.png"
                                width="28px"
                                height="20px"
                              />
                            </v-list-item-icon>

                            <v-list-item-title>{{
                              language.text
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                    <v-menu
                      offset-y
                      :close-on-content-click="false"
                      v-if="showFilters && config.settings?.some(s => s.setting === 'alljobs_show_favourite_filters' && s.value === 'true')"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          title="Favourite Filters"
                          large
                          v-on="on"
                          icon
                          class="btn-background ml-3"
                        >
                          <v-icon>mdi-star</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-title class="subtitle-1"
                            >Favourite Filters</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item class="mb-2">
                          <v-text-field
                            dense
                            v-model="newFilterKey"
                            hide-details
                            placeholder="Filter Name"
                            @keyup.enter="saveFilters"
                          ></v-text-field>
                          <v-btn
                            small
                            icon
                            color="primary"
                            class="ml-2"
                            @click="saveFilters"
                            :disabled="!newFilterKey"
                            ><v-icon>mdi-plus</v-icon></v-btn
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="f in savedFilters"
                          :key="f.key"
                          @click="restoreFilters(f)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ f.key }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon small
                              ><v-icon
                                title="Delete"
                                @click="deleteSavedFilter(f)"
                                >mdi-delete</v-icon
                              ></v-btn
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      v-if="allowBulkWorkflowActions"
                      offset-y
                      left
                      v-model="bulkActions.menu">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="showFilters"
                          large
                          icon
                          :class="[
                            'btn-background ml-3',
                            bulkActions.active ? 'depressed' : '',
                          ]"
                          title="Bulk Actions"
                          v-on="on"
                          @keyup.esc="bulkActionCancel"
                        >
                          <v-icon>checklist</v-icon>
                        </v-btn>
                      </template> 
                      <v-list
                        dense
                        style="max-height: calc(100vh - 250px); overflow: auto"
                      >
                        <v-list-item>
                          <v-list-item-title class="subtitle-1"
                            >Bulk Actions</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="(action, i) in bulkWorkflowActions"
                          :key="'a' + i"
                          @click="bulkActionSelect(action)"
                          :class="action.name === bulkActions.selectedAction?.name ? 'v-list-item--active' : ''"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ getIcon(action) }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ action.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div id="customiseTableMenu">
                      <v-menu
                        offset-y
                        left
                        :close-on-content-click="false"
                        v-if="!cardView"
                        attach="#customiseTableMenu"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            title="Customise Table"
                            large
                            v-on="on"
                            icon
                            class="btn-background ml-3"
                          >
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-list
                          dense
                          class="pa-3"
                          style="max-height: calc(100vh - 250px); overflow: auto"
                        >
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Customise Table</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            v-for="field in dtColumns.filter(
                              (c) => fromTable || !c.fromTable
                            )"
                            :key="field.value"
                          >
                            <v-switch
                              class="v-input--reverse v-input--expand"
                              style="width: 200px"
                              dense
                              v-model="dtColumnsEnabled"
                              :label="field.text"
                              :value="field.value"
                              @change="saveColumnSelection"
                            ></v-switch>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div id="customiseViewMenu">
                      <v-menu
                        offset-y
                        left
                        :close-on-content-click="false"
                        v-if="cardView"
                        attach="#customiseViewMenu"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            title="Customise View"
                            large
                            v-on="on"
                            icon
                            class="btn-background ml-3"
                          >
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense class="pa-3"
                          style="max-height: calc(100vh - 250px); overflow: auto">
                          <v-list-item>
                            <v-list-item-title class="subtitle-1"
                              >Customise View</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            v-for="field in cardFields.filter(
                              (c) => fromTable || !c.fromTable
                            )"
                            :key="field.value"
                          >
                            <v-switch
                              class="v-input--reverse v-input--expand"
                              style="width: 200px"
                              dense
                              v-model="cardFieldsEnabled"
                              :label="field.text"
                              :value="field.value"
                              @change="saveCardFieldSelection"
                            ></v-switch>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-card-text
                v-if="showHeaderCount && !useSideBySideStyle"
                class="headerCount"
              >
                <v-divider /><span
                  >{{ filteredItems.length }}
                  {{ filteredItems.length !== 1 ? "Documents" : "Document" }}
                  match the current selection.</span
                ><v-divider />
              </v-card-text>
              <v-divider v-else-if="showFilters && !useSideBySideStyle" />

              <div>
                <div
                  v-if="!isLoading && !bulkActions.loading"
                  :class="{ docList: true }"
                  id="docListContainer"
                >
                  <v-alert v-if="bulkActions.active && filteredItems.length !== 0" class="my-2 mx-2" type="info" text dense>
                    Results have been filtered to include only those items for which the selected action can be performed. Please select the items you wish to process.
                  </v-alert>

                  <div v-if="filteredItems.length === 0">
                    <v-alert v-if="bulkActions.active" class="mt-6 mb-1 mx-2" type="info" text>There are no documents for which you can perform the selected action</v-alert>
                    <v-alert v-else class="mt-6 mb-1 mx-2" type="info" text>No documents found</v-alert>
                    &nbsp;
                  </div>
                  <div v-else-if="useSideBySideStyle">
                    <DocumentSideBySide
                      :items="docCompareItems"
                      @changeView="changeView"
                      @doHierarchyFilter="doHierarchyFilter"
                      @openDoc="openDocument"
                      @triggerNotification="triggerNotification"
                      @showHierarchyFilter="
                        (val) => {
                          showHierarchies = val;
                        }
                      "
                      @openContext="
                        (event, j) => {
                          openContextFromSideBySide(event, j);
                        }
                      "
                    ></DocumentSideBySide>
                  </div>
                  <div v-else-if="cardView">
                    <v-row wrap class="cardList ma-2">
                      <v-col
                        v-for="j in currentPageItems"
                        :key="'j' + j.system_number"
                        cols="12"
                        sm="6"
                        :lg="showHierarchies ? 4 : 3"
                        :xl="showHierarchies ? 3 : 2"
                        class="pa-1"
                      >
                        <v-hover v-slot="{ hover }" close-delay="100">
                          <v-card
                            :class="{ 'on-hover': hover, 'ma-2': true }"
                            flat
                            class="doc-card"
                            tabindex="-1"
                            @click.stop="selectDocument($event, j)"
                            @contextmenu="openContextMenu($event, j)"
                            @keyup.enter="handleEnter($event, j)"
                            @keyup.esc="handleEsc"
                          >
                            <v-list dense>
                              <v-list-item class="data-content pt-1 pr-0">
                                <v-checkbox
                                  v-if="allowDocCompare && selectCompare"
                                  @change="toggleDocCompare(j.system_number)"
                                  class="ma-0"
                                  value
                                  :input-value="
                                    docListIncludes(j.system_number)
                                  "
                                  @click.stop
                                  :aria-label="`Select ${j.reference}`"
                                >
                                </v-checkbox>
                                <v-checkbox
                                  v-if="bulkActions.active"
                                  @click.stop
                                  class="ma-0"
                                  value
                                  :input-value="
                                    bulkActionDocsIncludes(j.doc_id)
                                  "
                                  @change="toggleDocToAction(j.doc_id)"
                                  :aria-label="`Select ${j.reference}`"
                                ></v-checkbox>
                                <v-avatar
                                  v-if="cardFieldsEnabled.includes('doc_type')"
                                  class="jobTypeAvatar mr-2"
                                  size="25"
                                >
                                  <span class="subtitle-2">{{
                                    j.doc_type_abbrev
                                  }}</span>
                                </v-avatar>
                                <v-list-item-title
                                  class="d-flex justify-space-between align-center"
                                >
                                  <span
                                    v-if="
                                      cardFieldsEnabled.includes(
                                        'system_number'
                                      )
                                    "
                                    class="body-2 font-weight-light"
                                    v-html="j.system_number"
                                  ></span>

                                  <v-progress-circular
                                    :aria-label="`${j.reference} score`"
                                    v-if="
                                      j.overall_score !== undefined &&
                                      j.overall_score !== null &&
                                      cardFieldsEnabled.includes(
                                        'overall_score'
                                      )
                                    "
                                    :rotate="90"
                                    :size="30"
                                    :width="3"
                                    :value="j.overall_score"
                                    :color="j.overall_score_color"
                                    class=""
                                    >{{ j.overall_score }}
                                  </v-progress-circular>

                                  <country-flag
                                    v-if="useTranslation"
                                    :country="getFlagForLang(j.language_code)"
                                    class="customFlag ml-0 my-0"
                                    size="small"
                                  /><DocStatus
                                    v-if="
                                      cardFieldsEnabled.includes(
                                        'doc_status_text'
                                      )
                                    "
                                    :document="j"
                                    sideAddendum
                                    style="margin-top: 0"
                                  ></DocStatus>
                                </v-list-item-title>
                                <v-btn
                                  small
                                  icon
                                  @click.stop="openContextMenu($event, j)"
                                  title="Actions"
                                  :id="`actions_${j.doc_id}`"
                                >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title class="subtitle-2">
                                    <div
                                      v-html="utils.sanitize(j.doc_name)"
                                    ></div>
                                  </v-list-item-title>

                                  <div
                                    v-if="
                                      cardFieldsEnabled.includes(
                                        'advert_job_title'
                                      )
                                    "
                                    class="caption font-italic font-weight-light"
                                    v-html="utils.sanitize(j.advert_job_title)"
                                  ></div>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-if="cardFieldsEnabled.includes('description')"
                                class="job-description data-value-2"
                                ><div
                                  v-html="
                                    utils
                                      .sanitize(j.description)
                                      .replaceAll('<br>', '.')
                                      .replaceAll(
                                        'font-size:18px',
                                        'font-size:13px'
                                      ) || 'No job description.'
                                  "
                                ></div
                              ></v-list-item>
                              <div
                                v-for="(cf, cfi) in cardDisplayFields"
                                :key="'j' + j.system_number + '_' + cfi"
                                :class="cf.cssClass"
                                :style="cf.style"
                              >
                                <span class="data-label">{{ cf.text }}:</span>
                                <span
                                  v-html="cf.format(j)"
                                  class="data-value-2"
                                ></span>
                              </div>
                            </v-list>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </div>
                  <v-data-table
                    v-else
                    :headers="dtColumnsFiltered"
                    :items="filteredItems"
                    :sort-by.sync="sortColumn"
                    :sort-desc.sync="sortDesc"
                    :dense="density == 'dense'"
                    :class="['mt-2', density]"
                    :page.sync="currentPage"
                    hide-default-footer
                    :items-per-page="itemsPerPage"
                    :fixed-header="!!tableHeight"
                    :height="tableHeight"
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        @click.stop="selectDocument($event, item)"
                        @contextmenu="openContextMenu($event, item)"
                        @keyup.enter="handleEnter($event, item)"
                        @keyup.esc="handleEsc"
                      >
                        <td v-for="col in dtColumnsFiltered" :key="col.value">
                          <div v-if="col.value === 'doc_select'" >
                            <v-checkbox
                              @click.stop
                              value
                              :input-value="
                                bulkActionDocsIncludes(item.doc_id)
                              "
                              @change="toggleDocToAction(item.doc_id)"
                              :aria-label="`Select ${item.system_number}`"
                            ></v-checkbox>
                          </div>
                          <div v-if="col.value === 'doc_compare'">
                            <v-checkbox
                              @change="toggleDocCompare(item.system_number)"
                              value
                              :input-value="docListIncludes(item.system_number)"
                              @click.stop
                              :aria-label="`Select ${item.system_number}`"
                            ></v-checkbox>
                          </div>
                          <div v-if="col.value === 'export_menu'" class="text-center">
                            <v-btn
                              small
                              icon
                              @click.stop="openContextMenu($event, item)"
                              title="Actions"
                              :id="`actions_${item.doc_id}`"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </div>
                          <div
                            v-else-if="col.value === 'doc_name'"
                            class="d-flex align-center"
                          >
                            <span
                              v-html="utils.sanitize(item[col.value])"
                            ></span>
                            <country-flag
                              v-if="useTranslation"
                              :country="getFlagForLang(item.language_code)"
                              class="customFlag ml-2 my-0"
                              size="small"
                            />
                          </div>
                          <div v-else-if="col.value === 'doc_status_text'">
                            <DocStatus :document="item"></DocStatus>
                          </div>
                          <div v-else-if="col.value === 'comment_count'">
                            <CommentsCount v-if="item.comment_count != 0" :document="item" @openDocument="openDocument"></CommentsCount>
                          </div>
                          <div v-else-if="col.value === 'overall_score'">
                            <v-progress-circular
                              :aria-label="`${item.reference} score`"
                              v-if="
                                item.overall_score !== undefined &&
                                item.overall_score !== null
                              "
                              :rotate="90"
                              :size="
                                density == 'sparse'
                                  ? 40
                                  : density == 'dense'
                                  ? 25
                                  : 35
                              "
                              :width="
                                density == 'sparse'
                                  ? 4
                                  : density == 'dense'
                                  ? 2
                                  : 3
                              "
                              :value="item.overall_score"
                              :color="item.overall_score_color"
                              class="ma-1"
                              ><span
                                :style="
                                  density == 'dense' ? 'font-size: 12px' : ''
                                "
                                >{{ item.overall_score }}</span
                              >
                            </v-progress-circular>
                          </div>
                          <div v-else-if="col.value === 'matches_similar_content_same_name'"
                              @click.stop="openMatchMenu($event, item)" title="Click to view matched documents"><v-badge
          color="red" v-if="item[col.value] && item[col.value].length"
          :content="item[col.value].length"
        >
							<v-icon color="blue">mdi-content-duplicate</v-icon>
        </v-badge>
                          </div>
                          <div
                            v-else-if="
                              col.value === 'listview3' ||
                              col.value === 'recruiter' ||
                              col.value === 'job_updated_at' ||
                              col.value === 'job_created_at' ||
                              col.value === 'created_by' ||
                              col.value.startsWith('classifier_')
                            "
                          >
                            <span
                              class="small"
                              v-html="utils.sanitize(item[col.value])"
                            ></span>
                          </div>
                          <div v-else>
                            <span
                              v-html="utils.sanitize(item[col.value])"
                            ></span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div
                    v-if="showFooter && !useSideBySideStyle"
                    class="d-flex justify-space-between footer-actions primary-background"
                  >
                    <PageDescription
                      :totalItems="filteredItems.length"
                      :pageSize="itemsPerPage"
                      :currentPage="currentPage"
                    />
                    <Pagination
                      :totalItems="filteredItems.length"
                      :pageSize="itemsPerPage"
                      :currentPage="currentPage"
                      @pageNavigation="currentPage = $event"
                    />
                    <div v-if="bulkActions.active">
                      <v-btn
                        color="primary"
                        outlined
                        small
                        class="ml-2"
                        @click="bulkActionCancel"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        color="primary"
                        outlined
                        small
                        class="ml-2"
                        @click="bulkActionToggleSelectAll"
                      >
                        <span>{{ bulkActions.selectedDocList.length === filteredItems.length ? 'Clear Selection' : 'Select All' }}</span>
                      </v-btn>
                      <v-btn
                        color="primary"
                        small
                        class="ml-2"
                        @click="bulkActionRun"
                        :disabled="bulkActions.selectedDocList.length === 0"
                      >
                        {{ bulkActions.selectedAction?.name }}
                        {{
                          bulkActions.selectedDocList.length ? `(${bulkActions.selectedDocList.length})` : ""
                        }}
                      </v-btn>
                    </div>
                    <div v-else-if="selectCompare">
                      <v-btn
                        color="primary"
                        outlined
                        small
                        class="mx-2"
                        @click="toggleCompareSelect"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        color="primary"
                        small
                        class="mx-2"
                        @click="confirmDocCompare"
                      >
                        Compare Documents -
                        {{
                          compareDocList.length
                            ? `(${compareDocList.length})`
                            : `All (${this.currentPageItems.length})`
                        }}
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-menu offset-y top z-index="301" class="mr-2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            small
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Export
                            <v-icon right>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item @click="exportToCSV">
                            <v-list-item-title>
                              Export to CSV
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="exportToCSVWithParts">
                            <v-list-item-title>
                              Export to CSV with full details
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y top v-if="pageSize === 0" z-index="301">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="!$loginState.readOnly"
                            outlined
                            small
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ itemsPerPageText }}
                            <v-icon right>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item
                            v-for="item in itemsPerPageOptions"
                            :key="item.value"
                            @click="setPageSize(item.value)"
                          >
                            <v-list-item-title>
                              {{ item.text }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
              <v-menu
                v-model="contextMenu"
                absolute
                :position-x="posX"
                :position-y="posY"
                offset-y
                :close-on-content-click="false"
                nudge-width="300"
                activator="#docListContainer"
                :open-on-click="false"
              >
                <v-card>
                  <v-card-text v-if="contextMenuBusy">
                    <v-row>
                      <v-col cols="5"></v-col>
                      <v-col>
                        <v-progress-circular
                          indeterminate
                          size="50"
                          color="grey"
                        >
                        </v-progress-circular>
                      </v-col>
                      <v-col cols="5"></v-col>
                    </v-row>
                  </v-card-text>
                  <v-list dense v-else>
                    <v-list-item @click="openDocument(exportDoc)">
                      <v-list-item-icon>
                        <v-icon>mdi-book-open</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Open</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="previewEnabled"
                      @click="openPreview(exportDoc)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Preview</v-list-item-title>
                    </v-list-item>

                    <div v-if="exportDoc.usesWorkflow">
                      <v-list-item
                        v-for="aa in exportDoc.listActions"
                        :key="
                          'd' + exportDoc.doc_id + 'a' + aa.action_available_id
                        "
                        dense
                        @click="doWorkflowAction(aa)"
                        :disabled="
                          !!(!aa.allow_errors && exportDoc.error_count) ||
                          !!(!aa.allow_errors && exportDoc.missing_data) ||
                          !!(!aa.allow_warnings && exportDoc.warning_count)
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>{{ getIcon(aa) }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ aa.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </div>
                    <div v-else>
                      <v-list-item
                        dense
                        @click="copyDocument(exportDoc, undefined)"
                        v-if="
                          $loginState.user.createDocumentOptions.some(
                            (a) => a.tmpl_id === exportDoc.tmpl_id
                          )
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-card-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Copy Document </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        dense
                        @click="sendToTaleo()"
                        v-if="usesTaleoFeed || this.$loginState.canDemo"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-transfer-right</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Post </v-list-item-title>
                      </v-list-item>

                      <v-list-group
                        v-if="!$loginState.readOnly && !exportDoc.usesWorkflow"
                        prepend-icon="mdi-share"
                      >
                        <template v-slot:activator>
                          <v-list-item-title>Share</v-list-item-title>
                        </template>
                        <div>
                          <v-list-item
                            v-for="(sec, si) in html_export_sections"
                            :key="'dt' + si"
                            dense
                            @click="docToClipboard(sec)"
                          >
                            <v-list-item-icon>
                              <v-icon>{{
                                "mdi-numeric-" +
                                (si + 1) +
                                "-box-multiple-outline"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{
                                sec.replace(/ .*/, "") +
                                (sec.replace(/ .*/, "") === "Section"
                                  ? " " + (si + 1)
                                  : "") +
                                " to Clipboard"
                              }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            key="dt19"
                            dense
                            @click="
                              showEmailDialog(false);
                              toggleDocToAction(exportDoc.doc_id);
                              contextMenu = false;
                            "
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-email-send-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title> Email to... </v-list-item-title>
                          </v-list-item>
                        </div>
                      </v-list-group>

                      <v-list-group
                        v-if="!exportDoc.usesWorkflow"
                        :value="false"
                        prepend-icon="mdi-download"
                      >
                        <template v-slot:activator>
                          <v-list-item-title>Download</v-list-item-title>
                        </template>

                        <v-list-item key="dt10" dense @click="generatePDF()">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title> Download PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          key="dt11"
                          dense
                          v-if="!$loginState.canDemo"
                          @click="generateRTF()"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-file-word</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Download RTF</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          key="dt14"
                          dense
                          v-if="!$loginState.canDemo"
                          @click="generateDOCX()"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-file-word-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Download Word</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          key="dt12"
                          dense
                          @click="generateDOCX(true)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-file-word-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Download Word (+Notes)</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          key="dt16"
                          dense
                          @click="generateJobShareTemplate()"
                          v-if="
                            $loginState.user.settings.some(
                              (s) =>
                                s.setting === 'uses_jobsharetemplate' &&
                                s.value === 'true'
                            ) &&
                            (exportDoc.doc_type == 'Job Description' ||
                              exportDoc.doc_type == 'Job Profile')
                          "
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-share-variant</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Export Job Share Template</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-group>
                    </div>
                  </v-list>
                </v-card>
              </v-menu>
            </v-card>
          </div>
          <MultipaneResizer
            v-if="previewEnabled && selectedDocument && !useSideBySideStyle"
          ></MultipaneResizer>
          <div
            class="previewPane"
            v-if="previewEnabled && selectedDocument && !useSideBySideStyle"
            @keyup.esc="closePreview"
          >
            <div class="actions">
              <v-btn icon large @click="closePreview" title="Close" ref="closePreview"
                ><v-icon>mdi-close-circle-outline</v-icon></v-btn
              >
              <v-btn
                icon
                large
                @click="openDocument(selectedDocument)"
                title="Edit Document"
                ><v-icon>mdi-square-edit-outline</v-icon></v-btn
              >
              <v-btn
                icon
                large
                @click="copyDocument(selectedDocument)"
                title="Copy Document"
                v-if="!$loginState.readOnly"
                ><v-icon>mdi-content-copy</v-icon></v-btn
              >
            </div>

            <DocumentFullEditSection
              class="docPreview"
              :documentId="selectedDocument.system_number"
              :readOnly="true"
              :isCopied="false"
              :useInline="true"
            />
          </div>
        </Multipane>
      </v-container>
    </div>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading || compareLoading || exportGenerating || bulkActions.loading" />

    <v-dialog v-model="languageDialog" max-width="1500px" min-height="700px">
      <v-card>
        <v-card-title class="justify-center">
          <div class="justify-center">Language Options:</div>
        </v-card-title>
        <v-card-text class="justify-center"> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLanguageDialog"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      <span v-html="errorText"></span>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showErrorSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="emailDialogue.show"
      max-width="800px"
      style="max-height: 600px"
      scrollable
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          <div class="subtitle-1">
            <span v-if="bulkActions.active"
              >Send Documents
              <span v-if="bulkActions.selectedDocList.length > 0"
                >({{ bulkActions.selectedDocList.length }})</span
              ></span
            >
            <span v-else>Send Document</span>
          </div>
          <v-spacer />
          <v-btn
            icon
            large
            class="btn-background"
            @click="emailDialogue.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                class="roundish mt-2"
                label="Email Address"
                required
                :rules="[rules.required, rules.email]"
                v-model="emailDialogue.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-6">
          <v-btn
            v-if="!emailDialogue.sending"
            color="primary"
            @click="emailDoc()"
            :disabled="!emailDialogue.value"
            >Send</v-btn
          >
          <v-progress-circular
            v-else
            class="mx-2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bulkActions.showConfirm"
      max-width="800px"
      style="max-height: 600px"
      scrollable
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          <div class="subtitle-1">
            Confirm Action
          </div>
          <v-spacer />
          <v-btn
            icon
            large
            class="btn-background"
            @click="bulkActions.showConfirm = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="bulkActions.processing" class="mb-4">
            <v-progress-linear
              color="primary"
              height="25"
              :value="100 * bulkActions.processingIndex / bulkActions.selectedDocList.length"
            >
              <strong>Processing {{ bulkActions.processingIndex + 1 }} of {{ bulkActions.selectedDocList.length }}</strong>
            </v-progress-linear>
          </div>
          <v-alert type="info" text v-else>
            Are you sure you want to <b>{{ bulkActions.selectedAction?.name }}</b> {{ bulkActions.selectedDocList.length }} {{bulkActions.selectedDocList.length > 1 ? 'documents' : 'document'}}?
          </v-alert>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-6 pt-0">
          <v-btn
            color="primary"
            outlined
            @click="bulkActions.processing = false; bulkActions.showConfirm = false; "
            >Cancel</v-btn
          >
          <v-btn
            v-if="!bulkActions.processing"
            color="primary"
            @click="bulkActionConfirm"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dataCapture.show"
      scrollable
      max-width="800px"
      persistent
    >
      <DataCaptureForm
        v-if="
          dataCapture.show &&
          (dataCapture.action.definition.isCommentBased ||
            ['picklist', 'form'].some(
              (x) => x === dataCapture.action.definition.method
            ))
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        :docId="exportDoc.doc_id"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
      />
      <DataCaptureDocumentRole
        v-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'document_role'
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        :docId="exportDoc.doc_id"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
      />
      <DataCaptureHierarchy
        v-else-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'hierarchy'
        "
        :action="dataCapture.action"
        :documentHierarchies="document.hierarchies"
        :isBusy="isLoading"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dataCapture.action = !dataCapture.action"
      />
      <DataCaptureRequisitions
        v-else-if="
          dataCapture.show &&
          (dataCapture.action.definition.method === 'requisition' ||
            dataCapture.action.definition.method === 'requisition_new')
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="
          dataCapture.action.isDataCaptureHidden =
            !dataCapture.action.isDataCaptureHidden
        "
      />
      <AssignReviewGroup
        v-else-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'review_group'
        "
        :docId="exportDoc.doc_id"
        :action="dataCapture.action"
        @save="saveDataCapture"
        @cancel="dataCapture.show = false"
        @close="dataCapture.show = false"
      >
      </AssignReviewGroup>
    </v-dialog>
		<DocListMenu
		:context="matchMenu" @openDocument="openDocument" @copyDocument="$emit('copyDocument', $event)" 
		></DocListMenu>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import HierarchySearch from "@/components/cHierarchySearch"; // @ is an alias to /src
import DocumentFullEditSection from "@/components/cDocumentFullEditSection";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import utils from "@/common/utils.js";
import documentExport from "@/common/documentExport.js";
import moment from "moment";
import CountryFlag from "vue-country-flag";
import { Multipane, MultipaneResizer } from "vue-multipane";
import { mapState } from "vuex";
import DataCaptureForm from "@/components/cDataCaptureForm.vue";
import DataCaptureHierarchy from "@/components/cDataCaptureHierarchy.vue";
import DataCaptureRequisitions from "@/components/cDataCaptureRequisitions.vue";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import PeopleFilter from "@/components/cPeopleFilter.vue";
import DocumentSideBySide from "@/components/cDocumentSideBySide.vue";
import DataCaptureDocumentRole from "@/components/cDataCaptureDocumentRole.vue";
import DocStatus from "@/components/common/DocStatus.vue";
import CommentsCount from "@/components/comments/CommentsCount.vue";
import DocListMenu from "@/components/common/DocListMenu";

export default {
  name: "DocumentsWithPartsNew",
  components: {
    ResponseHandler,
    HierarchySearch,
    DocumentFullEditSection,
    CountryFlag,
    Pagination,
    PageDescription,
    Multipane,
    MultipaneResizer,
    DataCaptureForm,
    DataCaptureHierarchy,
    DataCaptureRequisitions,
    AssignReviewGroup,
    PeopleFilter,
    DocumentSideBySide,
    DataCaptureDocumentRole,
    DocStatus,
    CommentsCount,
    DocListMenu,
  },
  props: {
    fullScreen: { type: Boolean, required: false, default: false },
    pageSize: { type: Number, required: false, default: 0 },
    height: { type: String, required: false, default: "" },
    showHeaderCount: { type: Boolean, required: false, default: false },
    showFooter: { type: Boolean, required: false, default: true },
    showFilters: { type: Boolean, required: false, default: true },
    density: { type: String, required: false, default: "sparse" },
    filters: { type: Object, required: false },
    enablePreview: { type: Boolean, required: false, default: false },
    clickToPreview: { type: Boolean, required: false, default: false },
  },
  data: function () {
    return {
      expandHover: false,
      mini: true,
      drawer: true,
      hdrawer: false,
      items2: [
        {
          title: "Hierarchies",
          icon: "mdi-align-horizontal-left",
          active: true,
        },
      ],
      targetPart: "Job Purpose",
      targetPartDetails: {},
      allParts: [],
      pinnedItems: [],
      pinnedItem: null,
      testString: "",
      diffModes: [
        { text: "Chars", value: "diffChars" },
        { text: "Words", value: "diffWords" },
      ],
      diffType: "diffWords",
      viewFilters: true,
      recruiterAltLabel: "Recruiter",
      utils: utils,
      response: null,
      languageDialog: false,
      items: [],
      isDirty: false,
      dirtyCount: 0,
      recruitersMenu: false,
      personTab: 0,
      peopleIncluded: [],
      reviewGroupsIncluded: [],
      docScoresIncluded: [],
      docCreatedDateRange: [],
      docCreatedDateRangeMenu: false,
      docStatusIncluded: [],
      docTypeIncluded: [],
      itemStatusIncluded: [],
      preSelectedHierarchyFilters: [],
      preSelectedClassifierFilters: [],
      tempFilterText: "",
      filterText: "",
      showSearch: false,
      selDoc: "",
      viewSelect: "",
      dtItemsPerPage: 20,
      dtColumns: [],
      dtColumnsEnabled: [
        "system_number",
        "doc_name",
        "doc_status_text",
        "overall_score",
        "listview2",
        "listview3",
        "recruiter",
        "job_created_at",
      ],
      contextMenu: false,
      contextMenuBusy: false,
      html_export_sections: ["Export"],
      usesTaleoFeed: false,
      usesInterviewTemplate: false,
      selectedLanguageFilter: { text: "All", value: "", flagCode: "" },
      translateOptions: [
        { text: "All", value: "", flagCode: "" },
        ...utils.translateOptions,
      ],
      dialog: false,
      options: { itemsPerPage: 15 },
      genPDF: false,
      ignoreTVInputEvent: false,
      filteredItems: [],
      maxCards: 24,
      currentPage: 1,
      cardFields: [],
      cardFieldsEnabled: [
        "system_number",
        "doc_status_text",
        "description",
        "listview2",
        "listview3",
        "recruiter",
        "job_created_at",
      ],
      cardDisplayFields: [],
      showHierarchies: false,
      drawerHover: false,
      exportDoc: {},
      searchTimeout: null,
      bulkActions: {
        menu: false,
        active: false,
        selectedAction: null,
        loading: false,
        docActionsAvailable: [],
        selectedDocList: [],
        showConfirm: false,
        processing: false,
        processingIndex: 0,
      },
      emailDialogue: {
        show: false,
        value: null,
        multi: false,
        sending: false,
      },
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      savedFilters: [],
      currentFilterKey: "",
      newFilterKey: "",
      isFavFilter: false,
      selectedDocument: null,
      tableHeight: null,
      tableHeightTimeout: null,
      posX: null,
      posY: null,
      emailHeaders: [
        { text: "", value: "actions" },
        { text: "System Number", value: "system_number" },
        { text: "Job Title", value: "doc_name" },
      ],
      allowDocCompare: false,
      docCompareItems: [],
      controlOnStart: false,
      compareLoading: false,
      selectedActiveParts: [],
      sortColumn: "system_number",
      sortDesc: true,
      prevSort: null,
      dataCapture: {
        show: false,
        isValid: false,
        value: null,
        action: null,
      },
      passedParams: {},
      selectCompare: false,
      compareDocList: [],
      matchMenu: {
        show: false,
        busy: false,
        title: null,
        documents: [],
        matchSets: [],
        posX: 0,
        posY: 0,
        matchColumn: false,
		documentType: null,
      },
      exportGenerating: false,
      openSearchInProgress: false,
      tab: 0,
      filtersToggle: true,
      openSearchMenuMini: false,
      openSearchMinScore: 5.0
    };
  },
  watch: {
    showHierarchies(val) {
      this.$loginState.saveSetting("dwpShowHierarchies", val.toString());
    },
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
    filters() {
      this.doFilter();
    },
    previewEnabled(val) {
      if (!val) this.closePreview();
    },
    docsListUpdated(val) {
      if (val && !this.isLoading && this.viewSelect !== "compare") {
        if (this.bulkActions.active)
          this.bulkActionFilter();
        else
          this.doFilter(true);
      }
    },
    config() {
      this.loadDefaultViewSettings();
    },
  },
  computed: {
    useOpenSearchUI(){
      return this.$route.meta.text === "All Jobs" && this.$loginState.user && this.$loginState.user.settings.some((s) => s.setting === 'alljobs_use_opensearch' && s.value === 'true');
    },
    useSideBySideStyle() {
      return this.allowDocCompare && this.viewSelect === "compare";
    },
    isMultiPart() {
      return this.targetPartDetails.multi_row;
    },
    previewEnabled() {
      //ensure document can be previewed too (template preview)
      return this.enablePreview && !this.$vuetify.breakpoint.mdAndDown;
      //return this.enablePreview && (!this.exportDoc || this.exportDoc?.preview) && !this.$vuetify.breakpoint.mdAndDown;
    },
    rowClickAction() {
      return (
        this.$loginState.user.settings.find(
          (s) => s.setting === "alljobs_leftclickaction"
        )?.value || "opendoc"
      );
    },
    docCreatedDateRangeText: {
      get() {
        const range = this.docCreatedDateRange;
        if (!range.length) return "";
        if (range.length == 1) return moment(range[0]).format("DD MMM YYYY");

        return range
          .map((d) => {
            return moment(d).format("DD MMM");
          })
          .join(" - ");
      },
      set(newValue) {
        const range = newValue.split('-').map(x => x.trim()).filter(x => x);
        if (range.length > 0 && range[0].length >= 8)
          this.docCreatedDateRange[0] = moment(range[0], 'DD MMM YYYY').format("YYYY-MM-DD");
        else if (range.length > 0 && range[0].length >= 4)
          this.docCreatedDateRange[0] = moment(range[0], 'DD MMM').format("YYYY-MM-DD");
        else
          this.docCreatedDateRange.splice(0);

        if (range.length > 1 && range[1].length >= 8)
          this.docCreatedDateRange[1] = moment(range[1], 'DD MMM YYYY').format("YYYY-MM-DD");
        if (range.length > 1 && range[1].length >= 4)
          this.docCreatedDateRange[1] = moment(range[1], 'DD MMM').format("YYYY-MM-DD");
        else if (this.docCreatedDateRange.length !== 0)
          this.docCreatedDateRange[1] = moment().format("YYYY-MM-DD");

        this.doFilter();
      }
    },
    dtColumnsFiltered() {
      const headersEnabled = this.dtColumnsEnabled;
      const columns = [];
      if (this.allowDocCompare && this.selectCompare) {
        columns.push({ text: "Select", value: "doc_compare", sortable: false });
      }
      if (this.bulkActions.active)
        columns.push({ text: "Select", value: "doc_select", sortable: false });
      return [
        ...columns,
        ...this.dtColumns.filter(
          (c) =>
            headersEnabled.indexOf(c.value) >= 0 &&
            (this.fromTable || !c.fromTable)
        ),
        { text: "Actions", value: "export_menu", sortable: false, align: "end" },
      ];
    },
    itemsPerPageOptions() {
      if (this.cardView)
        return [
          { text: "24 Cards", value: 24 },
          { text: "32 Cards", value: 32 },
          { text: "64 Cards", value: 64 },
        ];
      else
        return [
          { text: "10 Rows", value: 10 },
          { text: "20 Rows", value: 20 },
          { text: "30 Rows", value: 30 },
          { text: "50 Rows", value: 50 },
        ];
    },
    itemsPerPage() {
      if (this.pageSize !== 0) return this.pageSize;
      else if (this.cardView) return this.maxCards;
      else return this.dtItemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    currentPageItems() {
      if (this.itemsPerPage < 0) return this.filteredItems;

      const pageFrom = this.itemsPerPage * (this.currentPage - 1);
      const pageTo = this.itemsPerPage * this.currentPage;
      return this.filteredItems.slice(pageFrom, pageTo);
    },
    canEmail() {
      return this.filteredItems && this.filteredItems.some(d => !d.lifecycle_id || d.email_action_id);
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    cardView() {
      return this.fullScreen && this.viewSelect == "cards";
    },
    filtersToShow() {
      if (!this.showFilters)
        return [];

      return (
          this.config.settings?.find(s => s.setting === "alljobs_allowed_filters")?.value
          ?? "people|review_groups|created_between|types|scores|statuses"
        ).split('|')
        .filter(f => {
          switch (f) {
            case "review_groups":
              return !this.useSideBySideStyle && this.reviewGroupsSummary.length > 1;
            case "types":
              return this.docTypeSummary.length > 1;
            case "scores":
              return !this.useSideBySideStyle && this.fromTable;
            case "people":
            case "created_between":
              return !this.useSideBySideStyle;
            default:
              return true;
          }
        });
    },
    filterWidth() {
      if (this.$vuetify.breakpoint.smAndDown)
        return "50%";
      else if (this.$vuetify.breakpoint.mdAndDown)
        return "25%";

      return `${Math.round(650 / this.filtersToShow.length) / 10}%`;
    },
    ...mapState({
      isLoading: (state) => state.docs.loading,
      cleanItems: (state) => state.docs.docsList,
      docsListUpdated: (state) => state.docs.listUpdated,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      docStatusSummary: (state) => state.docs.docStatusSummaryFiltered,
      docTypeSummary: (state) =>
        state.docs.docTypeSummary.filter((t) => t.showInAllJobs),
      reviewGroupsSummary: (state) => state.docs.reviewGroupsSummary,
      docScoreCategories: (state) => state.docs.docScoreCategories,
      fromTable: (state) => state.docs.fromTable,
      useTranslation: (state) => state.docs.useTranslation,
      classifierTypes: (state) => state.docs.classifierTypes,
      statuses: (state) => state.hierarchies.statuses,
      config: (state) => state.settings.config,
    }),
    bulkWorkflowActions() {
      return this.config?.bulkWorkflowActions || [];
    },
    allowBulkWorkflowActions() {
      return this.config.settings.some(s => s.setting === 'alljobs_allow_bulk_actions' && s.value === 'true')
        && this.bulkWorkflowActions.length !== 0;
    }
  },
  created() {
    this.passedParams = this.$passedParams.get();

    let altLabel = this.$loginState.user.settings.find(
      (s) => s.setting === "RecruiterAltLabel"
    );
    if (altLabel !== undefined && altLabel.value !== " ") {
      this.recruiterAltLabel = altLabel.value;
    }

    this.allowDocCompare = this.$loginState.user.settings.some(
      (s) => s.setting === "alljobs_allow_doc_compare" && s.value === "true"
    );

    this.savedFilters = this.$loginState.getSetting("dwpSavedFilters", []);
    this.docScoresIncluded = [...this.docScoreCategories];
    this.fetchData();
    this.loadDefaultViewSettings();
    this.$emit("documentChangedHandler", this.documentChanged);
    this.$nextTick(() => this.setTableHeight());
  },
  methods: {
    clearAllFilters(){
      this.clearFilter();
      this.clearDateFilter();
      this.peopleIncluded.splice(0); 
      this.doFilter();
    },
    loadDefaultViewSettings() {
      const setting = this.config.settings?.find(
        (s) => s.setting === "alljobs_defaults"
      );
      const defaults =
        setting && setting.value ? JSON.parse(setting.value) : {};

      this.dtColumnsEnabled = this.$loginState.getSetting(
        "dwpColumnSelection",
        defaults.listViewColumnsEnabled || this.dtColumnsEnabled
      );
      this.cardFieldsEnabled = this.$loginState.getSetting(
        "dwpCardFieldSelection",
        defaults.cardViewColumnsEnabled || this.cardFieldsEnabled
      );
      this.viewSelect =
        this.$route.query.view ||
        this.passedParams.view ||
        localStorage.getItem("dlview") ||
        defaults.defaultView ||
        "cards";

      this.showHierarchies =
        this.$loginState.getSetting("dwpShowHierarchies", "false") == "true";
      this.maxCards = Number(localStorage.getItem("dwpMaxCards")) || 24;
      this.dtItemsPerPage = Number(localStorage.getItem("dtRPP")) || 20;
    },
    docListIncludes(ref) {
      return this.compareDocList.includes(utils.removeTags(ref));
    },
    confirmDocCompare() {
      let source =
        this.compareDocList.length === 0
          ? this.currentPageItems
          : this.cleanItems.filter((x) =>
              this.compareDocList.includes(utils.removeTags(x.system_number))
            );
      this.docCompareItems = source.map((object) => ({
        ...object,
        system_number: utils.removeTags(object.system_number),
      }));
      this.changeView("compare");
    },
    toggleDocCompare(ref) {
      ref = utils.removeTags(ref);
      let itemIdx = this.compareDocList.indexOf(ref);
      itemIdx === -1
        ? this.compareDocList.push(ref)
        : this.compareDocList.splice(itemIdx, 1);
    },
    toggleCompareSelect() {
      if (this.bulkActions.active) {
        this.bulkActionCancel();
      }
      this.selectCompare = !this.selectCompare;
      if (!this.selectCompare) {
        this.compareDocList = [];
      }
    },
    openContextFromSideBySide(event, doc) {
      let item = this.cleanItems.find((x) => x.doc_id === doc.doc_id);
      this.openContextMenu(event, item);
    },
    initSideBySide() {
      this.docCompareItems = JSON.parse(
        JSON.stringify([...this.currentPageItems])
      );
    },
    sendToTaleo() {
      if (this.$loginState.canDemo) {
        this.response = {
          Message: "Document posted to Workday",
          Data: null,
          Status: "OK",
          RowsUpdated: 0,
        };
        this.contextMenu = false;
      } else {
        this.reqDialogue.show = true;
      }
    },
    onPaneResize() {
      if (this.tableHeightTimeout) {
        clearTimeout(this.tableHeightTimeout);
        this.tableHeightTimeout = null;
      }
      this.tableHeightTimeout = setTimeout(() => this.setTableHeight(), 50);
    },
    setTableHeight() {
      if (this.viewSelect === "compare") {
        return;
      }
      const paneHeight = this.$refs.mainPane?.clientHeight;
      if (!this.height || !paneHeight || isNaN(paneHeight)) {
        this.tableHeight = null;
      } else if (this.showHeaderCount) {
        this.tableHeight = `${paneHeight - 80}px`;
      } else {
        this.tableHeight = `${paneHeight - 40}px`;
      }
    },
    LanguageDialog() {
      this.languageDialog = true;
    },
    closeLanguageDialog() {
      this.languageDialog = false;
    },
    selectLanguageFilter(lang) {
      this.selectedLanguageFilter = lang;
      this.doFilter();
    },
    getFlagForLang(item) {
      if (!item || item === "en") {
        return "";
      } else {
        let option = this.translateOptions.find((x) => x.value === item);
        return option ? option.flagCode : "";
      }
    },
    dateDoMMMYY(value) {
      if (!value) return "-";
      else return moment(value, "D MMM YYYY").format("Do MMM YYYY");
    },
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.doFilter(true);
    },
    docTypeChanged() {
      this.$loginState.saveSetting("dwpDocTypes", this.docTypeIncluded);
      this.doFilter();
    },
    docStatusChanged() {
      utils.setDefaultStatuses(this.$loginState, this.docStatusIncluded);
      this.doFilter();
    },
    setCardDisplayFields() {
      let fixedFields = [
        "doc_type",
        "system_number",
        "overall_score",
        "doc_status_text",
        "advert_job_title",
        "description",
      ];

      this.cardDisplayFields = this.cardFields
        .filter(
          (x) =>
            this.cardFieldsEnabled.includes(x.value) &&
            !fixedFields.includes(x.value)
        )
        .map((x) => {
          let f = {
            text: x.text,
            value: x.value,
            cssClass: "data-content",
            fromTable: !!x.fromTable,
            style: "",
            format: (r) => (r[f.value] ? r[f.value] : "-"),
          };
          switch (x.value) {
            case "listview2":
              f.cssClass = "data-content mt-1";
              break;
            case "listview3":
              f.format = (r) =>
                r[f.value] ? r[f.value].substring(0, 42) : "-";
              break;
            case "requisitions":
              f.style = "max-height: 1.5em; overflow: hidden";
              break;
            case "hiring_manager":
              f.cssClass = "data-content docList";
              f.style = "max-height: 1.5em; overflow: hidden";
              break;
            case "job_created_at":
              f.format = (r) =>
                r.created_by + " - " + this.dateDoMMMYY(r.job_created_at);
              break;
            case "job_updated_at":
              f.format = (r) => this.dateDoMMMYY(r.job_updated_at);
              break;
          }
          return f;
        });
    },
    fetchData() {
      if (this.isLoading) return;

      let config = this.$store.getters["settings/config"];
      if (config) {
        this.cardFields = config.docListColumns.filter((c) => c.showInCardView);
        this.dtColumns = config.docListColumns
          .filter((c) => c.showInListView)
          .map((x) => {
            let col = {
              text: x.text,
              value: x.value,
              fromTable: !!x.fromTable,
              sort: null,
            };
            if (['job_created_at', 'job_updated_at', 'status_date'].includes(col.value)) {
              col.sort = (a, b) =>
                moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
                  ? 1
                  : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
                  ? -1
                  : 0;
            }
            return col;
          });
        this.setCardDisplayFields();
      }

      if(this.useOpenSearchUI){
        let setting = this.$loginState.user.settings.find((s) => s.setting === 'alljobs_opensearch_score');
        this.openSearchMinScore = parseFloat(setting?.value) || 5.0;
      }

      this.classifierTypes.forEach((ct) => {
        this.dtColumns.push({
          text: ct.ct_name,
          value: `classifier_${ct.ct_id}`,
        });
        this.cardFields.push({
          text: ct.ct_name,
          value: `classifier_${ct.ct_id}`,
        });
      });

      let defaultStatuses;
      if (this.passedParams.statuses && this.passedParams.statuses.length)
        defaultStatuses = this.passedParams.statuses.map((s) => {
          return { status: s };
        });
      else defaultStatuses = utils.getDefaultStatuses(this.$loginState);

      var checkDefaultStatusesExist = false;
      defaultStatuses.forEach((ds) => {
        if (
          this.cleanItems.find(
            (itm) => itm.doc_status.toLowerCase() === ds.status.toLowerCase()
          )
        ) {
          checkDefaultStatusesExist = true;
        }
      });
      if (defaultStatuses.length && checkDefaultStatusesExist) {
        this.docStatusIncluded = defaultStatuses;
      } else {
        this.docStatusIncluded = [...this.docStatusSummary.filter(s => s.show)];
      }

      let docFound = false;
      if (this.passedParams.docTypes && this.passedParams.docTypes.length) {
        // passed default docType
        this.passedParams.docTypes.forEach((dt) => {
          let dtFound = this.docTypeSummary.find((dst) => dst.docType === dt);
          if (dtFound) {
            this.docTypeIncluded.push(dtFound);
            docFound = true;
          }
        });
      }

      if (!docFound) {
        let docTypeIncludedSaved = this.$loginState.getSetting(
          "dwpDocTypes",
          []
        );
        if (!Array.isArray(docTypeIncludedSaved)) docTypeIncludedSaved = [];
        let docTypeIncluded = this.docTypeSummary.filter((t) =>
          docTypeIncludedSaved.some((s) => s && s.tt_id === t.tt_id)
        );
        if (docTypeIncluded.length === 0)
          docTypeIncluded.push(...this.docTypeSummary);

        this.docTypeIncluded = docTypeIncluded;
      }

      this.doFilter();
    },
    saveFilters() {
      if (!this.newFilterKey) return;

      const idx = this.savedFilters.findIndex(
        (f) => f.key === this.newFilterKey
      );
      if (idx > -1)
        this.savedFilters[idx] = {
          key: this.newFilterKey,
          ...this.getFilters(true),
        };
      else
        this.savedFilters.push({
          key: this.newFilterKey,
          ...this.getFilters(true),
        });

      this.$loginState.saveSetting("dwpSavedFilters", this.savedFilters);
      this.newFilterKey = "";
    },
    deleteSavedFilter(f) {
      this.savedFilters.splice(this.savedFilters.indexOf(f), 1);
      this.$loginState.saveSetting("dwpSavedFilters", this.savedFilters);
    },
    restoreFilters(savedFilters) {
      if (savedFilters) {
        const filters = JSON.parse(JSON.stringify(savedFilters));
        this.filterText = filters.filterText || "";
        this.docCreatedDateRange = filters.docCreatedDateRange || [];
        this.docStatusIncluded = filters.docStatusIncluded || [];
        this.docTypeIncluded = filters.docTypeIncluded || [];
        this.docScoresIncluded = filters.docScoresIncluded || [];
        this.peopleIncluded = filters.peopleIncluded || [];
        this.reviewGroupsIncluded = filters.reviewGroupsIncluded || [];
        this.selectedLanguageFilter =
          this.translateOptions.find(
            (o) => o.value == filters.languageFilter
          ) || this.selectedLanguageFilter;
        this.doFilter();
        this.currentFilterKey = filters.key;
        this.isFavFilter = true;
        this.$nextTick(() => {
          this.preSelectedHierarchyFilters = filters.hierarchyFilters || [];
          this.preSelectedClassifierFilters = filters.classifierFilters || [];
        });
      }
    },
    getScoreFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docScoresIncluded.length === this.docScoreCategories.length)
        return this.docScoreCategories.length > 0 ? "All" : "None";

      return this.docScoresIncluded.map((i) => i.text).join(", ");
    },
    getDocTypeFilterText(item, index) {
      if (item && index > 0) return "";
      let included = this.docTypeSummary.filter((s) =>
        this.docTypeIncluded.some((si) => s.docType === si.docType)
      );
      if (included.length === this.docTypeSummary.length)
        return included.length > 0 ? "All" : "None";

      if (included.length > 2) return `${included.length} items`;

      return included.reduce((prev, curr) => {
        return prev + (prev ? ", " : "") + curr.docType;
      }, "");
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      let included = this.docStatusSummary.filter((s) =>
        this.docStatusIncluded.some((si) => s.status === si.status)
      );
      if (included.length === this.docStatusSummary.length)
        return included.length > 0 ? "All" : "None";

      if (included.length > 2) return `${included.length} items`;

      return included.reduce((prev, curr) => {
        return prev + (prev ? ", " : "") + curr.status;
      }, "");
    },
    changefilterText(val) {
      this.tempFilterText = val;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.triggerFilter(), 500);
    },
    changeView(val) {
      this.viewSelect = val;
      this.currentPage = 1;
      if (val === "compare") {
        if(this.showHierarchies){
          this.showHierarchies = !this.showHierarchies;
        }
      } else {
        localStorage.setItem("dlview", val);
      }
    },
    showFilterField() {
      this.showSearch = true;
      this.$nextTick(() => {
        if (this.$refs.filterField) this.$refs.filterField.$refs.input.focus();
      });
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
      this.showSearch = false;
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    doHierarchyFilter(hierarchyFilters, classifierFilters) {
      if (hierarchyFilters)
        hierarchyFilters = JSON.parse(
          JSON.stringify(
            hierarchyFilters.map((f) => {
              return { ht_id: f.ht_id, selected: f.selected };
            })
          )
        );
      if (classifierFilters)
        classifierFilters = JSON.parse(
          JSON.stringify(
            classifierFilters.map((f) => {
              return { ct_id: f.ct_id, selected: f.selected };
            })
          )
        );

      if (!this.showFilters || !this.fullScreen) {
        hierarchyFilters = this.filters?.hierarchyFilters;
        classifierFilters = this.filters?.classifierFilters;
      }

      let docs = [...this.items];

      if (hierarchyFilters) {
        hierarchyFilters.forEach((hf) => {
          if (hf && hf.selected.length) {
            docs = docs.filter((d) =>
              hf.selected.find((s) => {
                let ht = d.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
                return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
              })
            );
          }
        });
      }

      if (classifierFilters) {
        classifierFilters.forEach((cf) => {
          if (cf && cf.selected.length) {
            const cvs = cf.selected.map((c) => c.cv_id);
            docs = docs.filter(
              (d) =>
                d.classifiers.some((c) => cvs.includes(c.cv_id)) ||
                (cvs.includes(0) &&
                  !d.classifiers.some((c) => c.ct_id === cf.ct_id))
            );
          }
        });
      }

      this.preSelectedHierarchyFilters = hierarchyFilters;
      this.preSelectedClassifierFilters = classifierFilters;

      this.filteredItems = Object.freeze(docs);

      /*if (this.viewSelect === "compare") {
        this.initSideBySide();
      }*/
    },
    clearDateFilter() {
      this.docCreatedDateRange.splice(0);
      this.docCreatedDateRangeMenu = false;
      this.doFilter();
    },
    doDateFilter() {
      this.$refs.menu.save(this.docCreatedDateRange);
      this.doFilter();
    },
    getFilters(includeHierarchy) {
      const filters = this.filters || {};

      const params = {
        filterText: (this.filterText || filters.filterText || "")
          .trim()
          .toLowerCase(),
        myJobs: this.filters.myJobs || false,
        docCreatedDateRange: this.showFilters
          ? this.docCreatedDateRange
          : filters.docCreatedDateRange,
        docStatusIncluded: this.showFilters
          ? this.docStatusIncluded
          : filters.docStatusIncluded,
        docScoresIncluded: this.showFilters
          ? this.docScoresIncluded
          : filters.docScoresIncluded,
        docTypeIncluded: this.showFilters
          ? this.docTypeIncluded
          : filters.docTypeIncluded,
        canCopyTo: this.showFilters ? null : filters.canCopyTo,
        peopleIncluded: this.showFilters
          ? this.peopleIncluded
          : filters.peopleIncluded,
        reviewGroupsIncluded: this.showFilters
          ? this.reviewGroupsIncluded
          : filters.reviewGroupsIncluded,
        languageFilter:
          this.filters.languageFilter || this.selectedLanguageFilter.value,
        docIdsIncluded: this.bulkActions.active ? this.bulkActions.docActionsAvailable.map(d => d.doc_id) : null
      };

      if (includeHierarchy) {
        params.hierarchyFilters =
          this.showFilters && this.fullScreen
            ? this.hierarchyFilters
            : filters.hierarchyFilters;
        params.classifierFilters =
          this.showFilters && this.fullScreen
            ? this.classifierFilters
            : filters.classifierFilters;
      }

      return JSON.parse(JSON.stringify(params));
    },
    async doOpenSearch(text){

      let docTypes = this.docTypeIncluded.map(d => {
        return d.docType
      });

      let data = {
        text: text,
        doc_types: docTypes
      }

      let processPromise = new Promise((resolve, reject) => {
        axios.post("document/openSearch/", data)
          .then((resp) => {
            this.openSearchInProgress = false;
            resolve(resp.data.Data);
          })
          .catch(err => {
            this.openSearchInProgress = false;
            reject(err);
          });
      });
      return processPromise;
    },
    async doFilter(preventPageReset) {
      if (this.isLoading) return;

      if (this.isFavFilter) {
        this.currentFilterKey = this.$route.meta.text.replace("Jobs", "");
        this.isFavFilter = false;
      }

      let ignoreSort = false;
      const filters = this.getFilters();
      this.items = Object.freeze(this.$store.getters["docs/filter"](filters));

      if(this.items.length === 0 && this.useOpenSearchUI){
        this.openSearchInProgress = true;
        let newList = await this.doOpenSearch(filters.filterText);
        let idMap = newList?.filter(x => x.score >= this.openSearchMinScore).map(x => { return Number(x.id) });
        this.items = Object.freeze(this.cleanItems.filter(x => idMap.includes(x.doc_id)).sort((a, b) => {
          const scoreA = newList.find(s => Number(s.id) === a.doc_id)?.score || 0;
          const scoreB = newList.find(s => Number(s.id) === b.doc_id)?.score || 0;
          return scoreB - scoreA;
        }));
        ignoreSort = true;        
      }

      this.doHierarchyFilter(
        this.preSelectedHierarchyFilters,
        this.preSelectedClassifierFilters
      );

      if (this.bulkActions.active) {
        const ids = this.items.map(d => d.doc_id);
        this.bulkActions.selectedDocList = this.bulkActions.selectedDocList.filter(id => ids.includes(id));
      }

      if(ignoreSort){
          this.sortColumn = null;
          this.sortDesc = null;
          this.prevSort = null;
      } else {
        if (filters.filterText && this.sortColumn !== "matchRating") {
          //override sort by matchrating
          this.prevSort = {
            sortColumn: this.sortColumn,
            sortDesc: this.sortDesc,
          };
          this.sortColumn = "matchRating";
          this.sortDesc = true;
        } else if (
          !filters.filterText &&
          this.sortColumn === "matchRating" &&
          this.prevSort
        ) {
          this.sortColumn = this.prevSort.sortColumn;
          this.sortDesc = this.prevSort.sortDesc;
          this.prevSort = null;
        }
      }

      if (!preventPageReset || this.currentPageItems.length === 0)
        this.currentPage = 1;
    },
    saveColumnSelection() {
      this.$loginState.saveSetting("dwpColumnSelection", this.dtColumnsEnabled);
    },
    saveCardFieldSelection() {
      this.$loginState.saveSetting(
        "dwpCardFieldSelection",
        this.cardFieldsEnabled
      );
      this.setCardDisplayFields();
    },
    docDetails(item) {
      return {
        doc_id: item.doc_id,
        tmpl_id: item.tmpl_id,
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        preview: item.preview,
        viewAction: item.viewAction,
        listActions: [],
        usesWorkflow: !!item.lifecycle_id,
        error_count: item.error_count,
        warning_count: item.warning_count,
        missing_data: item.missing_data,
      };
    },
    closePreview() {
      if (this.selectedDocument && this.selectedDocument.doc_id)
        document.getElementById(`actions_${this.selectedDocument.doc_id}`)?.focus();
      this.selectedDocument = null;
      this.$refs.mainPane.style.height = "100%";
      this.$emit("deSelectDocument");
      this.$nextTick(() => this.setTableHeight());
    },
    selectDocument(event, item) {
      if (this.bulkActions.active) {
        this.toggleDocToAction(item.doc_id);
      } else if (this.selectCompare) {
        this.toggleDocCompare(item.system_number);
      } else if (
        (this.clickToPreview || this.rowClickAction === "preview") &&
        this.previewEnabled
      ) {
        this.openPreview(item);
        this.$emit("selectDocument", this.docDetails(item));
      } else if (this.rowClickAction === "menu") {
        this.openContextMenu(event, item);
      } else {
        this.openDocument(item);
      }
    },
    openPreview(item) {
      this.contextMenu = false;
      if (!this.selectedDocument) {
        if (this.height) this.$refs.mainPane.style.height = "50%";
        else this.$refs.mainPane.style.height = `${window.innerHeight / 2}px`;

        this.$nextTick(() => {
          this.setTableHeight();
          this.$refs.closePreview?.$el.focus();
        });
      }
      this.selectedDocument = item;
    },
    openDocument(item) {
      this.contextMenu = false;
      this.$emit("openDocument", this.docDetails(item));
    },
    setPageSize(value) {
      if (this.pageSize === 0) {
        if (this.cardView) {
          localStorage.setItem("dwpMaxCards", value);
          this.maxCards = value;
        } else {
          localStorage.setItem("dtRPP", value);
          this.dtItemsPerPage = value;
        }
      }
    },
    exportToCSVWithParts() {
      if (this.docTypeIncluded.length !== 1) {
        this.response = {
          Status: "info",
          message: "A single document type must be selected",
        };
        return;
      }

      const params = {
        tmplId: this.docTypeIncluded[0].tmpl_id,
        docIds: this.filteredItems.map((d) => d.doc_id).join(","),
        authToken: this.$loginState.rmapiAccessToken,
      };
      this.formPost(
        process.env.VUE_APP_API_BASE_URL + "document/docListDetailsCsv",
        params
      );
    },
    formPost(path, params) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = path;

      let key;
      for (key in params) {
        if (Object.hasOwn(params, key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    exportToCSV() {
      let csvHeaders = [];
      this.config.docListColumns
        .filter((c) => c.showInExport)
        .filter((c) => 
          (this.fromTable || !c.fromTable) && c.value === 'advert_job_title' ? (this.dtColumnsEnabled.includes('advert_job_title') || this.cardFieldsEnabled.includes('advert_job_title')) : true)
        .forEach((c) => {
          csvHeaders.push(c);
          if (
            c.value === "doc_status_text" &&
            this.filteredItems.some(
              (x) =>
                x.lifecycle_status_non_responders ||
                x.lifecycle_status_responders
            )
          ) {
            csvHeaders.push({
              text: `Waiting For`,
              value: "lifecycle_status_non_responders",
            });
            csvHeaders.push({
              text: `Completed By`,
              value: "lifecycle_status_responders",
            });
          }
        });

      let data = csvHeaders.map((h) => '"' + h.text + '"').join(",");
      data += "\n";

      this.filteredItems.forEach((d) => {
        data += csvHeaders
          .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
          .join(",");
        data += "\n";
      });

      utils.downloadFile(
        data,
        `${this.$loginState.user.client.replace(
          new RegExp("\\s+", "g"),
          "_"
        )}_documents_${moment().format("YYYYMMDDhhmmss")}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    openContextMenu(e, item) {
      this.exportDoc = this.docDetails(item);
      this.getDocListActions();
      e.preventDefault();
      this.contextMenu = false;
      if (e.clientX || e.clientY) {
        this.posX = e.clientX;
        this.posY = e.clientY;
      } else if (document.activeElement) {
        const rect = document.activeElement.getBoundingClientRect();
        this.posX = rect.left + window.scrollX;
        this.posY = rect.top + window.scrollY;
      }
      this.$nextTick(() => {
        this.contextMenu = true;
      });
    },
    copyDocument(doc, tmpl_id) {
      this.contextMenu = false;
      this.$nextTick(() => {
        this.$emit("copyDocument", doc, tmpl_id);
      });
    },
    docToClipboard(section) {
      const id = this.exportDoc.system_number;
      if (id) {
        utils
          .fetchDocumentData(id)
          .then((resp) => {
            documentExport.docToClipboard(resp.document, section);
            this.response = {
              Status: "OK",
              Message: "HTML copied to clipboard",
            };
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
        this.contextMenu = false;
        this.exportDoc = {};
      }
    },
    generatePDF() {
      const id = this.exportDoc.system_number;
      this.exportGenerating = true;
      if (id) {
        utils
          .fetchDocumentData(id, null, null, null, this.exportDoc.viewAction)
          .then((resp) => {
            const serverSidePdfs = this.config.settings.some(
              (s) => s.setting === "document_serverside_pdf" && s.value === "true"
            );
            documentExport
              .generateDocumentExport("PDF", resp.document, serverSidePdfs)
              .then(() => {
                this.exportGenerating = false;
              });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.exportGenerating = false;
          });
        this.contextMenu = false;
        this.exportDoc = {};
      }
    },
    generateDOCX(full) {
      const id = this.exportDoc.system_number;
      if (id) {
        this.exportGenerating = true;
        utils
          .fetchDocumentData(id, null, null, null, this.exportDoc.viewAction)
          .then((resp) => {
            documentExport
              .generateDocumentExport("DOCX", resp.document, full)
              .then(() => {
                this.exportGenerating = false;
              });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
        this.contextMenu = false;
        this.exportDoc = {};
      }
    },
    generateRTF(section) {
      const id = this.exportDoc.system_number;
      if (id) {
        this.exportGenerating = true;
        utils
          .fetchDocumentData(id, null, null, null, this.exportDoc.viewAction)
          .then((resp) => {
            documentExport
              .generateDocumentExport("RTF", resp.document, section)
              .then(() => {
                this.exportGenerating = false;
              });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
        this.contextMenu = false;
        this.exportDoc = {};
      }
    },
    generateJobShareTemplate() {
      const id = this.exportDoc.system_number;
      if (id) {
        utils
          .fetchDocumentData(id)
          .then((resp) => {
            documentExport.generateJobShareTemplate(resp.document, null);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
        this.contextMenu = false;
        this.exportDoc = {};
      }
    },
    bulkActionDocsIncludes(doc_id) {
      return this.bulkActions.selectedDocList.includes(doc_id);
    },
    toggleDocToAction(doc_id) {
      let itemIdx = this.bulkActions.selectedDocList.indexOf(doc_id);
      itemIdx === -1
        ? this.bulkActions.selectedDocList.push(doc_id)
        : this.bulkActions.selectedDocList.splice(itemIdx, 1);
    },
    bulkActionCancel() {
      this.bulkActions.active = false;
      this.bulkActions.processing = false;
      this.bulkActions.selectedAction = null;
      this.bulkActions.selectedDocList.splice(0);
      this.doFilter();
    },
    bulkActionToggleSelectAll() {
      if (this.bulkActions.selectedDocList.length === this.filteredItems.length) {
        this.bulkActions.selectedDocList.splice(0);
      } else {
        this.bulkActions.selectedDocList.splice(0);
        this.bulkActions.selectedDocList.push(...this.filteredItems.map(d => d.doc_id));
      }
    },
    bulkActionSelect(action) {
      if (this.bulkActions.selectedAction === action) {
        this.bulkActionCancel();
        return;
      }

      this.bulkActions.active = true;
      this.bulkActions.selectedAction = action;
      this.bulkActions.docActionsAvailable.splice(0);
      this.bulkActions.selectedDocList.splice(0);
      if (this.selectCompare) {
        this.selectCompare = false;
      }
      this.bulkActionFilter();
    },
    bulkActionFilter() {
      if (this.bulkActions.processing)
        return;

      let possibleError = false;
      this.bulkActions.loading = true;
      axios
        .get("workflow/getDocumentsForAction/" + this.bulkActions.selectedAction.action_ids)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.bulkActions.docActionsAvailable = resp.data.Data;
            this.doFilter();
          }
          this.bulkActions.loading = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          this.actionDialogue.loading = false;
          console.log(err);
        });
    },
    bulkActionRun() {
      if (this.bulkActions.selectedAction.emailDoc)
        this.showEmailDialog(true);
      else
        this.bulkActions.showConfirm = true;
    },
    async bulkActionConfirm() {
      this.bulkActions.processing = true;
      const docIds = JSON.parse(JSON.stringify(this.bulkActions.selectedDocList));
      for (let index = 0; index < docIds.length; index++) {
        if (this.bulkActions.processing) {
          this.bulkActions.processingIndex = index;
          const doc_id = docIds[index];
          const action = this.bulkActions.docActionsAvailable.find(a => a.doc_id === doc_id);
          if (doc_id && action)
            await this.doWorkflowAction(action, null, null, doc_id);
        }
      }

      axios
        .post("document/notifySubscribers/", {
          docIds: docIds,
          eventType: "doc_header_updated"
        })
        .then(() => {
          this.bulkActions.active = false;
          this.bulkActions.selectedAction = null;
          this.bulkActions.selectedDocList.splice(0);
          this.bulkActions.processing = false;
          this.bulkActions.showConfirm = false;
          this.doFilter();
          this.$store.dispatch("tasks/refresh");
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          return false;
        });
    },
    handleEnter(event, doc) {
      if (event?.srcElement?.type === "button")
        return;

      else if (this.bulkActions.active)
        this.bulkActions.selectedDocList.length && this.bulkActionRun();
      else if (this.selectCompare)
        this.compareDocList.length && this.confirmDocCompare();
      else
        this.selectDocument(event, doc);
    },
    handleEsc() {
      if (this.bulkActions.active)
        this.bulkActionCancel();
      if (this.selectCompare)
        this.selectCompare = false;
    },
    showEmailDialog(multi) {
      if (multi) {
        this.exportDoc = {};
      } else {
        this.bulkActions.selectedDocList.splice(0);
      }

      this.emailDialogue.show = true;
      this.emailDialogue.multi = multi;
      this.emailDialogue.sending = false;
    },
    emailDoc() {
      if (!this.bulkActions.active && this.exportDoc && this.exportDoc.usesWorkflow) {
        this.emailDialogue.sending = true;
        if (this.emailDialogue.action) {
          this.doWorkflowAction(this.emailDialogue.action, true);
        }

        return;
      } else {
        if (this.bulkActions.selectedDocList.length === 0) {
          this.triggerNotification(
            "Please select at least one document",
            "warning"
          );
          return;
        }
        this.emailDialogue.sending = true;
        let data = {
          to_address: this.emailDialogue.value,
          docs: this.bulkActions.selectedDocList,
        };

        axios
          .post("document/emaildoc/", data)
          .then((resp) => {
            if (resp.data.Status == "OK") {
              this.triggerNotification("Email Sent Successfully", "success");
              this.emailDialogue.show = false;
              this.emailDialogue.value = "";
              //this.items =[...this.items];
              //this.items = JSON.parse(JSON.stringify(this.items));
              this.contextMenu = false;
            } else {
              this.triggerNotification(resp.data.Message, "error");
              this.emailDialogue.show = false;
              this.emailDialogue.value = "";
              this.contextMenu = false;
            }
            this.emailDialogue.sending = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.triggerNotification(err, "error");
            }
            this.awaitingTaleo = false;
          });
      }
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    async doWorkflowAction(action, savedata, dcData, doc_id) {
      doc_id = doc_id || this.exportDoc?.doc_id;

      if (!doc_id) return;
      if (action.emailDoc && !this.emailDialogue.value) {
        this.emailDialogue.action = action;
        this.bulkActions.selectedDocList.splice(0);
        this.emailDialogue.multi = false;
        this.emailDialogue.sending = false;
        this.emailDialogue.show = true;
        this.contextMenu = false;
        return;
      }
      this.contextMenuBusy = true;
      //  this.isLoading = true;
      let dc = this.dataCapture;
      let request = {
        doc_id: doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
        returnHeaderOnly: true,
        delayNotirySubscribers: this.bulkActions.active
      };
      let dcReadOnly = null;
      if (savedata) {
        if (action.emailDoc) {
          request.preProcessResponse = { toAddress: this.emailDialogue.value };
        } else if (dcData.inputs && dcData.inputs.length) {
          request.preProcessResponse = dcData;
          dcReadOnly = this.document.state.dataCaptureActionsMain.find(
            (x) =>
              x.isDataCaptureViewer &&
              x.data_capture_type_id === action.data_capture_type_id
          );
        } else if (dcData.hr_id || dcData.hr_id_copy) {
          request.preProcessResponse = { ...dcData };
          // } else if (action.questionnaire_id && dcData.data) {
          //   request.preProcessResponse = {
          //     document_data_capture_id: dcData.document_data_capture_id,
          //     answer: dcData.data,
          //     isComplete: dcData.isComplete,
          //     questionnaire_action: dcData.questionnaire_action,
          //   };
        } else if (action.data_capture_method === "review_group") {
          request.preProcessResponse = { newReviewGroupId: dcData };
        } else if (
          action.data_capture_method === "requisition" ||
          action.data_capture_method === "requisition_new"
        ) {
          request.preProcessResponse = { requisitions: dcData.requisitions };
        } else {
          request.preProcessResponse = dcData.value;
        }
      }

      return axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          let result = { redirectURL: "" };
          if (resp.data.Status === "OK") {
            if (resp.data.Data.clipboard_content) {
              this.copyLinkToClipboard(resp.data.Data.clipboard_content);
            }
            if (resp.data.Data.dataCapture) {
              dc.action = action;
              dc.action.definition = resp.data.Data.dataCapture;
              //   if (dc.action.isDataCaptureEditInHeader) {
              //     this.openHeader();
              //   } else if (
              if (
                dc.action.isDataCaptureEditPopup ||
                dc.action.definition.lifecycle_role_id_created ||
                dc.action.definition.isCommentBased ||
                dc.action.definition.isCheckBased
              ) {
                dc.action.definition.value = null;
                dc.show = true;
              }
              this.contextMenu = false;
              this.contextMenuBusy = false;
              //  this.isLoading = false;
              return;
            } else {
              if (
                dcReadOnly &&
                dcReadOnly.definition &&
                dcReadOnly.definition.inputs?.length
              ) {
                dcReadOnly.definition.inputs.forEach((i) => {
                  let newVal = request.preProcessResponse.find(
                    (r) => r.check_type_id === i.check_type_id
                  );
                  if (newVal) i.value = newVal.value;
                });
              }
              dc.action = null;
              dc.show = false;
              if (resp.data.Data.stateChanged) {
                if (resp.data.Data.documents && resp.data.Data.documents.length) {
                  this.$store.dispatch("docs/updateDoc", resp.data.Data.documents[0]);
                } else {
                  //state changed but no doc - probably don't have permission to view the document any more.
                  this.$store.dispatch("docs/removeDoc", request.doc_id);
                }
                if (!this.bulkActions.active)
                  this.$store.dispatch(
                    "tasks/refresh",
                    request.doc_id
                  );
              }
              if (action.download_format) {
                this.exportGenerating = true;
                documentExport.generateDownload(action.download_format, this.exportDoc)
                  .then(() => this.exportGenerating = false);
              }
              if (resp.data.Data.redirectURL) {
                result.redirectURL = resp.data.Data.redirectURL;
              }
              if (resp.data.Data.copyAction) {
                this.$emit(
                  "copyDocument",
                  { doc_id: request.doc_id },
                  resp.data.Data.copyAction.tmpl_id_destination,
                  null,
                  resp.data.Data.copyAction.action_available_id
                );
              }
            }
          } else {
            console.log(resp.data.Message);
          }
          this.closeEmailDialogue();
          this.contextMenu = false;
          this.contextMenuBusy = false;
          resp.data.Timeout = -1;
          this.response = resp.data;
          return result;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.closeEmailDialogue();
          this.contextMenu = false;
          this.contextMenuBusy = false;
          return false;
        });
    },
    closeEmailDialogue() {
      this.emailDialogue.show = false;
      this.emailDialogue.action = null;
      this.emailDialogue.value = "";
      this.emailDialogue.valid = false;
    },
    saveDataCapture(dataCapture, action) {
      this.doWorkflowAction(action, true, dataCapture);
    },
    cancelDataCapture() {
      this.dataCapture.show = false;
    },
    getIcon(aa) {
      switch (aa.name?.split(" ")[0].substring(0, 5).toLowerCase().trim()) {
        case "copy":
          return "mdi-content-copy";
        case "creat":
          return "mdi-card-plus-outline";
        case "post":
          return "mdi-transfer-right";
        case "email":
          return "mdi-email";
        case "share":
          return "mdi-share";
        case "downl":
          return "mdi-download";
        case "expor":
          return "mdi-share-variant";
        case "appro":
          return "mdi-check-outline";
        case "delet":
          return "mdi-delete";
        case "revie":
          return "mdi-account-group";
        case "invit":
        case "add":
          return "mdi-account-plus";
      }
      return "mdi-hammer-wrench";
    },
    copyLinkToClipboard(text) {
      if (this.html_export_sections.some((x) => x === text)) {
        this.docToClipboard(text);
      } else {
        let container;
        let dialog = document.getElementsByClassName(
          "v-dialog--active v-dialog--fullscreen"
        );
        if (dialog && dialog.length) container = dialog[0];
        else container = document.body;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        container.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        container.removeChild(textarea);
      }
    },
    getDocListActions() {
      if (!this.exportDoc || !this.exportDoc.usesWorkflow) {
        return;
      }
      this.contextMenuBusy = true;

      axios
        .get(`workflow/getDocumentListActions/${this.exportDoc.doc_id}`)
        .then((resp) => {
          if (resp.data.Status == "OK") {
            this.exportDoc.listActions = resp.data.Data;
          }
          this.contextMenuBusy = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.triggerNotification(err, "error");
          }
        });
    },
    openMatchMenu(e, item) {
      if (e.preventDefault) e.preventDefault();
      this.matchMenu.show = false;
      this.matchMenu.posX = e.clientX;
      this.matchMenu.posY = e.clientY;
      this.matchMenu.documents = [item];
      this.matchMenu.matchColumn = "matches_similar_content_same_name";
      this.matchMenu.title = "Potential Duplicates";
      this.matchMenu.documentType = this.catalogueDocType;
      if (this.matchMenu.documents.length) { 
      this.$nextTick(() => {
        this.matchMenu.show = true;
      });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 48px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
  }

  .right-col {
    padding-top: 0;
    padding-right: 5px;
    transition: padding 200ms;
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }

  @media (max-width: 600px) {
    .right-col {
      padding-left: 0;
    }
  }
}

.hierarchies-collapsed {
  width: 20px;

  min-height: calc(100vh - 103px) !important;
  .showHierarchies {
    position: fixed;
    top: 72px;
    left: 5px;
  }
}

.theme--light .hierarchies-collapsed {
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark .hierarchies-collapsed,
.theme--dark.v-navigation-drawer {
  background-color: $primary-background-dark;
  border-right: 1px solid hsla(0, 0%, 100%, 0.12);
}

.showHierarchies {
  top: 22px;
  right: -15px;
}

.filter-col {
  flex-grow: 1;
  flex-shrink: 0;
}

.header-buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
}

.btn-background {
  height: 44px;
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.btn-background-opensearch{
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.headerCount {
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: -45px 0 15px 0;
  span {
    margin: 0 20px;
  }
}

::v-deep mark {
  color: inherit;
  border-radius: 5px;
  background-color: rgba(#0372ee, 0.3);
}

::v-deep .v-select__selections {
  overflow: hidden;
  white-space: nowrap;
  flex-flow: nowrap;
}

.sidebyside {
  padding-top: 0px !important;
  padding-right: 0px !important;
  height: calc(100vh - 150px) !important;
  max-height: calc(100vh - 150px) !important;
}

.sideviewfixheight {
  max-height: calc(100vh - 150px) !important;
  padding-left: 0px;
}

.sidebysidepadding {
  padding-top: 15px;
}

.fixedFooter {
  @media (max-width: 600px) {
    .docList .footer-actions {
      padding-left: 15px;
    }
  }

  .left-col {
    height: calc(100vh - 103px) !important;
  }
  .mainPane {
    padding-bottom: 56px;
  }

  .docList {
    .footer-actions {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 4;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.multipane {
  .multipane-resizer {
    margin: 0;
    left: 0;
    position: relative;
    height: 20px;
    &:before {
      display: block;
      content: "";
      width: 40%;

      position: absolute;
      left: 30%;
      margin-top: 5px;
      margin-left: -20px;
      border: 2px solid #ccc;
      border-radius: 2px;
    }
    &:hover {
      &:before {
        border-color: #999;
      }
    }
  }

  .mainPane {
    padding-top: 12px;
    padding-right: 12px;
    min-height: 100px;
    max-height: 100%;
    &.fixedHeader {
      padding-right: 0;
      overflow: hidden;
    }

    .sidebyside {
      padding-top: 0px !important;
      padding-right: 0px !important;
      min-height: 100vh !important;
      max-height: 100vh !important;
    }
  }
  .previewPane {
    width: 100%;
    height: 100px;
    display: flex;
    flex-grow: 1;
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      right: 25px;
      width: 44px;
      button {
        z-index: 1;
        opacity: 0.8;
      }
    }

    .docPreview {
      width: 100%;
      min-height: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 12px 85px 0 85px;
      //height: calc(50vh - 35px);
      //overflow-y: auto;
    }
  }
  &.hasPreview {
    height: calc(100vh - 105px);
    .mainPane {
      height: 100%;
      overflow-x: hidden;
      padding-bottom: 12px;
    }
  }
  // &:not(.hasPreview) {
  //   .mainPane {
  //     height: 100%;
  //     overflow: unset;
  //   }
  // }
}

.v-application.theme--light {
  .previewPane {
    ::v-deep .docPreview {
      background-color: $background-color-light;
      > .v-card {
        box-shadow: 0 0 4px 0 #b5b5b5;
      }
    }
  }
}

.v-application.theme--dark {
  .previewPane {
    ::v-deep .docPreview {
      > .v-card {
        border: 1px solid #b5b5b5;
      }
    }
  }
}

.v-application.theme--light .btn-background.depressed {
  background-color: lightgray !important;
}
.v-application.theme--dark .btn-background.depressed {
  background-color: black !important;
}

.v-application.theme--light .btn-background-opensearch.depressed {
  background-color: lightgray !important;
}
.v-application.theme--dark .btn-background-opensearch.depressed {
  background-color: black !important;
}

.v-application.theme--light .docList .data-label {
  color: rgb(85, 85, 85);
}
.v-application.theme--dark .docList .data-label {
  color: rgb(207, 207, 207);
}

.docList {
  .footer-actions {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px;

    div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }
  }

  .v-card.doc-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-card.on-hover.theme--light .v-list {
    background-color: #f7f7f7;
  }

  .v-card.on-hover.theme--dark .v-list {
    background-color: #272727;
  }

  .job-description {
    font-size: 0.75rem;
    overflow: hidden;
    display: flow-root;
    position: relative;
    height: 12em;
    line-height: 1.8;
  }

  .job-description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1) 50%);
  }

  .v-card.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#1e1e1e, 0),
        rgba(#1e1e1e, 1) 50%
      );
    }
  }

  .v-card.on-hover {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#f7f7f7, 0),
        rgba(#f7f7f7, 1) 50%
      );
    }
  }

  .v-card.on-hover.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#272727, 0),
        rgba(#272727, 1) 50%
      );
    }
  }

  .data-content {
    margin-top: -5px;
    padding-top: 0;
  }
  .data-content-2 {
    margin-top: -7px;
    min-height: auto !important;
    max-height: auto;
    padding-bottom: 0;
  }
  .data-label {
    font-size: 0.6rem;
    padding: 0 5px 0 16px;
  }

  .data-value {
    font-size: 0.9rem;
  }
  .data-value-2 {
    font-size: 0.75rem;

    padding-bottom: 10px;
  }

  .doc-card .status-chip {
    background-color: transparent !important;
    :before {
      content: "\2022";
      margin-right: 0.5em;
    }
  }

  ::v-deep .v-data-table {
    tbody > tr,
    tbody > tr span {
      cursor: pointer;
    }
    tr td:last-child {
      padding: 0 10px 0 0;
    }
    .status-chip {
      height: 2.35em;
      min-width: 7.2em;
      display: inline-flex;
      justify-content: center;
      font-size: 0.5625rem;
    }
  }
}

::v-deep {
  .customFlag {
    transform: scale(0.35) !important;
    -ms-transform: scale(0.35) !important;
    -webkit-transform: scale(0.35) !important;
    -moz-transform: scale(0.35) !important;
  }
}

.recruiterSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.recruiterChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.recruitersMenu {
  min-height: 150px;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}

.openSearchExpand {
  padding-top:12px;
}

.allJobsOptions  {
  height: calc(100vh - 100px);
  width: 350px !important;
  display: inline-flex;
  position: fixed;

  .expansionHolder {
    display:inline-flex; 
    float:right; 
    align-items: flex-start; 
    padding-top:10px; 
    padding-top: 12px; 
    padding-right: 3px;
  }

  .navDrawer {
    width: 350px !important;

    .navTabs {
      width: 335px !important;
      margin-right: 15px;
      padding-right: 15px;
    }
  }

  .navDrawerMini {
    width: 25px !important;

  }
}

.allJobsOptionsMini {
  width: 25px !important;
  height: calc(100vh - 100px);
  display: inline-flex;
  position: fixed;

}

.jobsHolder{
  width: calc(100vw - 380px);
  height: calc(100vh - 105px);
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0;
}

.jobsHolderMini {
  width: calc(100vw - 55px);
  height: calc(100vh - 105px);
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0;
}

  .icon {
        order: 0;
    }

    .header {
        order: 1;
    }  
</style>
