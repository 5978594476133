
<script setup>
//#region Imports
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed, nextTick  } from 'vue';
import axios from 'axios';
import { inject } from 'vue';
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import Notification from "@/components/common/SnackBar.vue";
import draggable from "vuedraggable";
//#endregion

//#region Reactive
const loginState = inject('loginState');
const store = inject('store');

const skillManagerState = reactive({
    loading: true,
    user_role: "User",
    adminHierarchyView: [],
    adminCurrentHierarchy: null,
    adminCurrentHierarchy_id: null,
    activeSkillList: [],
    framework_items: [],
    reviewGroups: [],
    activeReviewGroup: null,
    editReviewGroup: null,
    currentReviewGroup: null,
    framework: null,
    data: [],
    hierarchy: null,
    filteredData: [],
    treeSelection: [],
    tabs: [
      "Skills Dashboard",
      "Skills Workspace",
      "Skills Framework Admin",
      "Market Insights"
    ],
    headers: [
          {
            text: 'Skill Name',
            value: 'name',
          },
          {
            text: 'Type',
            value: 'type',
          },
          {
            text: 'Client Override Name',
            value: 'client_name',
          },
          {
            text: 'Usage Count',
            value: 'id_count',
          }, 
          { 
            text: 'Actions', 
            value: 'actions', 
            sortable: false
          }
    ],
    skillLoading: true,
    hierarchies: [],
    activeHierarchy: null,
    filteredHierarchies: [],
    selectedSkillTag: null,
    selectedSkillCategories: [],
    notification: {
      text: "",
      type: "success"
    },
    extractSkillsDialog: {
      show: false,
      data: [],
      selected: [],
      loading: false,
      action: "extract",
      framework_review_link_id: null
    },
    addSkillsDialog: {
      show: false,
      data: [],
      selected: []
    },
    reviewGroupHeaders: [
      {
        text: 'Review Group',
        align: 'start',
        value: 'name',
      },
      { text: 'Document Type', sortable: false, value: 'doc_type' },
      { text: 'Users', value: 'user_count' },
      { text: 'Jobs', value: 'doc_count' },
      { text: 'Creator', value: 'creator' },
      { text: 'Status', value: 'status' },
      { text: 'Date Created', value: 'date_created' },
      { text: 'Actions', sortable: false, value: 'actions' }
    ],
    createReviewGroupDialog: createDialogDefaultState(),
    createNewReviewGroupNodeId: null,
    existingHrNodes: [],
    activeView: "skill_proficiency",
    nodeHrs: [],
    reviewGroupLinks: [],
    nodeHrsSelected: [],
    addNewHrLinks: true,
    newHrGroup: {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    },
    selectedGradingTypes: [],
    frameworkGradingType: [],
    allCategories: [],
    userList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    saving: false,
    reviewGroupSearchText: ""
});

let rerender = ref(0);
let rerenderTreeKey = ref(0);
let priorityContextMenu = ref(null);
//#endregion
 
//#region Trash
/*const useClassification = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_classification_used' && s.value === "true"
))

const useCategorisation = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_categorisation_used' && s.value === "true"
))

const useRatings = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_rating_used' && s.value === "true"
))*/
//#endregion

//#region Computed

const filteredUserList = computed(() => {
  if(skillManagerState.createReviewGroupDialog.data.search === "" || !skillManagerState.createReviewGroupDialog.data.search){ return skillManagerState.userList }
  return skillManagerState.userList.filter(x => x.name && x.name.toLowerCase().includes(skillManagerState.createReviewGroupDialog.data.search?.toLowerCase()))
});

const filteredData = computed(() => {
  return skillManagerState.data.filter(x => skillManagerState.selectedSkillCategories.includes(x.type) || !x.type)
});

const filteredHierarchyGroupData = computed(() => {
  let returnData = skillManagerState.reviewGroupLinks.map(x => {
    //let filteredData = x.data.filter(d => skillManagerState.selectedSkillCategories.includes(d.type) || !d.type);
    let filteredAvailable = x.available.filter(d => skillManagerState.selectedSkillCategories.includes(d.type) || !d.type);

    return {
      ...x,
      //data: filteredData,
      available: filteredAvailable
    }
  });

  return returnData;
})

const getModelValue = (skill, hr) => computed(() => {
  const record = hr.skills_data.find((sd) => sd.tag_value_id === skill.tag_value_id);
  return record ? record.tag_rating_id : null;
});

const getGradingTypes = () => computed(() => {
  let id = skillManagerState.framework.tag_grading_type_id;
  let type = skillManagerState.tagData.tagGradingTypes?.find(x => x.tag_grading_type_id === id);
  return type ? type.values.filter(x => x) : [];
})
//#endregion

//#region Methods

const fallbackRecord = () => {
  return {
    framework_data_id: null,
    framework_id: skillManagerState.framework?.framework_id,
    framework_review_id: skillManagerState.activeReviewGroup,
    skill_id: null,
    tag_rating_id: null,
    hr_node_id: null,
    active: 1
  }
};

const skillExists = (skill, hr) => {
  if(hr.skills_data?.length === 0){
    return false;
  } else {
    return hr.skills_data?.map(x => x.tag_value_id).includes(skill.tag_value_id);
  }
  /*if(hr.skills_data?.length === 0){
    return false;
  } else {
    return hr.skills_data?.map(x => x.tag_value_id).includes(skill.tag_value_id);
  }*/
};

const groupSkill = (skill, group) => {
  return group.data?.map(x => x.tag_value_id).includes(skill.tag_value_id);
}

function setUpData(resp){
  skillManagerState.data = [];
  skillManagerState.skillLoading = false;
  skillManagerState.data = resp.data.skill_data;
  skillManagerState.tagData = resp.data.tag_data;
  skillManagerState.adminCurrentHierarchy_id = skillManagerState.adminHierarchyView.find(x => x.node_id === resp.data.reviewNode);
  skillManagerState.adminCurrentHierarchy = resp.data.hierarchies;
  skillManagerState.frameworkGradingType = skillManagerState.tagData.tagGradingTypes.find(x => x.tag_grading_type_id === skillManagerState.framework.tag_grading_type_id);
  skillManagerState.selectedGradingTypes = skillManagerState.frameworkGradingType.values.map(x => x.tag_grading_type_value_id);
  skillManagerState.selectedSkillTag = skillManagerState.tagData.tagTypes.find(x => x.tag_type_id === skillManagerState.framework.tag_type_id);
  skillManagerState.allCategories = skillManagerState.tagData.tagClassifierTypes.flatMap(item => item.values || []);
  
  
  skillManagerState.selectedSkillCategories = skillManagerState.tagData.tagClassifierTypes.flatMap(item => item.values || []).map(x => x.value);
  let valid = resp.data.hierarchies.map(x => x.hr_id);
  skillManagerState.hierarchy = store.state.hierarchies.hierarchies.find(x => x.ht_id === skillManagerState.framework.ht_id);
  skillManagerState.filteredHierarchies = skillManagerState.hierarchy.values.filter(x => valid.includes(x.value)).map(h => {
    let group = skillManagerState.reviewGroups.find(x => x.framework_review_id === skillManagerState.activeReviewGroup)
    let level = `hierarchy_node_id_level${group?.level+1}`;
    let source = resp.data.hierarchies.find(x => x.hr_id === h.value)
    return {
        hr_id: h.value,
        label: h.textValue,
        skills_data: source.data,
        node_id: h[level],
        docs: source.docs
      }
  });

  skillManagerState.newHrGroup = {
    name: "",
    data: [],
    available: [],
    framework_review_link_id: -1,
    hrs: []
  };

  showHierarchyGroupData(resp.data.group_links.data, resp.data.reviewNode);

}

function showHierarchyGroupData(data, node){
  let hrList = data.reduce((acc, item) => {
    item.hrs.forEach(hr => {
      if (!acc.includes(hr.node_id)) {
        acc.push(hr.node_id);
      }
    });
    return acc;
  }, []);

  let availableNodes = findNodeWithChildren(skillManagerState.hierarchy.treePickerOptionsActive, node);
  availableNodes.children.forEach(x => {
    x.disabled = hrList.includes(x.node_id) ? 'disabled':'';
  })

  skillManagerState.nodeHrs = [JSON.parse(JSON.stringify(availableNodes))]
  skillManagerState.reviewGroupLinks = data;  
}

function updateReviewGroupStatus(status){
  let colour = '';
  switch(status){
    case "In Progress":
      colour = '#fff5cc';
      break;
    case "In Review":
      colour = '#eef7f9';
      break;
    case "Approved":
      colour = '#f5f8d4';
      break;
  }

  let data = {
    framework_review_id: skillManagerState.currentReviewGroup.framework_review_id,
    status: status
  }

  axios.post("skills/updateReviewGroupState/", data)
    .then((resp) => {
      skillManagerState.currentReviewGroup.status = status;
      skillManagerState.currentReviewGroup.status_color = colour;
      triggerNotification("Status Updated: " + status, "success");
    })
    .catch(err => {
        console.log(err);
        triggerNotification("Errord: " + err, "error");
    });
}

function loadReviewGroup(id){
  resetCreateDialog();
  skillManagerState.activeReviewGroup = id;
  skillManagerState.activeView = "skill_proficiency";
  loadReviewGroupData(id);
}

function loadReviewGroupData(id){
  skillManagerState.skillLoading = true;
    axios.get("skills/skillFrameworkReviewGroupData/" + id)
    .then((resp) => {
      setUpData(resp);
      skillManagerState.currentReviewGroup = skillManagerState.reviewGroups.find(x => x.framework_review_id === id);
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function findNodeWithChildren(array, targetNodeId) {
  function search(nodes) {
    for (let node of nodes) {
      if (node.node_id === targetNodeId) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const found = search(node.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  return search(array);
}

function getSkillFrameworkReviewGroups() {
    skillManagerState.skillLoading = true;
    axios.get("skills/skillFrameworkReviewGroups/")
    .then((resp) => {
      skillManagerState.framework = resp.data.framework;
      skillManagerState.reviewGroups = resp.data.review_groups.filter(x => x.status !== 'Holding');
      skillManagerState.userList = resp.data.users;
      initHierarchies();
      skillManagerState.skillLoading = false;
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function getInstance() {
  let isAdmin = loginState.user.permission_groups.includes("RoleMapper Admin");
  
  if(isAdmin){
    skillManagerState.user_role = "Admin";
    initWorkspace();
  } else {
    skillManagerState.user_role = "User";
    getSkillFrameworkReviewGroups();
  }
}

function setPriorities(e, item){
  let link = skillManagerState.reviewGroupLinks.find(x => x.framework_review_link_id === item.framework_review_link_id);
  let data = item.data.map((d,dIdx) => {
    return {
      ...d,
      priority: dIdx+1
    }
  });
  link.data = data;
  if(e.added){
    let removed = link.available.findIndex(x => x.tag_value_id === e.added.element.tag_value_id)
    if(removed > -1){
      link.available.splice(removed, 1);
    }
  }

  let hrs = item.hrs.map(x => x.hr_id);

  skillManagerState.adminCurrentHierarchy.forEach(x => {
    if(hrs.includes(x.hr_id)){
      let skills = x.skills_data.map(z => z.tag_value_id);
      let toadd = item.data.filter(x => !skills.includes(x.tag_value_id));
      toadd.forEach(a => {
        let skill = skillManagerState.data.find(d => d.tag_value_id === a.tag_value_id);
        assignSkill(skill, x);
      })
    }
  })

  axios.post("skills/savePrioritySkills/", data)
  .then((resp) => {
    link.data = resp.data;
    triggerNotification("Priority Skills Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });  
}

function docLoaded(){
    skillManagerState.loading = store.state.docs.loading;
    if(!skillManagerState.hierarchies){
    skillManagerState.hierarchies = store.state.hierarchies.hierarchies.map((h) => {
          switch (h.label.toLowerCase()) {
            case "geography":
            case "location":
              h.icon = "mdi-map-marker-outline";
              break;
            default:
              h.icon = "mdi-menu-down";
              break;
          }
          h.treePickerValue = null;
          return h;
      });
    }

}

function findNodeById(id, nodes) {
  for (const node of nodes) {
    if (node.node_id === id) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const result = findNodeById(id, node.children);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

function initHierarchies(){
  if(store.state.hierarchies.loading){
    setTimeout(() => {
      initHierarchies();
    }, 500);
    return;
  }
  
  rerenderTreeKey.value++;
  skillManagerState.activeHierarchy = [];
  skillManagerState.hierarchy = store.state.hierarchies.hierarchies.find(x => x.ht_id === skillManagerState.framework.ht_id);
  skillManagerState.reviewGroups.forEach(rg => {
    let node = findNodeById(rg.hr_node_id, skillManagerState.hierarchy.treePickerOptionsActive);
    if(node){
      rg.doc_count = node.children.length;
    }
  })

  let clone = Object.assign([], skillManagerState.hierarchy.treePickerOptionsActive);
  markAsDisabled2(clone, 2);
  let pickerValues = [];
  clone.forEach(item => {
    let activeItems = item.children.filter(x => x.framework_review_id);
    item.status = `${activeItems.length} / ${item.children.length}`;
    pickerValues.push({ header: item.name });
    item.children.forEach(c => {
      pickerValues.push({ name: c.name, group: item.name, node_id: c.node_id });
    })
  })
  skillManagerState.activeHierarchy = clone;
  skillManagerState.activeHierarchy = skillManagerState.activeHierarchy.map(object => object);
  skillManagerState.adminHierarchyView = pickerValues;
}

function getSkillColour(skill, hr){
  const record = hr.skills_data.find((sd) => sd.tag_value_id === skill.tag_value_id);
  return record ? record.color : null;
}

function markAsDisabled2(data, target, level = 1) {
  data.forEach(item => {
    item.active = level === target;
    let rg = skillManagerState.reviewGroups.find(x => x.hr_node_id === item.node_id);
    if(rg){
      item.framework_review_id = rg.framework_review_id;
    }
    if (item.children && Array.isArray(item.children)) {
      markAsDisabled2(item.children, target, level + 1);
    }
  });
}

function assignSkill(skill, hr){
  if(!hr.skills_data){
    hr.skills_data = [];
  }
  let index = hr.skills_data?.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  if(index === -1){
    hr.skills_data.push({...fallbackRecord(), tag_value_id: skill.tag_value_id, framework_item_id: skill.framework_item_id});
  }
}

function initWorkspace(){
  skillManagerState.skillLoading = true;
  axios.get("skills/initWorkspace")
    .then((resp) => {
      setUpAdmin(resp.data);
      skillManagerState.skillLoading = false;
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function setUpAdmin(resp){
  skillManagerState.framework = resp.framework.framework;
  skillManagerState.tagData = resp.framework.tag_data;
  skillManagerState.framework_items = resp.items;
  skillManagerState.frameworkGradingType = skillManagerState.tagData.tagGradingTypes.find(x => x.tag_grading_type_id === skillManagerState.framework.tag_grading_type_id);
  skillManagerState.selectedGradingTypes = skillManagerState.frameworkGradingType.values.map(x => x.tag_grading_type_value_id);
  skillManagerState.selectedSkillTag = skillManagerState.tagData.tagTypes.find(x => x.tag_type_id === skillManagerState.framework.tag_type_id);
  initHierarchies();
}

function toggleNodeView(node){
  skillManagerState.skillLoading = true;

  let data = { 
    node_id: node.node_id, 
    name: node.name
  }

  axios.get("skills/nodeView", { params: data })
    .then((resp) => {
      skillManagerState.adminCurrentHierarchy = resp.data.node_data;
      skillManagerState.reviewGroupLinks = resp.data.groups;
      skillManagerState.data = resp.data.activeItems;
      skillManagerState.skillLoading = false;
      skillManagerState.currentReviewGroup = resp.data.currentReviewGroup;
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function doSomething(rating_id, skill, hr){
  let index = hr.skills_data.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  let data = index !== -1 ? hr.skills_data[index] : {...fallbackRecord()};
  data = {
    ...data,
    tag_value_id: skill.tag_value_id,
    tag_rating_id: rating_id,
    hr_node_id: hr.node_id,
    framework_review_id: skillManagerState.activeReviewGroup
  }

  axios.post("skills/saveSkillFrameworkData", data)
  .then((resp) => {
    index !== -1 ? hr.skills_data.splice(index, 1, resp.data.Data) : hr.skills_data.push(resp.data.Data);
    triggerNotification("Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });
}

function triggerNotification(msg, type){
  skillManagerState.notification = {
    text: msg,
    type: type
  };
}

function extractIds(data) {
  return data.flatMap(item => {
    const ids = typeof item.id === "number" || !isNaN(item.id) ? [{ hr_id:item.id, node_id: item.node_id, label: item.name, skills_data: item.skills_data }] : []; // Add the current item's id
    if (item.children && Array.isArray(item.children)) {
      ids.push(...extractIds(item.children)); // Recursively extract ids from children
    }
    return ids;
  });
}

function onHrySelect(hry, nodes){
  skillManagerState.createNewReviewGroupNodeId = null;
  if(nodes.length === 1 && nodes[0].children.length > 0 && nodes[0].parents.length > 0 && !skillManagerState.reviewGroups.some(x => x.hr_node_id === nodes[0].node_id)){
    skillManagerState.createNewReviewGroupNodeId = nodes[0].node_id;
  }
}

function pickRelevantHrNodes(test, nodes){
  console.log(nodes.map(x => x.node_id));
}

function editReviewGroup(g, type){
  skillManagerState.createReviewGroupDialog.mode = type;
  skillManagerState.editReviewGroup = g;
  axios.get("skills/skillFrameworkReviewGroupDataGroups/" + g.framework_review_id)
  .then((resp) => {
    showHierarchyGroupData(resp.data.data, g.node_id || g.hr_node_id);
      
    if(skillManagerState.createReviewGroupDialog.mode === "edit"){
      skillManagerState.createReviewGroupDialog.show = true;
      skillManagerState.createReviewGroupDialog.data = {
        ...skillManagerState.createReviewGroupDialog.data,
        name: g.name,
        node_id: g.node_id || g.hr_node_id,
        framework_review_id: g.framework_review_id,
        users: resp.data.users
      }
    }
  })
  .catch(err => {
      console.log(err);
      skillManagerState.skillLoading = false;
  });
}

function createReviewGroup() {
  let data = {
    node_id: skillManagerState.createReviewGroupDialog.data.hr_id.node_id,
    name: skillManagerState.createReviewGroupDialog.data.name,
    users: skillManagerState.createReviewGroupDialog.data.users
  }
  //
  axios.post("skills/skillFrameworkReviewGroups", data)
    .then((resp) => {
      skillManagerState.createReviewGroupDialog.data.hr_id.framework_review_id = resp.data;
      //getSkillFrameworkReviewGroups();
      initHierarchies();
      skillManagerState.createNewReviewGroupNodeId = null;
    })
    .then(() => {
      editReviewGroup(skillManagerState.createReviewGroupDialog.data.hr_id, "create");
      skillManagerState.createReviewGroupDialog.step = 3;
    })
    .catch(err => {
      console.log(err);
    });
}

/*function createReviewGroup(item){
  let data = {
    node_id: item.node_id,
    name: item.name
  }
  //
  axios.post("skills/skillFrameworkReviewGroups", data)
    .then((resp) => {
      item.framework_review_id = resp.data;
      //getSkillFrameworkReviewGroups();
      initHierarchies();
      skillManagerState.createNewReviewGroupNodeId = null;
    })
    .then(() => {
      editReviewGroup(item);
    })
    .catch(err => {
      console.log(err);
    });
}*/

function getAllSkills(){
  let data = [];

  skillManagerState.filteredHierarchies.forEach(h => {
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(z => z.hr_id === h.hr_id) && x.tmpl_id === skillManagerState.framework.tmpl_id);
    data.push({
      name: h.label,
      docs: docs.map(x => { return {
        doc_id: x.doc_id,
        system_number: x.system_number,
        doc_status_text: x.doc_status_text,
        skills_extracted: h.docs.findIndex(h => h.doc_id === x.doc_id) > -1,
        doc_type: x.doc_type,
        doc_name: x.doc_name
      }})
    })    
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: "extract"
  };
}

function getSkills(hry){
  let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => h.hr_id === hry.hr_id) && x.tmpl_id === skillManagerState.framework.tmpl_id);
  let data = [];
  data.push({
    name: hry.label,
    docs: docs.map(x => { return {
      doc_id: x.doc_id,
      system_number: x.system_number,
      doc_status_text: x.doc_status_text,
      skills_extracted: hry.docs.findIndex(h => h.doc_id === x.doc_id) > -1,
      doc_type: x.doc_type,
      doc_name: x.doc_name
    }})
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: "extract"
  };
}

function backToGroups(){
  skillManagerState.activeReviewGroup = null;
  skillManagerState.data = [];
  skillManagerState.skillLoading = false;
  skillManagerState.tagData = [];
  skillManagerState.selectedSkillTag = [];
  skillManagerState.selectedSkillCategories = [];
  skillManagerState.filteredHierarchies = []
}

function getNewSkills(data){
    let existing = skillManagerState.data.map(x => x.name);
    let available = data.filter(x => !existing.includes(x.name));
    skillManagerState.addSkillsDialog.data = available;
}

function confirmAddSkill(){
  skillManagerState.data = [
    ...skillManagerState.addSkillsDialog.selected,
    ...skillManagerState.data
  ]

  skillManagerState.addSkillsDialog.show = false;
}

function addNewSkill(){
  skillManagerState.addSkillsDialog = {
      show: true,
      selected: [],
      data:[]
  };
  axios.get("skills/getAllFrameworkData/")
    .then((resp) => {
      getNewSkills(resp.data);
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });

}

function createGroup(){
  let newItem = Object.assign({}, skillManagerState.newHrGroup);

  let data = {
    ...newItem,
    framework_review_id: skillManagerState.editReviewGroup.framework_review_id
  }
  axios.post("skills/createReviewGroupLink", data)    
  .then((resp) => {
    newItem.framework_review_link_id = resp.data.framework_review_link_id
    skillManagerState.reviewGroupLinks.push(newItem);
    triggerNotification("Hierarchy Link Group Created Successfully","success");
    skillManagerState.newHrGroup = {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    };
    showHierarchyGroupData(skillManagerState.reviewGroupLinks.data, skillManagerState.editReviewGroup?.node_id);
  })
  .catch(err => {
    triggerNotification(err,"error");
    skillManagerState.extractSkillsDialog.loading = false;
    skillManagerState.newHrGroup = {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    };
  });


}

function getSkillsForDocs(){
    let data = {
      doc_ids: skillManagerState.extractSkillsDialog.selected,
      activeReviewGroupId: skillManagerState.activeReviewGroup
    }

    skillManagerState.extractSkillsDialog.loading = true;

    axios.post("skills/getSkillsForDocs", data)
    .then((resp) => {
      skillManagerState.extractSkillsDialog.loading = false;
      setUpData(resp);
      skillManagerState.extractSkillsDialog.show = false;
      triggerNotification("Skills Extracted Successfully","success");
    })
    .catch(err => {
      console.log(err);
      skillManagerState.extractSkillsDialog.loading = false;
    });
}

function extractAllSKills(type){
  let data = [];
  let docList = skillManagerState.filteredHierarchies.flatMap(item => item.docs.map(doc => doc.doc_id));
  skillManagerState.reviewGroupLinks.forEach(item => {
    let hrs = item.hrs.map(x => x.hr_id);
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => hrs.includes(h.hr_id)) && x.tmpl_id === skillManagerState.framework.tmpl_id);
    if((type === 'apply' && item.data.length > 0) || type === 'extract'){
        data.push({
        name: item.name,
        framework_review_link_id: item.framework_review_link_id,
        docs: docs.map(x => { return {
          doc_id: x.doc_id,
          system_number: x.system_number,
          doc_status_text: x.doc_status_text,
          skills_extracted: docList.includes(x.doc_id),
          doc_type: x.doc_type,
          doc_name: x.doc_name
        }})
      })
    }
  });

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: type
  };
}

function deleteItem(skill, item){
  let target = item.skills_data.find(x => x.tag_value_id === skill.tag_value_id);
  if(target){
    axios.delete("skills/skillFrameworkData/" + target.framework_data_id)
    .then((resp) => {
      let idx = item.skills_data.indexOf(target);
      if(idx > -1){
        item.skills_data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  }
}

function deleteSkill(skill){

  let data = {
    ...skill,
    active: false
  }

  axios.post("skills/frameworkSkill", data)
    .then((resp) => {
      let idx = skillManagerState.data.findIndex(x => x.framework_item_id === skill.framework_item_id);
      if(idx > -1){
        skillManagerState.data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  //
}

function syncSkillCategories(newValue, skillItem, item) {
  item.hrs.forEach(h => {
    let hr = skillManagerState.adminCurrentHierarchy.find(x => x.hr_id === h.hr_id);
    if(hr){
      let skill = hr.skills_data.find(s => s.tag_value_id === skillItem.tag_value_id)
      if(!skill) return;
      skill.category = newValue.name;
      skill.category_id = newValue.tag_grading_type_value_id;
      skill.color = newValue.colour;
    }
  })  
}

function confirmCoreCategoryUpdate(val, d, item){
  let newValue = skillManagerState.frameworkGradingType.values.find(x => x.tag_grading_type_value_id === val);
  d.tag_grading_type_value_id = val;
  d.colour = newValue.colour;

  syncSkillCategories(newValue, d, item);

  axios.post("skills/savePrioritySkills", [d])
  .then((resp) => {
    triggerNotification("Skill Core Category Update Successful","success");
    d.edit = false;
  })
  .catch(err => {
    triggerNotification(err,"error");
  });
}

function applySkillsToDocs() {
  skillManagerState.saving = true;
  let allData = skillManagerState.extractSkillsDialog.data.flatMap(parent => 
    parent.docs.map(item => ({
      ...item,
      framework_review_link_id: parent.framework_review_link_id
    }))
  );

  let docData = skillManagerState.extractSkillsDialog.selected.map(d => {
    let item = store.state.docs.docsList.find(x => x.doc_id === d);
    let source = allData.find(x => x.doc_id === d);
    let hrItem = item.hierarchies.find(h => h.ht_id === skillManagerState.framework.ht_id);
    return {
      ...source,
      hr_id: hrItem?.hr_id
    }
  })

  let data = {
    framework_review_id: skillManagerState.currentReviewGroup.framework_review_id,    
    data: docData
  }

  axios.post("skills/applySkillsToDocs", data)
  .then((resp) => {
    triggerNotification("Skills Updated","success");
    skillManagerState.saving = false;
  })
  .catch(err => {
    triggerNotification(err,"error");
    skillManagerState.saving = false;
  });    
}

function showCreateDialog(val){
  skillManagerState.createReviewGroupDialog.show = val;
}

function createDialogDefaultState() {
  return {
    show: false,
    data: {
      name: "",
      hr_id: null,
      search: "",
      users: []
    },
    step: 1,
    mode: "create"
  }
}

function resetCreateDialog(){
  skillManagerState.createReviewGroupDialog = createDialogDefaultState();
}

function setActiveItem(item){
  if(!item.active || item.framework_review_id) { return false; }
  skillManagerState.createReviewGroupDialog.data.hr_id = item;
}

function toggleUser(user){
  let idx = skillManagerState.createReviewGroupDialog.data.users.indexOf(user);
  idx > -1 ? skillManagerState.createReviewGroupDialog.data.users.splice(idx,1) : skillManagerState.createReviewGroupDialog.data.users.push(user);
}

function updateReviewGroup(item){
  let data = {
    framework_review_id: item.framework_review_id,
    node_id: item.node_id,
    name: item.name,
    users: item.users
  }


  axios.post("skills/skillFrameworkReviewGroups", data)
  .then((resp) => {
    triggerNotification("Updated","success");
    skillManagerState.createReviewGroupDialog.show = false;
  })
  .catch(err => {
    console.log(err);
  });
}


//#endregion

//#region Component
onUpdated(() => {

})

onBeforeMount(() => {
    getInstance();
    watch(() => store.state.docs.loading, () => docLoaded());
})
//#endregion
</script>
<template>
  <v-card class="skillsWorkspace" flat>
    <v-card-text v-if="!skillManagerState.framework?.framework_id">
      No Framework Config!
    </v-card-text>
    <div v-else-if="skillManagerState.skillLoading">
        <div style="width:100vw; height:100vh">
          <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          style="top: 40%; left: 48%;"
        ></v-progress-circular>
        </div>
    </div>
    <div v-else>
        <v-card-text class="pa-0" v-if="skillManagerState.adminCurrentHierarchy">
          <div class="stepper">
            <div class="step" @click="skillManagerState.activeView = 'skill_proficiency'">
              <div :class="[ 'content', skillManagerState.activeView === 'skill_proficiency' ? 'active stepblue' : '' ]">
                <v-icon size="50px" color="#50B3CB">mdi-format-list-bulleted</v-icon>
                <div class="steptitle">
                  <h2>Skill Proficiency</h2>
                </div>
              </div>
            </div>            
            <div class="step" @click="skillManagerState.activeView = 'skill_priority'">
              <div :class="[ 'content', skillManagerState.activeView === 'skill_priority' ? 'active stepyellow' : '' ]">
                <v-icon size="50px" color="#FFCB00">mdi-chart-pie</v-icon>
                <div class="steptitle">
                  <h2>Skill Prioritization</h2>
                </div>
              </div>
            </div>
            <div class="step" @click="skillManagerState.activeView = 'job_hierarchy'">
              <div :class="[ 'content', skillManagerState.activeView === 'job_hierarchy' ? 'active steppink' : '' ]">
                <v-icon size="50px" color="#EA3785">mdi-file-tree</v-icon>
                <div class="steptitle">
                  <h2>Job Hierarchy Distribution</h2>
                </div>
              </div>
            </div>
          </div>
        </v-card-text> 
        <v-card-text v-if="skillManagerState.user_role === 'User' && !skillManagerState.activeReviewGroup" class="pa-0">
          <v-card-text class="skillsWorkspaceHolder">
          <h1>Review Groups</h1>
          <v-row dense>
            <v-col cols="2">
              Users<br/>
              <v-select dense outlined :z-index="21"></v-select>
            </v-col>
            <v-col cols="2">
              Document Type<br/>
              <v-select dense outlined :z-index="21"></v-select>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="2">
              Search Groups & Docs<br/>
              <v-text-field dense outlined autocomplete="off" v-model="skillManagerState.reviewGroupSearchText"></v-text-field>
            </v-col>
          </v-row>
          <div style="overflow: hidden scroll; height: calc(100vh - 590px);">
            <v-data-table
              :headers="skillManagerState.reviewGroupHeaders"
              :items="skillManagerState.reviewGroups"
              class="elevation-1"
              hide-default-footer
              :page.sync="skillManagerState.page"
              :items-per-page="skillManagerState.itemsPerPage"
              @page-count="skillManagerState.pageCount = $event"
            >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-start">
                <v-btn icon @click="loadReviewGroup(item.framework_review_id)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click="editReviewGroup(item, 'edit')"><v-icon>mdi-cog</v-icon></v-btn>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                small
                class="mx-1 status-chip"
                :color="item.status_color"
                label
              >
                {{ item.status }}
              </v-chip>
            </template>
            </v-data-table>
          </div>
          <div style="display:inline-flex; width:100%; position: relative; padding-top:40px">
            <div style="position: absolute; left: 0;">
              <v-btn color="success" @click="showCreateDialog(true)">
              <v-icon class="mr-3">mdi-plus</v-icon>
              Create Review Group
            </v-btn>
            </div>
            <div style="position: absolute; left: 50%; transform: translateX(-50%);">
              <v-pagination
                v-model="skillManagerState.page"
                :length="skillManagerState.pageCount"
              ></v-pagination>
            </div>
          </div>
          </v-card-text>
        </v-card-text>          
        <v-card-text v-else class="pa-0">
          <div class="skillContentHolder">
            <div v-if="skillManagerState.activeView === 'skill_proficiency'">
              <v-row v-if="!skillManagerState.currentReviewGroup" dense>
                <v-col cols="12" style="display:inline-flex">
                  <h1>Skills Proficiency Distribution</h1>
                </v-col>
              </v-row>
              <v-row v-if="skillManagerState.currentReviewGroup" dense>
                <v-col cols="4" style="display:inline-flex">
                  <h1 style="margin-left: 10px;">Skills Proficiency Distribution</h1>
                  <v-chip
                    class="mx-1 status-chip"
                    :color="skillManagerState.currentReviewGroup.status_color"
                    label
                    style="top: 5px; left:25px;"
                  >
                    {{ skillManagerState.currentReviewGroup.status }}
                  </v-chip>
                </v-col>
                <v-col cols="3">
                </v-col>
                <v-col v-if="skillManagerState.currentReviewGroup.status === 'Holding'" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('In Progress')">Create Review Group</v-btn>
                </v-col>
                <v-col v-if="skillManagerState.currentReviewGroup.status !== 'Holding'" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('In Progress')">Save As Draft</v-btn>
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('In Review')">Share For Review</v-btn>
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('Approved')">Approve</v-btn>
                  <v-btn v-if="skillManagerState.currentReviewGroup.status === 'Approved'" small class="mx-2" @click="extractAllSKills('apply')">Publish</v-btn>
                </v-col>
              </v-row>              
              <v-row dense style="padding-bottom:5px">
                <v-col dense cols="2">
                  Job Family<br/>
                  <v-autocomplete @change="toggleNodeView($event)" v-model="skillManagerState.adminCurrentHierarchy_id" :items="skillManagerState.adminHierarchyView" dense outlined hide-details style="z-index: 10" item-text="name" item-value="node_id" return-object></v-autocomplete>
                </v-col>
                <v-col dense cols="2">
                  Job<br/>
                  <v-select :items="['All']" value="All" dense outlined hide-details style="z-index: 10"></v-select>
                </v-col>
                <v-col dense cols="2">
                  Filter By<br/>
                  <v-select :items="['All']" value="All" dense outlined hide-details style="z-index: 10"></v-select>
                </v-col>
                <v-col dense cols="5">
                  <div style="justify-content: flex-end; display: flex; align-content: center; align-items: center; height: 100%; padding-top: 20px;">
                    <v-btn dense small @click="addNewSkill">
                      <v-icon color="#AAAAAA" class="mr-2">mdi-plus-circle</v-icon> 
                      Add New Skill
                    </v-btn>
                    <v-btn small class="ml-2" @click="getAllSkills">Extract</v-btn>
                  </div>
                </v-col>
                <v-col dense cols="1">
                </v-col>
              </v-row>
              <v-row dense style="padding-top: 28px;" v-if="!skillManagerState.adminCurrentHierarchy">
                <v-col>
                  <h2>Select A Job Family To Begin</h2>
                </v-col>
              </v-row>  
              <v-row v-if="skillManagerState.adminCurrentHierarchy && skillManagerState.activeView === 'skill_proficiency'" dense>
                <v-col cols="11">
                  <div class="table-wrapper distribution-wrapper-admin">
                    <div class="table-scroll">
                      <table class="table table-bordered distributionTable" cellpadding="0" cellspacing="0">
                        <thead>
                          <tr>
                            <th class="locked-column intersect-column">Skill  ({{ skillManagerState.data.length }})</th>
                            <th v-for="item in skillManagerState.adminCurrentHierarchy" :key="`hdr_${item.node_id}_${rerender}`">
                              <div style="display:inline">
                                <div class="headerText" :title="item.name">
                                  {{item.name}}
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="skillManagerState.data.length > 0">
                          <tr v-for="(skill, idx) in skillManagerState.data" :key="`${idx}_${rerender}`">
                            <td class="locked-column">
                              <div class="locked-col-text" style="display: inline-flex; width: 85%;" :title="skill.name">
                                {{idx+1}} &nbsp; {{skill.name}}
                              </div>
                              
                              <v-menu
                                bottom
                                attach
                                right
                                min-width="220px"
                                :nudge-bottom="4"
                                :nudge-right="50"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <div style="position:relative; float:right; bottom: 5px; right: 5px;">
                                    <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="#48A401" style="float: right;  ">mdi-dots-horizontal</v-icon>
                                  </v-btn>
                                  </div>

                                </template>

                                <v-list dense>
                                  <v-list-item @click="deleteSkill(skill)">
                                    <v-list-item-icon class="mr-3">
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>Delete Skill</v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td> 
                            
                            <td v-for="item in skillManagerState.adminCurrentHierarchy" :style="{ 'background-color': getSkillColour(skill, item) }" :key="`${idx}_${item.hr_id}`">
                                  <div v-if="skillExists(skill, item)" style="display: inline-flex;width: 100%;align-items: center;justify-content: space-between;">
                                    <div style="width:130px;">
                                      <v-select dense :value="getModelValue(skill, item)" @change="doSomething($event, skill, item)" style="width:130px; top:7px" :items="skillManagerState.selectedSkillTag.ratings" item-value="tag_type_rating_id" item-text="name">
                                        <template v-slot:selection="{ item }">
                                          <span
                                            class="grey--text text-caption"
                                            style="width:100px; text-align: center;"
                                          >
                                          {{ item.name  }}
                                          </span>
                                        </template>
                                      </v-select>
                                    </div>
                                    <div style="float: right;right: 0px;position: relative;">
                                    
                                      <v-menu
                                        bottom
                                        attach
                                        right
                                        min-width="220px"
                                        :nudge-bottom="25"
                                        :nudge-right="25"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <v-icon color="#48A401">mdi-dots-horizontal</v-icon>
                                          </v-btn>
                                        </template>

                                        <v-list dense>
                                          <v-list-item @click="deleteItem(skill, item)">
                                            <v-list-item-icon class="mr-3">
                                              <v-icon>mdi-delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>Remove Mapping</v-list-item-content>
                                          </v-list-item>
                                          <v-list-item>
                                            <v-list-item-icon class="mr-3">
                                              <v-icon>mdi-comment-quote-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>Comment</v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div @click="assignSkill(skill, item)" class="addSkill" style="color:#AAAAAA">
                                      <v-icon color="#AAAAAA">mdi-plus-circle</v-icon> Add Skill
                                    </div>
                                  </div>
                                </td>         
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="locked-column">No Skills - Click To Extract</td>
                            <td :colspan="skillManagerState.filteredHierarchies.length"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="1">
                  <div>Core Category</div>
                    <div>
                      <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:3px">
                        <div :style="{ 'width':'15px', 'height':'15px', 'border-radius':'50%', 'border':'2px solid grey', 'background-color': item.colour}"></div>
                        <div style="padding-top:3px; padding-left:5px">{{ item.name }}</div>
                      </div>
                    </div>
                </v-col>
              </v-row>    
            </div>
            <div v-if="skillManagerState.activeView === 'skill_priority'">
              <v-row dense>
                <v-col cols="4" style="display:inline-flex">
                  <h1>Prioritization of Skills</h1>
                  <v-chip
                    class="mx-1 status-chip"
                    :color="skillManagerState.currentReviewGroup.status_color"
                    label
                    style="top: 5px; left:25px;"
                  >
                    {{ skillManagerState.currentReviewGroup.status }}
                  </v-chip>                
                </v-col>
                <v-col cols="3">
                </v-col>
                <v-col cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('In Progress')">Save As Draft</v-btn>
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('In Review')">Share For Review</v-btn>
                  <v-btn small class="mx-2" @click="updateReviewGroupStatus('Approved')">Approve</v-btn>
                  <v-btn v-if="skillManagerState.currentReviewGroup.status === 'Approved'" small class="mx-2" @click="extractAllSKills('apply')">Publish</v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2">
                  Job Family<br/>
                  <v-autocomplete @change="toggleNodeView($event)" v-model="skillManagerState.adminCurrentHierarchy_id" :items="skillManagerState.adminHierarchyView" dense outlined hide-details style="z-index: 10" item-text="name" item-value="node_id" return-object></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  Job Profile<br/>
                  <v-select dense outlined :items="['All']" value="All" :z-index="21"></v-select>
                </v-col>
                <v-col cols="2">
                  Business Area<br/>
                  <v-select dense outlined :items="['All']" value="All" :z-index="21"></v-select>
                </v-col>
                <v-col cols="5" style="padding-left:15px">
                  <div>Core Category</div>
                    <div>
                      <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:3px">
                        <div :style="{ 'width':'15px', 'height':'15px', 'border-radius':'50%', 'border':'2px solid grey', 'background-color': item.colour}"></div>
                        <div style="padding-top:3px; padding-left:5px">{{ item.name }}</div>
                      </div>
                    </div>
                </v-col>              
                <v-col cols="1" style="display: flex; justify-content: flex-end; align-items: center; align-content: center; flex-wrap: wrap;">
                  <v-btn small class="mx-2" @click="extractAllSKills('extract')">Extract</v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <div class="widthWrapper">
                    <div class="widthScroller">
                      <table class="table table-bordered prioritisationTable2" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th v-for="(item, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${item.framework_review_link_id}`">
                            <div class="hdrLabel" :title="item.name">
                              <span>{{ item.name }}</span>
                            </div>
                          </th>                      
                        </tr>
                        <tr>
                          <th class="hdrDrop" v-for="(item, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${item.framework_review_link_id}`">
                            <draggable
                              tag="div"
                              :list="item.data"
                              :group="`group_${gIdx}`"
                              handle=".handle"
                              class="dragZoneHdr"
                              @change="setPriorities($event, item)"
                              min-height="200px"
                              >
                                <div v-for="(d, dIdx) in item.data" :key="`${gIdx}_${dIdx}`" class="priorityItemHolder" >
                                  <div class="rank">
                                    <span v-if="d.priority">{{ d.priority }}</span>
                                  </div>
                                  <div class="dragSkill" :style="{ 'background-color':d.colour}">
                                    <div class="handle">
                                  <v-icon>mdi-dots-grid</v-icon>
                                </div>
                                <div class="value">{{ d.value }}</div>
                                <div class="actions">
                                  <v-menu
                                    bottom
                                    absolute
                                    ref="priorityContextMenu"
                                    right
                                    v-model="d.edit"
                                    :close-on-content-click="false"
                                    min-width="250px"
                                    height="300px"
                                    :value="d.edit"
                                    :nudge-bottom="10"
                                    :nudge-right="10"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" color="#AAAAAA" @click="d.edit = true;">mdi-dots-horizontal</v-icon>
                                    </template>

                                    <v-list>
                                      <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>View Skill Descriptors</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>Comment</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>Share</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item> 
                                        <v-menu :close-on-content-click="false" right offset-x
                                          :nudge-bottom="0"
                                          :nudge-right="0" 
                                          max-width="250px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on">
                                              <v-list-item-content>
                                                <v-list-item-title>Set Core Category</v-list-item-title>
                                              </v-list-item-content>
                                              <v-list-item-icon>
                                                <v-icon>mdi-chevron-right</v-icon>
                                              </v-list-item-icon>                                          
                                            </v-list-item>

                                          </template>
                                          <v-list>
                                            <v-list-item>
                                              <v-select v-model="d.tag_grading_type_value_id" 
                                              :items="skillManagerState.frameworkGradingType.values.slice().reverse()" 
                                              item-text="name" 
                                              item-value="tag_grading_type_value_id"
                                              @change="confirmCoreCategoryUpdate($event, d, item)"
                                            ></v-select>
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                    </v-list>
                                  </v-menu>
                                </div>
                                  </div>


                              </div>
                            </draggable>
                          </th>
                        </tr>
                      </thead>
                    </table> 
                    <h2 style="padding:16px 0px">Core Skills by Level</h2>
                
                  </div>
                  <div class="verticalWrapper">
                      <div class="verticalScroller">
                        <table class="table table-bordered prioritisationTable2" cellpadding="0" cellspacing="0">
                          <tbody>
                                <tr>
                                  <td v-for="(item, idx) in skillManagerState.reviewGroupLinks" :key="`${idx}_${item.framework_review_link_id}_${rerender}`">
                                      <draggable
                                      tag="div"
                                      :list="item.available"
                                      :group="`group_${idx}`"
                                      handle=".handle"
                                      >
                                        <div v-for="(d, dIdx) in item.available" :key="`${idx}_${dIdx}`" class="dragSkill" :style="{ 'background-color':d.color}"  >
                                        <div class="handle">
                                          <v-icon>mdi-dots-grid</v-icon>
                                        </div>
                                        <div class="value">{{ d.value }}</div>
                                        <div class="actions">
                                          <v-icon color="#AAAAAA">mdi-dots-horizontal</v-icon>
                                        </div>
                                        
                                      </div>
                                      </draggable>                            
                                  </td>  
                                </tr>
                              </tbody>
                        </table> 
                      </div>
                    </div>   
                  </div>
                </v-col>
              </v-row>              
            </div>
            <div v-if="skillManagerState.activeView === 'job_hierarchy'">
              <v-row dense>
                <v-col><h1>Job Hierarchy Distribution</h1></v-col>
              </v-row>   
            </div>
          </div>
        </v-card-text> 
    </div>
    <Notification :notification="skillManagerState.notification" />

    <v-dialog v-model="skillManagerState.extractSkillsDialog.show" max-width="1200px" scrollable>
      <v-card>
        <v-card-title v-if="skillManagerState.extractSkillsDialog.action === 'extract'" class="d-flex align-center">
          Extract Skills:
        </v-card-title>
        <v-card-title v-if="skillManagerState.extractSkillsDialog.action === 'apply'" class="d-flex align-center">
          Apply Skills:
        </v-card-title>
        <v-card-text>

          <v-expansion-panels accordion multiple>
            <v-expansion-panel
              v-for="(g,gIdx) in skillManagerState.extractSkillsDialog.data" :key="gIdx"
            >
              <v-expansion-panel-header><div style="padding-left:15px">{{ g.name }}</div></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="flow-font-small" v-for="d in g.docs" :key="d.doc_id">
                  <v-col cols="6">
                    <div style="display:flex; align-items: center;">
                      <v-checkbox v-model="skillManagerState.extractSkillsDialog.selected" 
                      :value="d.doc_id" 
                      :disabled="d.skills_extracted && skillManagerState.extractSkillsDialog.action === 'extract'"
                    ></v-checkbox>{{ d.system_number }} - {{ d.doc_name }}</div>
                  </v-col>
                  <v-col cols="2">
                    <v-chip
                      small
                      class="mx-1 status-chip"
                      label
                    >
                      {{ d.doc_type }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip
                      small
                      class="mx-1 status-chip"
                      label
                    >
                      {{ d.doc_status_text }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <div style="font-style:italic; color:green; padding-left:10px" v-if="d.skills_extracted && skillManagerState.extractSkillsDialog.action === 'extract'">Skills extracted</div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-progress-circular
            :size="30"
            color="primary"
            indeterminate
            class="mr-2" v-if="skillManagerState.extractSkillsDialog.loading || skillManagerState.saving"
          ></v-progress-circular>
          <v-btn @click="skillManagerState.extractSkillsDialog.show = false">Cancel</v-btn>
          <v-btn v-if="skillManagerState.extractSkillsDialog.action === 'extract'" :disabled="skillManagerState.extractSkillsDialog.selected.length === 0 || skillManagerState.extractSkillsDialog.loading" color="primary" @click="getSkillsForDocs">Extract Skills</v-btn>
          <v-btn v-if="skillManagerState.extractSkillsDialog.action === 'apply'" 
          :disabled="skillManagerState.extractSkillsDialog.selected.length === 0 || skillManagerState.extractSkillsDialog.loading || skillManagerState.saving" 
          color="primary" @click="applySkillsToDocs">Apply Skills</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog v-model="skillManagerState.createReviewGroupDialog.show" max-width="1500px" persistent scrollable>
      <v-card>
        <v-card-title class="d-flex align-center" >
          <div style="text-align: center; width:100%">{{ skillManagerState.createReviewGroupDialog.mode === 'create' ? 'Create' : 'Edit'}} Review Group</div>
        </v-card-title>
        <v-card-text>
          <v-stepper color="success" v-model="skillManagerState.createReviewGroupDialog.step" flat class="elevation-0">
            <v-stepper-header style="box-shadow: none !important">
              <v-stepper-step
                :complete="skillManagerState.createReviewGroupDialog.step > 1"
                step="1"
              >
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="skillManagerState.createReviewGroupDialog.step > 2"
                step="2"
              >
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="mb-3"><h2>Name your Group</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Give the group a name</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense  v-model="skillManagerState.createReviewGroupDialog.data.name" outlined hide-details label="Group name"></v-text-field>
                  </div>
                </div>
                <div class="mb-4"><h2>Choose Your Members</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Choose the members of this new group.</div>
                </div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Search Users</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense :value="skillManagerState.createReviewGroupDialog.data.search" v-model="skillManagerState.createReviewGroupDialog.data.search" outlined hide-details label="Search..." @click:clear="skillManagerState.createReviewGroupDialog.data.search = ''" clearable></v-text-field>
                  </div>
                </div>
                <div class="d-flex align-center mb-5">
                  <v-row dense>
                    <v-col cols="6">
                      <div style="max-height:300px; overflow: hidden scroll">

                        <v-list subheader>
                        <v-list-item
                          v-for="user in filteredUserList"
                          :key="user.user_id"
                        >
                        <v-avatar class="secondary-background" :color="$loginState.impersonating ? 'success darken-2' : ''" size="32">
                          <span class="body-2">{{ user.userInitials }}</span>
                        </v-avatar>

                          <v-list-item-content class="ml-3">
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-checkbox :value="skillManagerState.createReviewGroupDialog.data.users.indexOf(user) > -1" @change="toggleUser(user)"></v-checkbox>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-chip v-for="user in skillManagerState.createReviewGroupDialog.data.users"
                        class="ma-2"
                        close
                        color="grey"
                        label
                        text-color="white"
                        @click:close="toggleUser(user)"
                      >
                        {{user.name}}
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="mb-2 mt-2"><h1>Choose Job Families</h1></div>
                <div class="mb-2 mt-2">Choose which Job Families this group can review. </div>
                <v-treeview :items="skillManagerState.activeHierarchy" item-key="node_id" :key="rerenderTreeKey" return-object>
                  <template v-slot:label="{ item }">
                    <div @click="setActiveItem(item)" :style="{'color': item.active && !item.framework_review_id ? '#000000' : '#9f9b9b', 'background-color': skillManagerState.createReviewGroupDialog.data.hr_id?.node_id === item.node_id ? '#00cf3354' : ''}">
                      {{ item.name }} <span v-if="item.status" style="font-style: italic; font-size: 13px;" class="ml-3">{{ item.status }}</span>
                    </div>

                  </template>
                </v-treeview>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row dense>
                  <v-col cols="5">
                    <div class="mb-2 mt-2"><h2>Select Job Titles</h2></div>
                  </v-col>
                  <v-col cols="7">
                    <div class="mb-2 mt-2"><h2>Enter Grouping Name</h2></div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5">
                    <div style="padding-top:15px">
                      <v-treeview item-disabled="disabled" selectable :items="skillManagerState.nodeHrs" v-model="skillManagerState.newHrGroup.hrs" item-key="node_id" return-object></v-treeview>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <div class="ml-3" style="width: 500px; display: inline-flex; justify-content: center; flex-wrap: nowrap; align-items: baseline;">
                      <v-text-field v-model="skillManagerState.newHrGroup.name" style="width:300px" class="mr-4" dense></v-text-field>
                      <v-btn :disabled="skillManagerState.newHrGroup.hrs.length === 0" small @click="createGroup">Create Hierarchy Group</v-btn><br/>
                    </div>
                    <table class="table table-bordered" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr v-for="(g, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${g.framework_review_link_id}`" style="border:1px solid black">
                          <td style="position: relative; vertical-align: top; padding-bottom:15px">
                            <div class="headerDisplay">
                              {{ g.name }}
                            </div>
                          </td>
                          <td style="position: relative; vertical-align: top; padding-bottom:15px; padding-top: 3px;">
                            <div v-for="(h, hIdx) in g.hrs" :key="`${gIdx}_${g.framework_review_link_id}_${hIdx}`">{{h.name}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn text color="success" class="mr-3" @click="resetCreateDialog()">Cancel</v-btn>
          <div v-if="skillManagerState.createReviewGroupDialog.step === 1">
            <v-btn class="ml-3" color="success" @click="skillManagerState.createReviewGroupDialog.step = skillManagerState.createReviewGroupDialog.mode === 'create' ? 2 : 3">Next</v-btn>
          </div>
          <div v-if="skillManagerState.createReviewGroupDialog.step === 2 && skillManagerState.createReviewGroupDialog.mode === 'create'">
            <v-btn class="ml-3" text color="success" @click="skillManagerState.createReviewGroupDialog.step = 1">Back</v-btn>
            <v-btn class="ml-3" color="success" :disabled="skillManagerState.createReviewGroupDialog.data.hr_id === null" @click="createReviewGroup()">Next</v-btn>
          </div>
          <div v-if="skillManagerState.createReviewGroupDialog.step === 3">
            <v-btn class="ml-3" text color="success" @click="skillManagerState.createReviewGroupDialog.step = skillManagerState.createReviewGroupDialog.mode === 'create' ? 2 : 1">Back</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'create'" @click="resetCreateDialog()">Finish & Close</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'edit'" @click="updateReviewGroup(skillManagerState.createReviewGroupDialog.data)">Update & Close</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'create'" @click="loadReviewGroup(skillManagerState.createReviewGroupDialog.data.hr_id.framework_review_id)">Continue & Edit</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="skillManagerState.addSkillsDialog.show" max-width="1200px" scrollable>
      <v-card>
        <v-card-title class="d-flex align-center">
          Add Skills:
        </v-card-title>
        <v-card-text>
          <div v-if="skillManagerState.addSkillsDialog.data.length ===0">
            Loading
          </div>
          <div v-else>
            <v-autocomplete multiple :items="skillManagerState.addSkillsDialog.data" v-model="skillManagerState.addSkillsDialog.selected" return-object item-text="name" item-value="id"></v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn @click="skillManagerState.addSkillsDialog.show = false">Cancel</v-btn>
          <v-btn :disabled="skillManagerState.addSkillsDialog.selected.length === 0" color="primary" @click="confirmAddSkill">Add Skills</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
  </v-card>  
</template>
<style lang="scss" scoped>

$skillColumnWidth: 400px;


.skillsWorkspace { 
  width: 100%;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px); 
  background: #fefefe;  
  margin: 40px 0px;
}

.skillsWorkspaceHolder{ 
  border-radius:24px;
  padding: 32px 40px 32px 40px;
  gap: 32px;

  .noBorder {
    box-shadow:none;
  }
}

.headerIcon{
  position:relative;
  z-index:200
}

.addSkill {
  color:#AAAAAA;
  font-size:12px;
  cursor:pointer;
  text-align:center;
}

.addRating{
  color:green;
  font-size:12px;
}

.headerWrapper{
  width: 98%;
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
  overflow: scroll;
}

.subWrapper {
  background-color: green;
  width: 100%;
  overflow: hidden scroll;
  min-height: calc(100vh - 275px);
}

.prioritisation-wrapper {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.distribution-wrapper {
  height: calc(100vh - 460px);
  max-height: calc(100vh - 460px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.table-scroll {
    overflow: auto;
    max-height: 100%;
    min-height: 100%;
    position: relative;
}

.distributionTable {
  width: 100%;
  border-collapse: collapse;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;   

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border: 1px solid #ccc;
    padding: 8px;
    min-width: 200px;
    max-width: 200px;
    max-height: 20px;
    height: 46px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 10px;
    background-color: #ccc;
    color: black;
    z-index: 9;

    .headerText {
      max-width: 95%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 3px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;    
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 46px;
    td:not(.locked-column) {

      height: 46px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 200px;
      max-width: 200px;
      word-wrap: break-word;
      border: 1px solid #ccc;
      padding: 0px 8px 0px 25px;
    }
    
  }

  .menuClass {
    z-index:25;
  }

  .locked-column {
  position: sticky;
  left: -1px;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 46px;
  min-width: 400px;
  max-width: 400px;
  padding-left: 25px;
  z-index:9;    
  padding: 10px 8px 0px 25px;
}

.locked-col-text {
  display: inline-flex;
  width: 85%;
  text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    overflow-x: clip;  
}

.intersect-column{
  position: sticky;
  top: -1px;
  left: -1px;
  z-index: 3;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  color: #000;
  padding-left: 25px;  
  text-align: left;
  z-index:10;
}
}

.prioritisationTable {

  max-width:100%;
  border-spacing:10px;
  border-collapse:collapse;

  thead tr th {
    position: sticky;
    top: 0px;
    z-index: 1;
    min-width: 260px;
    max-width: 260px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    color: black;
    z-index: 9;
    padding-bottom: 5px;
    padding-right: 10px;

    &.hdrDrop {
      top: 35px !important;
      max-height: 200px;
    }

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 30px;
    td:not(.locked-column) {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 300px;
      max-width: 300px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;    
      padding: 0px 10px;
    }
    
  }
}

.groups{
  width: 100%;
  border-collapse: collapse;
}

.groups td, .groups th {
  border: 1px solid #ddd;
  padding: 8px;
}

.groups tr:nth-child(even){background-color: #f2f2f2;}

//.groups tr:hover {background-color: #ddd;}

.groups th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #949494;
  color: white;
}

.dragSkill{
  min-height: 40px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    left: -1px;
    background-color: #fff;
    z-index: 2;
    border: 1px solid #ccc;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;    
    margin-top: 3px;

    .handle {
      width: 24px;
      cursor: move;
      display: inline-flex;
      border-radius: 5px;

      span {
        padding-left: 12px;
        padding-top: 12px;
        font-family: DM Sans;
        font-size: 12px;
      }
    }

    .value {
      width: 75%;
      text-align: left;
      padding-left: 8px;
      font-family: DM Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 20.83px;
      letter-spacing: 0.5px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .tag {
      width: 25%;
      font-family: DM Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 20.83px;
      letter-spacing: 0.5px;
      text-align: right;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .actions {
      width: 10%;
    }
}

.headerDisplay {
  top: -1px; 
  z-index: 1; 
  border: 1px solid #ccc; 
  padding: 8px;
  min-width: 260px;
  max-width: 260px;
  max-height: 30px;
  height: 30px;
  line-height: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  background-color: #ccc;
  color: black;
  border-radius: 5px;
  align-content: center;
  vertical-align: top;
  position: relative;
  top: 0px;  
}


.dragZoneHdr{
  min-height: 100%;
  max-height: 175px;
  overflow: hidden scroll;
}

.dragZoneHdr:empty {
  color: #888;
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  align-content: center;
  height: 100%;
  overflow:hidden;
}

.dragZoneHdr:empty::before {
  content: "Add Items";
  color: #888;
}

.hdrLabel {
  padding: 17px 0px 0px 0px;
  border-radius: 8px;
  background-color: #cec9c9;
  height: 30px;
  span {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    overflow-x: clip;
    display: inline-block;
    align-content: center;
  }
}
.v-menu__content {
  z-index: 1000 !important;
}

.navPill{
  width: 300px;
  height: 40px;
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: DM Serif Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.42px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

}

.skillContentHolder{
  border-radius:10px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 0px;
  padding: 32px 40px 0px 40px;
  margin-right: 5px;
  height: calc(100vh - 160px);
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width:100%;
  padding-bottom:40px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-right: 20px;
}

.content {
  width: 421px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #50B3CB;
  color: #fff;
  border-color: #50B3CB;
  box-shadow: 0 2px 5px rgba(0, 98, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.steptitle {
  color: #000000;
  padding-top: 3px;
  padding-left: 12px;
}

.steptitleLink{
  color: #48A401;
  text-decoration:underline;
}

.line {
  height: 2px;
  background-color: #ccc;
  flex-grow: 1;
  z-index: 0;
}

.stepblue {
  border: 4px solid #50B3CB;
}

.stepyellow {
  border: 4px solid #FFCB00;
}

.steppink{
  border: 4px solid #EA3785;
}

.active .content {



  box-shadow: 0 4px 8px rgba(0, 98, 128, 0.2);
}


.line.active {
  background-color: #50B3CB;
}

.rank {
  color: #AAAAAA;
  font-size:12px;
  display:flex; 
  align-items: center;
  justify-content: center;
  width: 25px;
  padding-top: 5px;  
}

.priorityItemHolder {
  display:inline-flex;
  width:100%;
}








@import "@/assets/styles/vars";


.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  .titlerow {
    min-height: 100px !important;
    .col {
      display: flex;
      align-items: center;
      padding: 29px;
    }
    h2 {
      font-size: 26px;
      line-height: normal;
      white-space: pre-wrap;
      word-break: normal;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0;
      color: green;
      text-decoration: underline;
      font-size: 16px;
    }
  }
  .v-icon {
    opacity: 0.8;
  }
  &.theme--dark.hover {
    background-color: $secondary-background-dark;
  }
}

.v-card {
  border-radius: 20px !important;
}

.tile-color-orange {
  background-color: #fff5cc;
}

.tile-color-green {
  background-color: #F5F8D4;
}

.tile-color-blue {
  background-color: #EEF7F9;
}

.widthWrapper{
  max-width:100%;
  overflow: scroll hidden;
  max-height: calc(100vh - 480px);
}

.widthScroller {
  width: max-content;
  overflow: hidden;
}

.verticalWrapper {
  max-width:100%;
  width:100%;
  overflow: scroll scroll;
  max-height: calc(100vh - 580px);
  position:relative;
  display: inline;
}

.verticalScroller {
  width: max-content;
  overflow: scroll scroll;
  max-height: calc(100vh - 740px);
}

.verticalScroller::-webkit-scrollbar {
  position: absolute;
  width: 10px;
  right: -10px; /* Move scrollbar outside */
}

.prioritisationTable2 {
  max-width:100%;
  border-spacing:10px;
  border-collapse:collapse;

  thead tr th {
    top: 0px;
    z-index: 1;
    min-width: 260px;
    max-width: 260px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    color: black;
    z-index: 9;
    padding-bottom: 5px;
    padding-right: 10px;

    &.hdrDrop {
      top: 35px !important;
      max-height: 200px;
    }

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 30px;
    td {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 260px;
      max-width: 260px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;    
      padding: 0px 10px 0px 0px;
    }
    
  }
}

.distribution-wrapper-admin{
  height: calc(100vh - 455px);
  max-height: calc(100vh - 455px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

</style>