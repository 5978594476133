<template>
  <v-container fluid class="px-0">
    <v-row class="mt-3 px-6" v-if="!showEditDefinition">
      <v-col class="d-flex align-center">
        <h1 class="title">Landing Page Configuration</h1>
      </v-col>
    </v-row>

    <v-row class="px-6" v-if="!showEditDefinition">
      <v-col>
        <v-card width="100%" class="py-4">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="py-2"><h2>Page</h2></th>
                  <th class="py-2">
                    <h2>Actions</h2>
                  </th>
                  <th class="py-2" colspan="20">
                    <h2>Permission Groups</h2>
                  </th>
                </tr>
                <tr>
                  <th colspan="2"></th>
                  <th v-for="pg in permissionGroups" :key="pg.pg_id">
                    <v-chip small class="mb-2">{{
                      pg.permission_group_name
                    }}</v-chip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="p in landingPages">
                  <tr
                    :key="'page' + p.lp_id"
                    :class="!p.active ? 'inactive' : ''"
                  >
                    <td>
                      <v-icon v-if="p.icon" :class="p.icon">{{
                        p.icon ? p.icon.split(" ")[0] : ""
                      }}</v-icon>
                      {{ p.menu_section ? `${p.menu_section} -` : "" }}
                      {{ p.title }}
                    </td>
                    <td>
                      <v-btn icon title="Configure Page" @click="editPage(p)">
                        <v-icon>settings</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        title="Add New Blank Definition"
                        @click="addDefinition(p)"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </td>
                    <td colspan="20"></td>
                  </tr>
                  <template v-for="pd in p.definitions">
                    <tr
                      :key="'pageDef_' + pd.lpd_id"
                      :class="!p.active || !pd.active ? 'inactive' : ''"
                    >
                      <td>
                        <v-icon class="ml-6">subdirectory_arrow_right</v-icon
                        >{{ pd.description }}
                      </td>
                      <td>
                        <v-btn
                          icon
                          title="Delete Definition"
                          @click="deleteDefinition(p, pd)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          title="Copy Definition"
                          @click="addDefinition(p, pd)"
                        >
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          title="Upload Definition"
                          @click="uploadDefinition(p, pd)"
                        >
                          <v-icon>file_upload</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          title="Download Definition"
                          @click="downloadDefinition(p, pd)"
                        >
                          <v-icon>file_download</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          title="Edit Definition"
                          @click="openDefinitionForEdit(p, pd)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td v-for="pg in permissionGroups" :key="pg.pg_id">
                        <v-btn
                          icon
                          title="Disable"
                          v-if="pd[`pg${pg.permission_group_id}_enabled`]"
                          @click="toggleRoute(pd, pg.permission_group_id)"
                        >
                          <v-icon>mdi-check-circle-outline</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          title="Enable"
                          v-else
                          style="opacity: 0.2"
                          @click="toggleRoute(pd, pg.permission_group_id)"
                        >
                          <v-icon>mdi-plus-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="20" class="pt-4">
                    <v-btn color="primary" outlined @click="addPage"
                      >Add New Page</v-btn
                    >
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="showEditDefinition && pageDefinition">
      <v-col>
        <v-row class="px-6">
          <v-col cols="4">
            <v-btn @click="closeDefinition" color="primary" outlined>
              <v-icon left>mdi-arrow-left</v-icon>Back</v-btn
            >
          </v-col>
          <v-col cols="4" class="d-flex justify-center">
            <h2>
              {{ pageDefinition.pageTitle }} / {{ pageDefinition.description }}
              {{ !pageDefinition.active ? "(disabled)" : "" }}
              <v-btn icon @click="configureDefinition">
                <v-icon>settings</v-icon>
              </v-btn>
            </h2>
          </v-col>
          <v-col cols="4" class="d-flex justify-end">
            <v-btn class="ml-3" color="primary" outlined @click="addTab"
              >Add Tab</v-btn
            >
            <v-btn class="ml-3" color="primary" outlined @click="addColumn"
              >Add Column</v-btn
            >
            <v-btn class="ml-3" color="primary" outlined @click="addRow"
              >Add Control</v-btn
            >
            <v-btn class="ml-3" color="primary" @click="saveDefinition"
              >Save</v-btn
            >
          </v-col>
        </v-row>
        <div class="ma-3">
          <Landing
            v-model="pageDefinition.definition"
            :editMode="true"
            @tabEdit="tabEdit"
            @tabMoveLeft="tabMoveLeft"
            @tabMoveRight="tabMoveRight"
            @deleteTab="deleteTab"
            @changeActiveTab="changeActiveTab"
            @colEdit="colEdit"
            @deleteCol="deleteCol"
            @rowEdit="rowEdit"
            @deleteRow="deleteRow"
            @editTile="editTile"
            @addTile="addTile"
            @copyTile="copyTile"
            @deleteTile="deleteTile"
          >
          </Landing>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showPageEdit" max-width="500px">
      <v-card v-if="page">
        <v-card-title>
          <div>Page Settings</div>
          <v-spacer />
          <v-btn icon @click="showPageEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Position"
                type="number"
                v-model="page.order"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                label="Route"
                v-model="page.page_route"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="d-flex justify-end">
              <v-switch
                label="Active"
                v-model="page.active"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-select
                label="Menu Section"
                v-model="page.menu_section"
                :items="['', 'Reporting', 'Admin']"
              >
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Title" v-model="page.title"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="d-flex justify-space-between">
                <v-icon :class="page.icon" style="width: 25px">{{
                  page.icon ? page.icon.split(" ")[0] : ""
                }}</v-icon>
                <v-text-field
                  class="ml-3"
                  label="Icon"
                  v-model="page.icon"
                ></v-text-field>
              </div>
            </v-col>
            <v-col class="d-flex align-center">
              <v-switch
                label="Hide Header Create"
                v-model="page.hide_header_create"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4 px-6">
          <v-btn color="primary" @click="savePage"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDefinitionEdit" max-width="500px">
      <v-card v-if="pageDefinition">
        <v-card-title>
          Definition Settings
          <v-spacer />
          <v-btn icon @click="showDefinitionEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Description"
            v-model="pageDefinition.description"
          ></v-text-field>

          <v-select
            v-if="tabSelect"
            label="Default Tab"
            v-model="pageDefinition.definition.defaultTabIndex"
            :items="tabSelect"
            item-text="title"
            item-value="index"
          >
          </v-select>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTabEdit" max-width="500px">
      <v-card v-if="tabModel">
        <v-card-title>
          Tab Settings
          <v-spacer />
          <v-btn icon @click="showTabEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Title"
                v-model="tabModel.title"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showColEdit" max-width="500px">
      <v-card v-if="colModel">
        <v-card-title>
          Column Settings
          <v-spacer />
          <v-btn icon @click="showColEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Columns"
                type="number"
                v-model="colModel.cols"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRowEdit"
      :max-width="
        rowModel && rowModel.controlType === 'DocumentList' ? '1200px' : '800px'
      "
    >
      <v-card v-if="rowModel">
        <v-card-title>
          Control Settings
          <v-spacer />
          <v-btn icon @click="showRowEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="9">
                  <v-select
                    label="Control Type"
                    v-model="rowModel.controlType"
                    :items="controlTypes"
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Columns"
                    v-model="rowModel.cols"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-select
                    label="Style"
                    :items="controlStyle"
                    v-model="rowModel.style"
                    item-text="text"
                    item-value="value">
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    label="Contained"
                    v-model="rowModel.contained"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              

              <v-text-field
                label="Title"
                v-model="rowModel.title"
              ></v-text-field>

              <v-select
                v-if="rowModel.controlType === 'CreateDocLinks'"
                label="Document Type"
                v-model="rowModel.documentType"
                :items="docTypes"
                item-text="tmpl_name"
                item-value="tmpl_name"
                clearable>
              </v-select>

              <v-select
                v-if="['DocumentList', 'TaskList', 'Chart'].includes(rowModel.controlType)"
                label="Document Type"
                v-model="rowModel.docTypes"
                clearable
                multiple
                :items="docTypes"
                item-text="tmpl_name"
                item-value="tmpl_name"
              >
              </v-select>

              <v-select
                v-if="rowModel.controlType === 'DocumentList'"
                label="Lifecycles"
                v-model="rowModel.lifecycles"
                :items="rowLifecycles"
                item-text="lifecycle_name"
                item-value="lifecycle_name"
                multiple
              >
              </v-select>

              <v-select
                v-if="rowModel.controlType === 'DocumentList'"
                label="Included Statuses"
                v-model="rowModel.statuses"
                :items="rowStatuses"
                item-text="lifecycle_status_name"
                item-value="lifecycle_status_name"
                multiple
              >
              </v-select>

              <template v-if="rowModel.controlType === 'DocumentList'">
                <v-switch
                  label="Show Counts"
                  v-model="rowModel.showCounts"
                ></v-switch>
                <v-switch
                  label="Show Pie Chart"
                  v-model="rowModel.showPieChart"
                ></v-switch>
                <v-switch
                  label="Show DocType Filter"
                  v-model="rowModel.showDocTypeFilter"
                ></v-switch>
                <v-switch
                  label="Show Hierarchy Simple Filters"
                  v-model="rowModel.showHierarchyFilters"
                ></v-switch>
                <v-switch
                  label="Show Hierarchy Filter Panel"
                  v-model="rowModel.showHierarchyFilterPanel"
                ></v-switch>
                <v-switch
                  label="Show Review Groups Filter"
                  v-model="rowModel.showReviewGroupsFilter"
                ></v-switch>
                <v-switch
                  label="Show People Filter"
                  v-model="rowModel.showManagerFilter"
                ></v-switch>
                <v-switch
                  label="Show Date Filter"
                  v-model="rowModel.showDateFilter"
                ></v-switch>
                <v-switch
                  label="Show Evaluation Status"
                  v-model="rowModel.showEvaluationStatus"
                ></v-switch>
                <v-switch
                  label="Show Evaluation Counts"
                  v-model="rowModel.showEvaluationCounts"
                ></v-switch>
                <v-switch
                  label="Show Evaluation Pie Chart"
                  v-model="rowModel.showEvaluationPieChart"
                ></v-switch>
                <v-switch
                  label="Show Evaluation Score"
                  v-model="rowModel.showEvaluationScore"
                ></v-switch>
              </template>

              <v-select
                v-if="rowModel.controlType === 'TaskList'"
                label="Default Visible Columns"
                v-model="rowModel.taskListColumns"
                multiple
                :items="utils.taskListFields"
                item-text="text"
                item-value="value"
                small-chips>
              </v-select>
            </v-col>
            <v-col cols="8" v-if="rowModel.controlType === 'DocumentList'">
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th></th>
                    <th>Label</th>
                    <th>Field</th>
                    <th>Visible</th>
                    <th>
                      <v-btn
                        icon
                        @click="
                          rowModel.docListColumns.push({
                            text: '',
                            value: '',
                            visible: false,
                          })
                        "
                        ><v-icon>add_circle_outline</v-icon></v-btn
                      >
                    </th>
                  </tr>
                </thead>
                <draggable
                  v-model="rowModel.docListColumns"
                  handle=".draghandle"
                  tag="tbody"
                >
                  <tr
                    v-for="(c, ci) in rowModel.docListColumns"
                    :key="'dlCol' + ci"
                  >
                    <td>
                      <v-icon class="draghandle">drag_indicator</v-icon>
                    </td>
                    <td>
                      <v-text-field
                        v-model="c.text"
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        :items="documentListFields"
                        item-text="text"
                        item-value="value"
                        v-model="c.value"
                        @change="onDocListFieldSelect(c)"
                        hide-details
                      ></v-select>
                    </td>
                    <td>
                      <v-switch v-model="c.visible" hide-details></v-switch>
                    </td>
                    <td>
                      <v-btn
                        icon
                        @click="
                          rowModel.docListColumns =
                            rowModel.docListColumns.filter((o) => o !== c)
                        "
                        ><v-icon>delete</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </draggable>
              </v-simple-table>
            </v-col>
            <v-col cols="8" v-if="rowModel.controlType === 'Chart'">
              <v-row v-if="rowModel.controlType === 'Chart'">
                <v-col>
                  <v-select
                    :items="chartTypes"
                    v-model="rowModel.chartType"
                    label="Chart Type"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                  <v-select
                    :items="tagTypes"
                    v-model="rowModel.tagTypeName"
                    label="Tag Type"
                    item-text="tag_type_name"
                    item-value="tag_type_name"
                    clearable
                  ></v-select>
                  <v-select
                    :items="chartDisplayTypes"
                    v-model="rowModel.chartDisplayType"
                    label="Display Type"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="6" v-if="rowModel.chartType === 'tagDistribution'">
                  <v-select
                    label="Classifer Type Filters"
                    placeholder="< All >"
                    v-model="rowModel.chartClassifierFilters"
                    :items="tagClassifierTypes"
                    multiple
                    item-text="name"
                    item-value="tag_classifier_type_id"
                  ></v-select>
                  <v-autocomplete
                    :items="tagValues"
                    v-model="rowModel.initialTagValueId"
                    label="Initial Tag Value"
                    item-text="value"
                    item-value="tag_value_id"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" v-if="rowModel.chartType === 'jobTagClassifiers'">
                  <v-select
                    label="Classifer Type"
                    v-model="rowModel.chartClassifierTypeId"
                    :items="tagClassifierTypes"
                    item-text="name"
                    item-value="tag_classifier_type_id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTileEdit" max-width="1000px">
      <v-card v-if="tileModel">
        <v-card-title>
          Tile Settings
          <v-spacer />
          <v-btn icon @click="showTileEdit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-if="rowModel.controlType.startsWith('LinkTiles')"
                label="Type"
                v-model="tileModel.type"
                :items="tileTypes"
                item-text="text"
                item-value="value"
              >
              </v-select>

              <template
                v-if="
                  rowModel.controlType === 'Metabase'
                "
              >
                <v-row>
                  <v-col>
                    <v-select
                      label="Resource Type"
                      v-model="tileModel.metabaseType"
                      :items="['question', 'dashboard']"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Resource ID"
                      v-model="tileModel.metabaseId"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-switch label="Include Title" v-model="tileModel.incTitle">
                </v-switch>
                <v-switch label="Show Border" v-model="tileModel.showBorder">
                </v-switch>
              </template>
              <template v-else-if="rowModel.controlType === 'QuickLinks'">
                <v-text-field label="Title" v-model="tileModel.title">
                </v-text-field>

                <div class="d-flex justify-space-between">
                  <v-icon
                    :class="tileModel.icon"
                    :color="tileModel.icon_colour"
                    style="width: 25px"
                    >{{
                      tileModel.icon ? tileModel.icon.split(" ")[0] : ""
                    }}</v-icon
                  >
                  <v-text-field
                    style="max-width: 120px"
                    label="Icon"
                    v-model="tileModel.icon"
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width: 80px"
                    label="Size"
                    type="number"
                    v-model="tileModel.icon_size"
                  >
                  </v-text-field>
                  <v-combobox
                    style="max-width: 100px"
                    label="Icon Colour"
                    v-model="tileModel.icon_colour"
                    :items="colours"
                  >
                  </v-combobox>
                </div>
              </template>
              <template v-else-if="rowModel.controlType === 'LinkTiles' || rowModel.controlType === 'LinkTilesAlt'">
                <v-text-field label="Title" v-model="tileModel.title">
                </v-text-field>

                <div class="d-flex justify-space-between">
                  <v-icon
                    :class="tileModel.icon"
                    :color="tileModel.icon_colour"
                    style="width: 25px"
                    >{{
                      tileModel.icon ? tileModel.icon.split(" ")[0] : ""
                    }}</v-icon
                  >
                  <v-text-field
                    style="max-width: 120px"
                    label="Icon"
                    v-model="tileModel.icon"
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width: 80px"
                    label="Size"
                    type="number"
                    v-model="tileModel.icon_size"
                  >
                  </v-text-field>
                  <v-combobox
                    style="max-width: 100px"
                    label="Icon Colour"
                    v-model="tileModel.icon_colour"
                    :items="colours"
                  >
                  </v-combobox>
                </div>
                <v-text-field label="Hero Image" v-model="tileModel.hero_image">
                </v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field label="Image Width" v-model="tileModel.image_width" />
                  </v-col>
                  <v-col>
                    <v-text-field label="Image Height" v-model="tileModel.image_height" />
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="rowModel.controlType === 'LinkTilesColoured'">
                <v-text-field label="Title" v-model="tileModel.title">
                </v-text-field>

                <v-text-field label="Link Text" v-model="tileModel.link_text">
                </v-text-field>

                <v-combobox
                  label="Colour"
                  v-model="tileModel.icon_colour"
                  :items="colours"
                >
                </v-combobox>

                <div class="d-flex justify-space-between">
                  <v-icon
                    :class="tileModel.icon"
                    :color="tileModel.icon_colour"
                    style="width: 25px"
                    >{{
                      tileModel.icon ? tileModel.icon.split(" ")[0] : ""
                    }}</v-icon
                  >
                  <v-text-field
                    style="max-width: 260px"
                    label="Icon"
                    v-model="tileModel.icon"
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width: 80px"
                    label="Size"
                    type="number"
                    v-model="tileModel.icon_size"
                  >
                  </v-text-field>
                </div>
              </template>
              <template v-else>
                <v-text-field label="Title" v-model="tileModel.title">
                </v-text-field>

                <v-select
                  label="Colour"
                  v-model="tileModel.icon_colour"
                  :items="colours"
                >
                </v-select>
              </template>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Tile Columns"
                    type="number"
                    v-model="tileModel.tile_cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="rowModel.controlType === 'Metabase' || rowModel.controlType === 'LinkTiles' || rowModel.controlType === 'LinkTilesAlt'"
                    label="Height"
                    type="text"
                    v-model="tileModel.height"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <template v-if="rowModel.controlType === 'Metabase'">
                <h3>Parameters</h3>
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>
                        <v-btn
                          icon
                          @click="
                            if (!tileModel.metabaseParams)
                              $set(tileModel, 'metabaseParams', []);

                            tileModel.metabaseParams.push({
                              name: '',
                              value: '',
                            });
                          "
                          ><v-icon>add_circle_outline</v-icon></v-btn
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(c, ci) in tileModel.metabaseParams"
                      :key="'p' + ci"
                    >
                      <td>
                        <v-combobox
                          :items="metabaseParams"
                          v-model="c.name"
                          hide-details
                        ></v-combobox>
                      </td>
                      <td>
                        <div
                          v-if="metabaseParams.includes(c.name)"
                          class="caption text--disabled mt-4"
                        >
                          <span>auto-set</span>
                        </div>
                        <v-text-field
                          v-else
                          v-model="c.value"
                          hide-details
                        ></v-text-field>
                      </td>
                      <td>
                        <v-btn
                          icon
                          @click="
                            tileModel.metabaseParams =
                              tileModel.metabaseParams.filter((o) => o !== c)
                          "
                          ><v-icon>delete</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
              <template v-else-if="tileModel.type === 'createdoc'">
                <v-select
                  :items="docTypes"
                  v-model="tileModel.createDocType"
                  label="Doc Type"
                  item-text="tmpl_name"
                  item-value="tmpl_name"
                  clearable
                ></v-select>
                <v-text-field
                  label="Call to Action"
                  v-model="tileModel.call_to_action"
                >
                </v-text-field>
              </template>
              <template v-else-if="tileModel.type === 'ssolink'">
                <v-text-field
                  label="URL"
                  v-model="tileModel.url"
                >
                </v-text-field>
                <v-switch
                  label="Open in new tab"
                  v-model="tileModel.open_newtab"
                >
                </v-switch>
                <v-select
                  :items="ssoTypes"
                  v-model="tileModel.sso"
                  label="SSO Type"
                ></v-select>
                <v-text-field
                  label="Call to Action"
                  v-model="tileModel.call_to_action"
                >
                </v-text-field>
              </template>
              <template v-else>
                <v-combobox
                  label="URL"
                  v-model="tileModel.url"
                  :items="internalRoutes"
                  item-text="label"
                  item-value="path"
                  :return-object="false"
                >
                </v-combobox>
                <v-text-field
                  label="Passed Params"
                  v-model="tileModel.passedParams">
                </v-text-field>
                <v-switch
                  label="Open in new tab"
                  v-model="tileModel.open_newtab"
                >
                </v-switch>
                <v-text-field
                  label="Call to Action"
                  v-model="tileModel.call_to_action"
                >
                </v-text-field>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <v-file-input
      v-show="false"
      accept=".json"
      ref="inputFile"
      dense
      @change="uploadDefinitionFromFile"
      label="Select File"
    ></v-file-input>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ResponseHandler from "@/components/ResponseHandler";
import Landing from "@/components/landingPages/cLanding";
import utils from "@/common/utils.js";
import landingUtils from "@/components/landingPages/landingUtils.js";
import draggable from "vuedraggable";

export default {
  name: "vLandingConfig",
  components: {
    ResponseHandler,
    Landing,
    draggable,
  },
  props: {},
  data: function () {
    return {
      utils: utils,
      isLoading: false,
      response: null,
      landingPages: [],
      permissionGroups: [],
      page: null,
      pageDefinition: null,
      showEditDefinition: false,
      showDefinitionEdit: false,
      isDirty: false,
      showPageEdit: false,
      showColEdit: false,
      showRowEdit: false,
      showTabEdit: false,
      tabModel: null,
      activeTab: 0,
      colModel: null,
      rowModel: null,
      showTileEdit: false,
      tileModel: null,
      ssoTypes: ["", "zendesk"],
      controlTypes: [
        { text: "Tiles", value: "LinkTiles" },
        { text: "TilesAlt", value: "LinkTilesAlt" },
        { text: "Tiles Coloured", value: "LinkTilesColoured" },
        { text: "QuickLinks", value: "QuickLinks" },
        { text: "Stepper", value: "Stepper" },
        { text: "Small Stepper", value: "StepperSmall" },
        { text: "Create Doc Links", value: "CreateDocLinks" },
        { text: "Task List", value: "TaskList" },
        { text: "Document List", value: "DocumentList" },
        { text: "Metabase Dashboard", value: "Metabase" },
        { text: "Chart", value: "Chart" },
      ],
      controlStyle: [
        { text: "Static", value: "static" },
        { text: "Collapsible (default expanded)", value: "collapsible-expanded" },
        { text: "Collapsible (default collapsed)", value: "collapsible-collapsed" }
      ],
      tileTypes: [
        { value: "link", text: "Link" },
        { value: "ssolink", text: "External SSO Link" },
        { value: "createdoc", text: "Create Doc Dialog" },
      ],
      chartTypes: [
        { value: "tagDistribution", text: "Tag Distribution" },
        { value: "tagProficiencies", text: "Tag by Proficiencies" },
        { value: "jobTagClassifiers", text: "Jobs by Tag Classifiers" },
      ],
      chartDisplayTypes: [
        { value: "bars", text: "Bars" },
        { value: "pieset", text: "Pie Charts" },
      ],
      colours: ["", "red", "blue", "purple", "green", "orange", "gray"],
      internalRoutes: [],
      metabaseParams: ["client_id", "client_name", "user_id", "name", "email"],
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    hierarchiesLoading(val) {
      if (!val) this.init();
    },
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      tagTypes: (state) => state.hierarchies.tagTypes,
      docTypes: (state) => state.hierarchies.docTypes,
      docListColumns: (state) => state.settings.config.docListColumns,
      lifecycleStatuses: (state) => state.hierarchies.lifecycleStatuses,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
    }),
    tagValues() {
      return this.tagTypes?.find(tt => tt.tag_type_name === this.rowModel?.tagTypeName)?.values || [];
    },
    documentListFields() {
      return [
        ...this.docListColumns,
        { text: "Remaining Time", value: "remainingTime" },
      ];
    },
    rowLifecycles() {
      if (!this.pageDefinition || !this.rowModel) return [];

      return this.docTypes
        .filter((s) => !this.rowModel.docTypes || this.rowModel.docTypes.length === 0 || this.rowModel.docTypes.some(dt => dt.toLowerCase() === s.tmpl_name.toLowerCase()))
        .reduce((p, c) => {
          return [...p, ...c.lifecycles ]
        }, []);
    },
    tabSelect() {
      const tabs = this.pageDefinition?.definition?.tabs;
      if (!tabs || tabs.length <= 1)
        return null;

      return tabs.map((t, ti) => {
        return {
          title: t.title,
          index: ti
        }
      })
    },
    rowStatuses() {
      if (!this.pageDefinition || !this.rowModel) return [];

      const lifecycleIdsIncluded = this.docTypes
        .filter((s) => !this.rowModel.docTypes || this.rowModel.docTypes.length === 0 || this.rowModel.docTypes.some(dt => dt.toLowerCase() === s.tmpl_name.toLowerCase()))
        .reduce((p, c) => {
          return [...p, ...c.lifecycles?.filter(l => !this.rowModel.lifecycles || this.rowModel.lifecycles.length === 0 || this.rowModel.lifecycles.includes(l.lifecycle_name)) ]
        }, [])
        .map(l => l.lifecycle_id);

      return this.lifecycleStatuses
        .filter(
          (s) =>
            (!this.rowModel.docTypes || this.rowModel.docTypes.length === 0 || this.rowModel.docTypes.some(dt => dt.toLowerCase() === s.tmpl_name.toLowerCase())) &&
            (!lifecycleIdsIncluded || !lifecycleIdsIncluded.length || lifecycleIdsIncluded.includes(s.lifecycle_id))
        )
        .map((s) => {
          return {
            lifecycle_status_id: s.lifecycle_status_id,
            lifecycle_status_name: s.lifecycle_status_name
          };
        });
    },
    activeTabDefinition: {
      get() {
        const definition = this.pageDefinition?.definition;
        if (!definition)
          return;

        return definition.tabs[this.activeTab];
      },
      set(newValue) {
        const definition = this.pageDefinition?.definition;
        if (!definition)
          return;

        definition.tabs[this.activeTab] = newValue;
      }
    }
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("admin/landingPageConfigLoad")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            const landingPages = data.pages;
            const definitionPgs = data.definitionPgs;
            const permissionGroups = data.permissionGroups;
            landingPages.forEach((p) => {
              p.definitions.forEach((d) => {
                permissionGroups.forEach((pg) => {
                  const lppg = definitionPgs.find(
                    (dpg) =>
                      dpg.lpd_id === d.lpd_id &&
                      dpg.permission_group_id === pg.permission_group_id
                  );
                  d[`pg${pg.permission_group_id}_enabled`] = !!lppg;
                });
              });
            });
            this.landingPages = landingPages.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            this.permissionGroups = permissionGroups;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });

      this.internalRoutes = this.$router
        .getRoutes()
        .filter((r) => r.meta?.newUi)
        .map((r) => {
          return {
            label: r.meta?.text || r.name,
            path: r.name,
          };
        });
    },
    editPage(p) {
      this.page = p;
      this.showPageEdit = true;
    },
    addPage() {
      let maxPosition = Math.max.apply(
        null,
        this.landingPages.map((p) => p.order || 0)
      );
      if (maxPosition < 0) maxPosition = 0;
      this.page = {
        lp_id: null,
        menu_section: "",
        page_route: "",
        active: true,
        title: "",
        icon: "",
        hide_header_create: false,
        definitions: [],
        order: ++maxPosition,
      };
      this.showPageEdit = true;
    },
    openDefinitionForEdit(p, pd) {
      this.editDefinition(p, pd);
      this.showEditDefinition = true;
    },
    editDefinition(p, pd) {
      this.page = p;

      this.pageDefinition = {
        lp_id: pd.lp_id,
        lpd_id: pd.lpd_id,
        pageTitle: p.title,
        description: pd.description,
        active: pd.active,
        definition: this.prepDefinition(pd.definition),
      };
    },
    prepDefinition(definition) {
      definition = definition ? JSON.parse(definition) : {};
      landingUtils.prepLandingPageDefinition(definition);

      return definition;
    },
    addDefinition(p, pd) {
      let newDefinition;
      if (pd) {
        newDefinition = JSON.parse(JSON.stringify(pd));
        const oldDesc = pd.description || "New Definition";
        let i = 1,
          newDesc = oldDesc;
        while (p.definitions.some((d) => d.description === newDesc)) {
          i++;
          newDesc = `${oldDesc} (${i})`;
        }
        newDefinition.description = newDesc;
      } else {
        const newDesc = prompt("Enter Definition Description", "");
        if (!newDesc) return;
        newDefinition = {
          lp_id: p.lp_id,
          description: newDesc,
          active: true,
        };
      }
      this.permissionGroups.forEach((pg) => {
        newDefinition[`pg${pg.permission_group_id}_enabled`] = false;
      });
      let possibleError = false;
      this.isLoading = true;
      axios
        .post("admin/landingPageDefinitionSave", {
          lp_id: p.lp_id,
          description: newDefinition.description,
          definition: newDefinition.definition,
          active: newDefinition.active,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            newDefinition.lpd_id = resp.data.Data;
            p.definitions.push(newDefinition);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    deleteDefinition(p, pd) {
      if (confirm("Delete definition?")) {
        let possibleError = false;
        this.isLoading = true;
        axios
          .post("admin/landingPageDefinitionDelete", {
            lpd_id: pd.lpd_id,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              p.definitions = p.definitions.filter(
                (d) => d.lpd_id !== pd.lpd_id
              );
            }
            this.response = resp.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    closeDefinition() {
      // const definitionJson = JSON.stringify(this.pageDefinition.definition);
      // const origDefinition = this.page
      //   ? this.page.definitions.find(
      //       (p) => p.lpd_id === this.pageDefinition.lpd_id
      //     )
      //   : null;
      // if (
      //   origDefinition &&
      //   (definitionJson !== origDefinition.definition ||
      //     this.pageDefinition.description !== origDefinition.description)
      // ) {
      //   if (confirm("Discard changes?")) {
      //     this.pageDefinition = null;
      //     this.showEditDefinition = false;
      //   }
      // } else {
      //   this.pageDefinition = null;
      //   this.showEditDefinition = false;
      // }
      this.pageDefinition = null;
      this.showEditDefinition = false;
    },
    configureDefinition() {
      this.showDefinitionEdit = true;
    },
    addTab() {
      const definition = this.pageDefinition?.definition;
      if (!definition) return;

      const newTab = {
        title: "New Tab",
        cols: [{
          cols: 0,
          rows: [],
        }],
      };
      if (!definition.tabs || definition.tabs.length === 0) {
        definition.tabs = [];
        newTab.rows = definition.rows;
        definition.rows = [];
      }
      definition.tabs.push(newTab);
    },
    tabEdit(tab) {
      this.tabModel = tab;
      this.showTabEdit = true;
    },
    tabMoveLeft(tab) {
      const tabs = this.pageDefinition?.definition?.tabs;
      if (!tabs) return;

      let currIdx = tabs.indexOf(tab);
      if (currIdx > 0)
        tabs.splice(currIdx - 1, 0, tabs.splice(currIdx, 1)[0]);
    },
    tabMoveRight(tab) {
      const tabs = this.pageDefinition?.definition?.tabs;
      if (!tabs) return;

      let currIdx = tabs.indexOf(tab);
      if (currIdx >= 0 && currIdx < tabs.length)
        tabs.splice(currIdx + 1, 0, tabs.splice(currIdx, 1)[0]);
    },
    deleteTab(tab) {
      const definition = this.pageDefinition?.definition;
      if (!definition) return;

      if (definition.tabs.length > 1) {
        const idx = definition.tabs.indexOf(tab);
        if (idx >= 0)
          definition.tabs.splice(idx, 1);
      }
    },
    changeActiveTab(tabIdx) {
      this.activeTab = tabIdx;
    },
    addColumn() {
      const definition = this.pageDefinition?.definition;
      if (!definition) return;

      const container = definition.tabs[this.activeTab];
      container.cols.push({
        cols: 0,
        rows: [],
      });
    },
    colEdit(col) {
      this.colModel = col;
      this.showColEdit = true;
    },
    deleteCol(col) {
      if (!this.activeTabDefinition) return;

      if (this.activeTabDefinition.cols.length > 1) {
        const idx = this.activeTabDefinition.cols.indexOf(col);
        if (idx >= 0)
          this.activeTabDefinition.cols.splice(idx, 1);
      }
    },
    rowEdit(row) {
      this.rowModel = row;
      this.showRowEdit = true;
    },
    addRow() {
      if (!this.activeTabDefinition) return;

      const newRow = {
        controlType: "LinkTiles",
        cols: 12,
        links: [],
        docListColumns: [],
      };
      this.addTile({ row: newRow });
      const container = this.activeTabDefinition.cols[this.activeTabDefinition.cols.length - 1];
      container.rows.push(newRow);
    },
    deleteRow(row, col) {
      if (!col || !col.rows) return;

      const idx = col.rows.indexOf(row);
      if (idx >= 0)
        col.rows.splice(idx, 1);
    },
    editTile(args) {
      this.rowModel = args.row;
      this.tileModel = args.link;
      this.showTileEdit = true;
    },
    addTile(args) {
      const maxId = Math.max(...args.row.links.map((l) => l.id || 0));
      args.row.links.push({
        id: maxId + 1,
        type: "link",
        title: "Untitled",
        url: "",
        icon: "",
        icon_colour: "gray",
        call_to_action: "",
        open_newtab: false,
        hero_image: null,
        image_width: null,
        image_height: null,
        tile_cols: null,
        sso: null,
      });
    },
    copyTile(args) {
      args.row.links.push(JSON.parse(JSON.stringify(args.link)));
    },
    deleteTile(args) {
      const idx = args.row.links.indexOf(args.link);
      if (idx >= 0)
        args.row.links.splice(idx, 1);
    },
    onDocListFieldSelect(col) {
      if (!col.text) {
        col.text =
          this.documentListFields.find((f) => f.value === col.value)?.text ||
          "";
      }
    },
    savePage() {
      this.page.page_route = this.page.page_route
        .replace(" ", "")
        .toLowerCase();

      const currentRoutes = this.$router
        .getRoutes()
        .filter(
          (r) => r.meta?.newUi && r.meta.landingPageId !== this.page.lp_id
        )
        .map((r) => r.name.toLowerCase());

      if (
        !this.page.page_route ||
        currentRoutes.includes(this.page.page_route)
      ) {
        this.response = {
          Status: "Error",
          Message: "Page route must be unique",
        };
        return;
      }

      let possibleError = false;
      this.isLoading = true;
      axios
        .post("admin/landingPageSave", {
          lp_id: this.page.lp_id,
          page_route: this.page.page_route,
          menu_section: this.page.menu_section,
          title: this.page.title,
          icon: this.page.icon,
          hide_header_create: this.page.hide_header_create,
          order: this.page.order,
          active: this.page.active,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.showPageEdit = false;
            if (!this.page.lp_id && resp.data.Data) {
              this.page.lp_id = resp.data.Data;
              this.landingPages.push(this.page);
            }

            this.landingPages.sort((a, b) => (a.order > b.order ? 1 : -1));
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    saveDefinition() {
      let possibleError = false;
      const definitionJson = JSON.stringify(this.pageDefinition.definition);
      this.isLoading = true;
      axios
        .post("admin/landingPageDefinitionSave", {
          lp_id: this.pageDefinition.lp_id,
          lpd_id: this.pageDefinition.lpd_id,
          description: this.pageDefinition.description,
          definition: definitionJson,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const origDefinition = this.page
              ? this.page.definitions.find(
                  (p) => p.lpd_id === this.pageDefinition.lpd_id
                )
              : null;
            if (origDefinition) {
              origDefinition.description = this.pageDefinition.description;
              origDefinition.active = this.pageDefinition.active;
              origDefinition.definition = definitionJson;
            }

            this.$loginState.updateLandingDefinition(
              this.pageDefinition.lpd_id,
              definitionJson
            );
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    downloadDefinition(d, pd) {
      const definitionJson = JSON.stringify(pd);
      const fileName = `${d.title.replaceAll(
        " ",
        "_"
      )}-${pd.description.replaceAll(" ", "_")}.json`;
      utils.downloadFile(definitionJson, fileName, "text/json");
    },
    uploadDefinition(p, pd) {
      this.editDefinition(p, pd);
      this.$refs.inputFile.$refs.input.click();
    },
    uploadDefinitionFromFile(file) {
      if (file) {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            const uploadedDefinition = JSON.parse(reader.result);
            this.pageDefinition.definition = this.prepDefinition(
              uploadedDefinition.definition
            );
            this.saveDefinition();
          },
          false
        );
        reader.readAsText(file, "UTF-8");
        this.$refs.inputFile.$refs.input.value = null;
      }
    },
    toggleRoute(pd, pgId) {
      let enabled = pd[`pg${pgId}_enabled`];

      if (enabled) {
        let possibleError = false;
        axios
          .post("admin/landingPageRemovePermission", {
            lpd_id: pd.lpd_id,
            pg_id: pgId,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              pd[`pg${pgId}_enabled`] = false;
            }
            this.response = resp.data;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      } else {
        let possibleError = false;
        axios
          .post("admin/landingPageAddPermission", {
            lpd_id: pd.lpd_id,
            pg_id: pgId,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              pd[`pg${pgId}_enabled`] = true;
            }
            this.response = resp.data;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

tr.inactive {
  background-color: #eee;
}

.draghandle {
  cursor: move;
}
</style>