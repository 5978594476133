import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/vHome.vue'
import Customers from '../views/vCustomers.vue'
import DocumentsWithParts from '../views/vDocumentsWithParts.vue'
import PartsWithDocuments from '../views/vPartsWithDocuments.vue'
import LandingFlex from '../views/vLandingHolder'
import Insights from '../views/vInsights.vue'
import dd from '../components/cDocumentDashboard.vue'
import UserList from '../components/cUserList.vue'
import ClientList from '../components/cClientList.vue'
import governance from '../components/cGovernance.vue'
import TemplateBuilder from '../views/vTemplateBuilder'
import ReviewGroups from '../views/vReviewGroups'
import BrandedContent from '../components/cBrandedContent'
import ClassificationList from '../components/cClassificationList.vue'
import AdminHolder from '../components/admin/cAdminHolder.vue'
import ReportHolder from '../components/cReportsHolder.vue'
import UploadContent from '../components/cUploadContent.vue'
import UploadContentNew from '../components/cUploadContentNew.vue'
import ReportDataUpload from '../components/dataUpload/cReportDataUpload.vue'
import DocumentTraining from '../components/cDocumentTraining.vue'
import SimpleDocParts from '../components/cSimpleDocParts.vue'
import AdminDocClassifierRules from '../components/cAdminDocClassifierRules.vue'
import WorkflowEngine from '../components/cWorkflowEngine.vue'
import RouteAdmin from '../views/vRouteAdmin.vue'
import LandingConfig from '../views/vLandingConfig.vue'
import Tasks from '../components/cTasks.vue'
import EventsGateway from '../components/cEventsGatewayLog.vue'
import HierarchyView from '../components/cHierarchyView.vue'
import HierarchyConfig from '../components/hierarchy/cHierarchyConfig.vue'
import AdminHierarchy from '../components/hierarchy/cAdminHierarchy.vue'
import AdminTags from '../components/admin/cAdminTagsAlt.vue'
// import QuestionnaireReport from '../components/cQuestionnaireSummary'
import DocViewAdmin from '../components/document/view/View_Admin.vue'
import similarityAnalysis from '../components/jobArchitecture/similarityAnalysis.vue'
import SkillManager from '../components/skills/cSkillManager.vue'
import TagCompare from "@/components/jobArchitecture/TagCompare";
//import DocViewAdmin from '../components/document/view/Document_View_Admin.vue'

Vue.use(VueRouter)

const menuSections = [
	{ name: 'Reporting', icon: 'assessment'},
	{ name: 'Admin', icon: 'admin_panel_settings' }
];

const routes = [
	{
		path: '/',
		name: 'default'
	}, {
		path: '/u/:user/customers',
		name: 'customers',
		component: Customers,
		meta: { text: 'Customer List', icon: 'manage_accounts', newUi: true, oldUi: false, internal: true }
	}, {
		path: '/u/:user/home',
		name: 'home',
		component: Home,
		meta: { text: 'Home', icon: 'home', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/myjobs',
		name: 'myjobs',
		component: DocumentsWithParts,
		meta: { text: 'My Jobs', icon: 'assignment', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/alljobs',
		name: 'alljobs',
		component: DocumentsWithParts,
		meta: { text: 'All Jobs', icon: 'dvr', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/docswithparts',
		name: 'docswithparts',
		component: DocumentsWithParts,
		meta: { text: 'Database', icon: 'dvr', newUi: false, oldUi: true }
	}, {
		path: '/u/:user/partswithdocs',
		name: 'partswithdocs',
		component: PartsWithDocuments,
		meta: { text: 'QA', icon: 'app_registration', newUi: false, oldUi: true }
	}, {
		path: '/u/:user/contentmanager',
		name: 'contentmanager',
		component: PartsWithDocuments,
		meta: { text: 'Content Manager', icon: 'app_registration', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/brandedcontent',
		name: 'brandedcontent',
		component: BrandedContent,
		meta: { text: 'Brand Content', icon: 'edit_note', newUi: false, oldUi: true }
	}, {
		path: '/u/:user/templatebuilder',
		name: 'templatebuilder',
		component: TemplateBuilder,
		meta: { text: 'Template Builder', icon: 'web', section: 'Admin', newUi: true, oldUi: true, reserved: true },
	}, {
		path: '/u/:user/userlist',
		name: 'userlist',
		component: UserList,
		meta: { text: 'Users', icon: 'manage_accounts', section: 'Admin', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/reviewgroups',
		name: 'reviewgroups',
		component: ReviewGroups,
		meta: { text: 'Review Groups', icon: 'people', section: 'Admin', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/clientlist',
		name: 'clientlist',
		component: ClientList,
		meta: { text: 'Customer Admin', icon: 'business', section: 'Admin', newUi: true, oldUi: true, reserved: true, internal: true }
	}, {
		path: '/u/:user/governance',
		name: 'governance',
		component: governance,
		meta: { text: 'Governance', icon: 'account_balance', section: 'Reporting', newUi: false, oldUi: true }
	}, {
		path: '/u/:user/classification',
		name: 'classification',
		component: ClassificationList,
		meta: { text: 'Classifiers', icon: 'layers', section: 'Reporting', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/simpleSearch',
		name: 'simpleSearch',
		component: SimpleDocParts,
		meta: { text: 'Simple', icon: 'pageview outlined', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/uploadContent',
		name: 'uploadContent',
		component: UploadContent,
		meta: { text: 'Upload Content', icon: 'upload', section: 'Admin', newUi: true, oldUi: true }
	},{
		path: '/u/:user/dataUpload',
		name: 'dataUpload',
		component: UploadContentNew,
		meta: { text: 'Data Upload', icon: 'upload', section: 'Admin', newUi: true, oldUi: true }
	},{
		path: '/u/:user/docTraining',
		name: 'docTraining',
		component: DocumentTraining, 
		meta: { text: 'Document Training', newUi: false, oldUi: false }
	},{
		path: '/u/:user/admin',
		name: 'admin',
		component: AdminHolder,
		meta: { text: 'Admin', icon: 'settings outlined', section: 'Admin', newUi: true, oldUi: true, reserved: true }
	}, {
		path: '/u/:user/classifierRules',
		name: 'classifierRules',
		component: AdminDocClassifierRules,
		meta: { text: 'Classifier Rules', icon: 'checklist_rtl', section: 'Admin', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/usageReports',
		name: 'usageReports',
		component: ReportHolder,
		meta: { text: 'Usage Reports', icon: 'summarize outlined', section: 'Reporting', newUi: true, oldUi: true }
	}, {
		path: '/u/:user/workflow',
		name: 'workflow',
		component: WorkflowEngine,
		meta: { text: 'Workflow Engine', icon: 'summarize outlined', section: 'Admin', newUi: true, oldUi: false, reserved: true }
	}, {
		path: '/u/:user/routeadmin',
		name: 'routeadmin',
		component: RouteAdmin,
		meta: { text: 'Page Permissions', icon: 'route', section: 'Admin', newUi: true, oldUi: false, reserved: true }
	}, {
		path: '/u/:user/landingconfig',
		name: 'landingconfig',
		component: LandingConfig,
		meta: { text: 'Landing Config', icon: 'layers', section: 'Admin', newUi: true, oldUi: false, reserved: true }
	}, {
		path: '/u/:user/tasks',
		name: 'tasks',
		component: Tasks,
		meta: { text: 'Tasks', icon: 'summarize outlined', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/eventsGateway',
		name: 'eventsGateway',
		component: EventsGateway,
		meta: { text: 'Events Gateway Log', icon: 'sensors', reserved: true }
	}, {
		path: '/u/:user/hierarchyview',
		name: 'hierarchyview',
		component: HierarchyView,
		meta: { text: 'Job Architecture', icon: 'sensors', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/jobfamily',
		name: 'jobfamily',
		component: HierarchyView,
		meta: { text: 'Job Family', icon: 'sensors', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/similarityAnalysis',
		name: 'similarityAnalysis',
		component: similarityAnalysis,
		meta: { text: 'Similarity Analysis', icon: 'sensors', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/hierarchyconfig',
		name: 'hierarchyconfig',
		component: HierarchyConfig,
		meta: { text: 'Hierarchy Config', icon: 'account_tree', section: "Admin", newUi: true, oldUi: false }
	}, {
		path: '/u/:user/hierarchyadmin',
		name: 'hierarchyadmin',
		component: AdminHierarchy,
		meta: { text: 'Hierarchy Admin', icon: 'account_tree', section: "Admin", newUi: true, oldUi: false }
	}, {
		path: '/u/:user/managereportdata',
		name: 'managereportdata',
		component: ReportDataUpload,
		meta: { text: 'Reporting Data', icon: 'storage', section: "Admin", newUi: true, oldUi: false }
	},  {
		path: '/u/:user/skilladmin',
		name: 'skilladmin',
		component: AdminTags,
		meta: { text: 'Skill Admin', icon: 'local_offer', section: "Admin", newUi: true, oldUi: false }
	}, {
	// 	path: '/u/:user/questionnairereport',
	// 	name: 'questionnairereport',
	// 	component: QuestionnaireReport,
	// 	meta: { text: 'Questionnaire Summary Report', icon: 'summarize outlined', section: 'Reporting', newUi: true, oldUi: false }
	// }, {
		path: '/u/:user/docViewAdmin',
		name: 'docViewAdmin',
		component: DocViewAdmin,
		meta: { text: 'Document View Setup', icon: 'summarize outlined', newUi: true, oldUi: false }
	}, {
		path: '/u/:user/tagcompare',
		name: 'tagcompare',
		component: TagCompare,
		meta: { text: 'Tag Compare', icon: 'local_offer', newUi: true, oldUi: false }
	}//, {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	// 	meta: { text: 'About', icon: 'mdi-information-outline', newUi: true, oldUi: true }
	// }
	,{
		path: '/u/:user/skillmanager',
		name: 'skillmanager',
		component: SkillManager,
		meta: { text: 'Skill Manager', icon: 'checklist', newUi: true, oldUi: false, reserved: true, module: 'RoleSkill' }
	}
]

const router = new VueRouter({
	routes
})

router.menuSections = menuSections;
router.addDashboardRoutes = function(dashboards) {
	let dashboardRoutes = [];
	dashboards.forEach(d => {
		let name = d.name.replace(' ', '');
		name = name.charAt(0).toLowerCase() + name.slice(1);
		const path = "/u/:user/" + name;
		const newRoute = {
			name: name,
			path: path,
			component: d.dashboard_type === "INSIGHTS" ? Insights : dd,
			meta: { text: d.name, icon: d.icon, section: 'Reporting', newUi: true, oldUi: true, dashboardId: d.db_id }
		};
		dashboardRoutes.push(newRoute);

		const currentRoute = router.getRoutes().find(c => c.name == newRoute.name);
		if (currentRoute)
			currentRoute.meta = newRoute.meta;
		else
			router.addRoute(newRoute);
	});

	return dashboardRoutes;
}

router.addLandingPages = function(landingPageConfigs) {
	router.getRoutes()
		.filter(r => r.meta && r.meta.landingPageId)
		.forEach(r => {
			r.meta = undefined;
		});

	landingPageConfigs.forEach((p, pi) => {
		let name = p.page_route;
		const path = "/u/:user/" + name;
		const newRoute = {
			name: name,
			path: path,
			component: LandingFlex,
			meta: { text: p.title, section: p.menu_section, icon: p.icon, newUi: true, oldUi: false, landingPageId: p.lp_id, position: pi + 1, hideHeaderCreate: p.hide_header_create === 1 }
		};

		const currentRoute = router.getRoutes().find(c => c.name == newRoute.name);
		if (currentRoute) {
			currentRoute.meta = newRoute.meta;
			currentRoute.component = newRoute.component;
		} else {
			router.addRoute(newRoute);
		}
	});
}

router.clearClientRoutes = function() {
	router.getRoutes()
		.filter(r => r.meta && (r.meta.dashboardId || r.meta.landingPageId))
		.forEach(r => {
			r.meta = undefined;
		});
}

// very basic "setup" of a global guard
router.beforeEach((to, from, next) => {
	if (to.name !== "default" && router.app.$loginState.defaultRoute && !router.app.$loginState.permittedRoutes.includes(to.name))
		next({ name: router.app.$loginState.defaultRoute });
	else
		next();
});

let applicationName;

const setDocumentTitle = () => {
	const routeText = router.currentRoute?.meta?.text;
	const appName = applicationName || 'Role Mapper';
	document.title = routeText ? `${appName} - ${routeText}` : appName;
}

router.setAppName = (appName) => {
	applicationName = appName;
	setDocumentTitle();
}

router.afterEach(() => {
    Vue.nextTick(() => {
        setDocumentTitle();
    });
});

export default router
